export const GET_FOLLOWUP = "GET_FOLLOWUP";
export const GET_FOLLOWUP_SUCCESS = "GET_FOLLOWUP_SUCCESS";
export const GET_FOLLOWUP_FAILED = "GET_FOLLOWUP_FAILED";
export const GET_FOLLOWUP_RESET = "GET_FOLLOWUP_RESET";
export const GET_ALL_FOLLOWUP = "GET_ALL_FOLLOWUP";
export const GET_ALL_FOLLOWUP_SUCCESS = "GET_ALL_FOLLOWUP_SUCCESS";
export const GET_ALL_FOLLOWUP_FAILED = "GET_ALL_FOLLOWUP_FAILED";
export const GET_ALL_FOLLOWUP_RESET = "GET_ALL_FOLLOWUP_RESET";
export const CREATE_FOLLOWUP = "CREATE_FOLLOWUP";
export const CREATE_FOLLOWUP_SUCCESS = "CREATE_FOLLOWUP_SUCCESS";
export const CREATE_FOLLOWUP_FAILED = "CREATE_FOLLOWUP_FAILED";
export const UPDATE_FOLLOWUP = "UPDATE_FOLLOWUP";
export const UPDATE_FOLLOWUP_SUCCESS = "UPDATE_FOLLOWUP_SUCCESS";
export const UPDATE_FOLLOWUP_FAILED = "UPDATE_FOLLOWUP_FAILED";
export const DELETE_FOLLOWUP = "DELETE_FOLLOWUP";
export const DELETE_FOLLOWUP_SUCCESS = "DELETE_FOLLOWUP_SUCCESS";
export const DELETE_FOLLOWUP_FAILED = "DELETE_FOLLOWUP_FAILED";
export const UPDATE_FOLLOWUP_STATUS = "UPDATE_FOLLOWUP_STATUS";
export const UPDATE_FOLLOWUP_STATUS_SUCCESS = "UPDATE_FOLLOWUP_STATUS_SUCCESS";
export const UPDATE_FOLLOWUP_STATUS_FAILED = "UPDATE_FOLLOWUP_STATUS_FAILED";
export const UPDATE_FOLLOWUP_RESET = "UPDATE_FOLLOWUP_RESET";
export const CREATE_FOLLOWUP_RESET = "CREATE_FOLLOWUP_RESET";
export const DELETE_FOLLOWUP_RESET = "DELETE_FOLLOWUP_RESET";
export const UPDATE_FOLLOWUP_STATUS_RESET = "UPDATE_FOLLOWUP_STATUS_RESET";

export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAILED = "GET_BRANCH_FAILED";
export const GET_BRANCH_RESET = "GET_BRANCH_RESET";
export const CREATE_BRANCH = "CREATE_BRANCH";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILED = "CREATE_BRANCH_FAILED";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILED = "UPDATE_BRANCH_FAILED";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_FAILED = "DELETE_BRANCH_FAILED";
export const UPDATE_BRANCH_STATUS = "UPDATE_BRANCH_STATUS";
export const UPDATE_BRANCH_STATUS_SUCCESS = "UPDATE_BRANCH_STATUS_SUCCESS";
export const UPDATE_BRANCH_STATUS_FAILED = "UPDATE_BRANCH_STATUS_FAILED";
export const UPDATE_BRANCH_RESET = "UPDATE_BRANCH_RESET";
export const CREATE_BRANCH_RESET = "CREATE_BRANCH_RESET";
export const DELETE_BRANCH_RESET = "DELETE_BRANCH_RESET";
export const UPDATE_BRANCH_STATUS_RESET = "UPDATE_BRANCH_STATUS_RESET";

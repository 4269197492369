import { combineReducers } from "redux";
import authTokenReducer from "./login/reducers";
import changeState from "../store";
import {
  branchReducer,
  branchStatusReducer,
  createBranchReducer,
  updateBranchReducer,
  deleteBranchReducer,
} from "./branch/reducers";

import {
  courseReducer,
  allCourseReducer,
  createCourseReducer,
  updateCourseReducer,
  deleteCourseReducer,
  updateCourseStatusReducer,
  gradeByCourseIdReducer,
  courseByClassIdReducer,
} from "./courses/reducers";
import {
  courseGradeReducer,
  courseGradeStatusReducer,
  createCourseGradeReducer,
  deleteCourseGradeReducer,
  assignCourseGradeReducer,
  updateCourseGradeReducer,
} from "./coursesGurad/reducers";
import { classReducer } from "./class/reducers";
import {
  batchAllReducer,
  batchReducer,
  batchStatusReducer,
  createBatchReducer,
  deleteBatchReducer,
  updateBatchReducer,
} from "./batch/reducers";
import {
  createSessionReducer,
  deleteSessionReducer,
  sessionAllReducer,
  sessionReducer,
  sessionStatusReducer,
  updateSessionReducer,
} from "./session/reducers";
import {
  createLocationReducer,
  deleteLocationReducer,
  getLocationReducer,
  updateLocationReducer,
  updateLocationStatusReducer,
} from "./location/reducers";
import {
  vehicleReducer,
  createvehicleReducer,
  updatevehicleReducer,
  deleteVehicleReducer,
  vehicleStatusReducer,
} from "./vehicle/reducers";
import {
  createLeadReducer,
  deleteLeadReducer,
  leadReducer,
  leadReportMonthReducer,
  leadReportWeekReducer,
  leadReportYearReducer,
  leadStatusReducer,
  updateLeadReducer,
} from "./lead/reducers";
import {
  createSocialMediaReducer,
  deleteSocialMediaReducer,
  socialMediaAllReducer,
  socialMediaReducer,
  socialMediaStatusReducer,
  updateSocialMediaReducer,
} from "./socialMedia/reducers";
import {
  createFollowupReducer,
  deleteFollowupReducer,
  followupReducer,
  followupsReducer,
  followupStatusReducer,
  updateFollowupReducer,
} from "./followup/reducers";
import {
  campByIdReducer,
  campReducer,
  campStatusReducer,
  campTypeReducer,
  campTypeStatusReducer,
  createCampReducer,
  createCampTypeReducer,
  deleteCampReducer,
  deleteCampTypeReducer,
  getCampAndCourseReducer,
  updateCampReducer,
  updateCampTypeReducer,
} from "./camp/reducers";
import {
  classRoomReducer,
  classRoomStatusReducer,
  createClassRoomReducer,
  deleteClassRoomReducer,
  updateClassRoomReducer,
} from "./classRoom/reducers";

const rootReducer = combineReducers({
  authTokenReducer,
  classReducer,
  branchReducer,
  createBranchReducer,
  updateBranchReducer,
  deleteBranchReducer,
  courseReducer,
  allCourseReducer,
  createCourseReducer,
  updateCourseReducer,
  deleteCourseReducer,
  updateCourseStatusReducer,
  branchStatusReducer,
  createCourseGradeReducer,
  updateCourseGradeReducer,
  deleteCourseGradeReducer,
  courseGradeStatusReducer,
  courseGradeReducer,
  changeState,
  assignCourseGradeReducer,
  batchAllReducer,
  deleteBatchReducer,
  createBatchReducer,
  updateBatchReducer,
  batchStatusReducer,
  batchReducer,
  sessionAllReducer,
  deleteSessionReducer,
  createSessionReducer,
  updateSessionReducer,
  sessionStatusReducer,
  sessionReducer,
  courseByClassIdReducer,
  gradeByCourseIdReducer,
  getLocationReducer,
  createLocationReducer,
  updateLocationReducer,
  deleteLocationReducer,
  updateLocationStatusReducer,
  vehicleReducer,
  deleteVehicleReducer,
  updatevehicleReducer,
  vehicleStatusReducer,
  createvehicleReducer,
  leadReducer,
  createLeadReducer,
  updateLeadReducer,
  deleteLeadReducer,
  leadStatusReducer,
  socialMediaReducer,
  socialMediaAllReducer,
  deleteSocialMediaReducer,
  updateSocialMediaReducer,
  createSocialMediaReducer,
  socialMediaStatusReducer,
  followupReducer,
  followupStatusReducer,
  createFollowupReducer,
  updateFollowupReducer,
  deleteFollowupReducer,
  followupsReducer,
  leadReportMonthReducer,
  leadReportWeekReducer,
  leadReportYearReducer,
  campReducer,
  createCampReducer,
  updateCampReducer,
  deleteCampReducer,
  campStatusReducer,
  campTypeReducer,
  createCampTypeReducer,
  updateCampTypeReducer,
  deleteCampTypeReducer,
  campTypeStatusReducer,
  campByIdReducer,
  getCampAndCourseReducer,
  classRoomReducer,
  createClassRoomReducer,
  updateClassRoomReducer,
  deleteClassRoomReducer,
  classRoomStatusReducer,
});

export default rootReducer;

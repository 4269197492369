import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  GET_VEHICLE,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_STATUS,
} from "./constants";
import {
  createVehicleApi,
  deleteVehicleApi,
  getVehicleApi,
  updateVehicleApi,
  updateVehicleStatusApi,
} from "./api";
import {
  createVehicleFailed,
  createVehicleSuccess,
  deleteVehicleFailed,
  deleteVehicleSuccess,
  getVehicleFailed,
  getVehicleSuccess,
  updateVehicleFailed,
  updateVehicleStatusFailed,
  updateVehicleStatusSuccess,
  updateVehicleSuccess,
} from "./action";

function* getVehicle(action) {
  try {
    const response = yield call(getVehicleApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getVehicleSuccess(response));
    } else {
      yield put(getVehicleFailed(response));
    }
  } catch (e) {
    yield put(getVehicleFailed(e.response));
  }
}

function* createVehicle(action) {
  try {
    console.log("createVehicle saga" + action);
    const response = yield call(createVehicleApi, action.payload.requestBody);

    if (response && response.status === 201) {
      yield put(createVehicleSuccess(response));
    } else {
      yield put(createVehicleFailed(response));
    }
  } catch (e) {
    yield put(createVehicleFailed(e.response));
  }
}

function* updateVehicle(action) {
  try {
    const response = yield call(updateVehicleApi, action.payload.requestBody);
    if (response && response.status === 200) {
     
    yield put(updateVehicleSuccess(response));
    } else {
      yield put(updateVehicleFailed(response));
    }
  } catch (e) {
    yield put(updateVehicleFailed(e.response));
  }
}

function* updateVehicleStatus(action) {
  try {
    const response = yield call(
      updateVehicleStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateVehicleStatusSuccess(response));
    } else {
      yield put(updateVehicleStatusFailed(response));
    }
  } catch (e) {
    yield put(updateVehicleStatusFailed(e.response));
  }
}

function* deleteVehicle(action) {
  try {
    const response = yield call(deleteVehicleApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteVehicleSuccess(response));
    } else {
      yield put(deleteVehicleFailed(response));
    }
  } catch (e) {
    yield put(deleteVehicleFailed(e.response));
  }
}

export default function* getVehicleSaga() {
  yield takeEvery(GET_VEHICLE, getVehicle);
  yield takeEvery(CREATE_VEHICLE, createVehicle);
  yield takeEvery(UPDATE_VEHICLE, updateVehicle);
  yield takeEvery(UPDATE_VEHICLE_STATUS, updateVehicleStatus);
  yield takeEvery(DELETE_VEHICLE, deleteVehicle);
}

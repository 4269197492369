import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_FAILED,
  GET_COURSE_RESET,
  GET_ALL_COURSE_TYPE_RESET,
  GET_ALL_COURSE_TYPE_FAILED,
  GET_ALL_COURSE_TYPE_SUCCESS,
  GET_ALL_COURSE_FAILED,
  GET_ALL_COURSE_SUCCESS,
  GET_ALL_COURSE,
  GET_ALL_COURSE_RESET,
  CREATE_COURSE_FAILED,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  DELETE_COURSE,
  UPDATE_COURSE_FAILED,
  UPDATE_COURSE_RESET,
  CREATE_COURSE_RESET,
  DELETE_COURSE_RESET,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILED,
  UPDATE_COURSE_STATUS,
  UPDATE_COURSE_STATUS_SUCCESS,
  UPDATE_COURSE_STATUS_FAILED,
  UPDATE_COURSE_STATUS_RESET,
  GET_COURSE_BY_CALSSID,
  GET_COURSE_BY_CALSSID_RESET,
  GET_COURSE_BY_CALSSID_SUCCESS,
  GET_GRADE_BY_COURSEID,
  GET_GRADE_BY_COURSEID_RESET,
  GET_GRADE_BY_COURSEID_FAILED,
  GET_GRADE_BY_COURSEID_SUCCESS,
  GET_COURSE_BY_CALSSID_FAILED,
} from "./constants";

export const courseReducer = (
  state = {
    coursedata: null,
    courseloading: false,
    courseerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_COURSE:
      return {
        ...state,
        courseloading: true,
        coursedata: null,
        courseerror: null,
      };
    case GET_COURSE_SUCCESS:
      return {
        ...state,
        coursedata: action.payload,
        courseloading: false,
        courseerror: null,
      };
    case GET_COURSE_FAILED:
      return {
        ...state,
        courseloading: false,
        coursedata: null,
        courseerror: action.payload,
      };
    case GET_COURSE_RESET:
      return {
        ...state,
        courseloading: false,
        coursedata: null,
        courseerror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const allCourseReducer = (
  state = {
    allCourseData: null,
    allCourseLoading: false,
    allCourseError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_COURSE:
      return {
        ...state,
        allCourseLoading: true,
        allCourseData: null,
        allCourseError: null,
      };
    case GET_ALL_COURSE_SUCCESS:
      return {
        ...state,
        allCourseData: action.payload,
        allCourseLoading: false,
        allCourseError: null,
      };
    case GET_ALL_COURSE_FAILED:
      return {
        ...state,
        allCourseloading: false,
        allCourseData: null,
        allCourseError: action.payload,
      };
    case GET_ALL_COURSE_RESET:
      return {
        ...state,
        allCourseLoading: false,
        allCourseData: null,
        allCourseError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createCourseReducer = (
  state = {
    createCourseData: null,
    createCourseLoading: false,
    createCourseError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_COURSE:
      return {
        ...state,
        createCourseLoading: true,
        createCourseData: null,
        createCourseError: null,
      };
    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        createCourseData: action.payload,
        createCourseLoading: false,
        createCourseError: null,
      };
    case CREATE_COURSE_FAILED:
      return {
        ...state,
        createCourseLoading: false,
        createCourseData: null,
        createCourseError: action.payload,
      };
    case CREATE_COURSE_RESET:
      return {
        ...state,
        createCourseLoading: false,
        createCourseData: null,
        createCourseError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateCourseReducer = (
  state = {
    updateCourseData: null,
    updateCourseLoading: false,
    updateCourseError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_COURSE:
      return {
        ...state,
        updateCourseLoading: true,
        updateCourseData: null,
        updateCourseError: null,
      };
    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        updateCourseData: action.payload,
        updateCourseLoading: false,
        updateCourseError: null,
      };
    case UPDATE_COURSE_FAILED:
      return {
        ...state,
        updateCourseLoading: false,
        updateCourseData: null,
        updateCourseError: action.payload,
      };
    case UPDATE_COURSE_RESET:
      return {
        ...state,
        updateCourseLoading: false,
        updateCourseData: null,
        updateCourseError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteCourseReducer = (
  state = {
    deleteCourseData: null,
    deleteCourseLoading: false,
    deleteCourseError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_COURSE:
      return {
        ...state,
        deleteCourseLoading: true,
        deleteCourseData: null,
        deleteCourseError: null,
      };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        deleteCourseData: action.payload,
        deleteCourseLoading: false,
        deleteCourseError: null,
      };
    case DELETE_COURSE_FAILED:
      return {
        ...state,
        deleteCourseLoading: false,
        deleteCourseData: null,
        deleteCourseError: action.payload,
      };
    case DELETE_COURSE_RESET:
      return {
        ...state,
        deleteCourseLoading: false,
        deleteCourseData: null,
        deleteCourseError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateCourseStatusReducer = (
  state = {
    updateCourseStatusData: null,
    updateCourseStatusLoading: false,
    updateCourseStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_COURSE_STATUS:
      return {
        ...state,
        updateCourseStatusLoading: true,
        updateCourseStatusData: null,
        updateCourseStatusError: null,
      };
    case UPDATE_COURSE_STATUS_SUCCESS:
      return {
        ...state,
        updateCourseStatusData: action.payload,
        updateCourseStatusLoading: false,
        updateCourseStatusError: null,
      };
    case UPDATE_COURSE_STATUS_FAILED:
      return {
        ...state,
        updateCourseStatusLoading: false,
        updateCourseStatusData: null,
        updateCourseStatusError: action.payload,
      };
    case UPDATE_COURSE_STATUS_RESET:
      return {
        ...state,
        updateCourseStatusLoading: false,
        updateCourseStatusData: null,
        updateCourseStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const courseByClassIdReducer = (
  state = {
    courseByClassIdData: null,
    courseByClassIdLoading: false,
    courseByClassIdError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_COURSE_BY_CALSSID:
      return {
        ...state,
        courseByClassIdLoading: true,
        courseByClassIdData: null,
        courseByClassIdError: null,
      };
    case GET_COURSE_BY_CALSSID_SUCCESS:
      return {
        ...state,
        courseByClassIdData: action.payload,
        courseByClassIdLoading: false,
        courseByClassIdError: null,
      };
    case GET_COURSE_BY_CALSSID_FAILED:
      return {
        ...state,
        courseByClassIdloading: false,
        courseByClassIdData: null,
        courseByClassIdError: action.payload,
      };
    case GET_COURSE_BY_CALSSID_RESET:
      return {
        ...state,
        courseByClassIdLoading: false,
        courseByClassIdData: null,
        courseByClassIdError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const gradeByCourseIdReducer = (
  state = {
    gradeByCourseIdData: null,
    gradeByCourseIdLoading: false,
    gradeByCourseIdError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_GRADE_BY_COURSEID:
      return {
        ...state,
        gradeByCourseIdLoading: true,
        gradeByCourseIdData: null,
        gradeByCourseIdError: null,
      };
    case GET_GRADE_BY_COURSEID_SUCCESS:
      return {
        ...state,
        gradeByCourseIdData: action.payload,
        gradeByCourseIdLoading: false,
        gradeByCourseIdError: null,
      };
    case GET_GRADE_BY_COURSEID_FAILED:
      return {
        ...state,
        gradeByCourseIdloading: false,
        gradeByCourseIdData: null,
        gradeByCourseIdError: action.payload,
      };
    case GET_GRADE_BY_COURSEID_RESET:
      return {
        ...state,
        gradeByCourseIdLoading: false,
        gradeByCourseIdData: null,
        gradeByCourseIdError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import { takeLatest, put, call, takeEvery } from 'redux-saga/effects'
import {
  CHECK_AVAILABEL,
  CREATE_TIME_TABLE,
  GET_TIME_TABLE,
  GET_TIME_TABLE_CC,
  UPDATE_TIME_TABLE,
} from './constants'
import {
  checkAvailableTimeApi,
  createTimetableApi,
  getTimeTableByClassTypeAndCourseApi,
  getTimetableApi,
  updateTimetableApi,
} from './api'
import {
  checkAvailabelTimeFailed,
  checkAvailabelTimeSuccess,
  createTimetableFailed,
  createTimetableSuccess,
  getTimeTableByClassTypeAndCourseFailed,
  getTimeTableByClassTypeAndCourseSuccess,
  getTimetableFailed,
  getTimetableSuccess,
  updateTimetableFailed,
  updateTimetableSuccess,
} from './action'

function* getTimetable(action) {
  try {
    const response = yield call(getTimetableApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getTimetableSuccess(response))
    } else {
      yield put(getTimetableFailed(response))
    }
  } catch (e) {
    yield put(getTimetableFailed(e.response))
  }
}

function* createTimetable(action) {
  try {
    const response = yield call(createTimetableApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(createTimetableSuccess(response))
    } else {
      yield put(createTimetableFailed(response))
    }
  } catch (e) {
    yield put(createTimetableFailed(e.response))
  }
}

function* updateTimetable(action) {
  try {
    const response = yield call(updateTimetableApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(updateTimetableSuccess(response))
    } else {
      yield put(updateTimetableFailed(response))
    }
  } catch (e) {
    yield put(updateTimetableFailed(e.response))
  }
}

function* checkAvailableTime(action) {
  try {
    const response = yield call(checkAvailableTimeApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(
        checkAvailabelTimeSuccess({
          response,
          data: action.payload.requestBody.data,
        }),
      )
    } else {
      yield put(checkAvailabelTimeFailed(response))
    }
  } catch (e) {
    yield put(checkAvailabelTimeFailed(e.response))
  }
}

function* getTimeTableByClassTypeAndCourse(action) {
  try {
    const response = yield call(getTimeTableByClassTypeAndCourseApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getTimeTableByClassTypeAndCourseSuccess(response))
    } else {
      yield put(getTimeTableByClassTypeAndCourseFailed(response))
    }
  } catch (e) {
    yield put(getTimeTableByClassTypeAndCourseFailed(e.response))
  }
}

export default function* getTimetableSaga() {
  yield takeEvery(GET_TIME_TABLE, getTimetable)
  yield takeEvery(CREATE_TIME_TABLE, createTimetable)
  yield takeLatest(UPDATE_TIME_TABLE, updateTimetable)
  yield takeLatest(CHECK_AVAILABEL, checkAvailableTime)
  yield takeLatest(GET_TIME_TABLE_CC, getTimeTableByClassTypeAndCourse)
}

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_FAILED = 'GET_EMPLOYEE_FAILED'
export const GET_EMPLOYEE_RESET = 'GET_EMPLOYEE_RESET'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS'
export const CREATE_EMPLOYEE_FAILED = 'CREATE_EMPLOYEE_FAILED'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_STATUS = 'UPDATE_EMPLOYEE_STATUS'
export const UPDATE_EMPLOYEE_STATUS_SUCCESS = 'UPDATE_EMPLOYEE_STATUS_SUCCESS'
export const UPDATE_EMPLOYEE_STATUS_FAILED = 'UPDATE_EMPLOYEE_STATUS_FAILED'
export const CREATE_EMPLOYEE_RESET = 'CREATE_EMPLOYEE_RESET'
export const UPDATE_EMPLOYEE_RESET = 'UPDATE_EMPLOYEE_RESET'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED'
export const DELETE_EMPLOYEE_RESET = 'DELETE_EMPLOYEE_RESET'
export const GET_ALL_ROLE = 'GET_ALL_ROLE'
export const GET_ALL_ROLE_SUCCESS = 'GET_ALL_ROLE_SUCCESS'
export const GET_ALL_ROLE_FAILED = 'GET_ALL_ROLE_FAILED'
export const GET_ALL_ROLE_RESET = 'GET_ALL_ROLE_RESET'

import {
  GET_ALL_SOCIAL_MEDIA,
  GET_ALL_SOCIAL_MEDIA_SUCCESS,
  GET_ALL_SOCIAL_MEDIA_FAILED,
  GET_ALL_SOCIAL_MEDIA_RESET,
  GET_SOCIAL_MEDIA,
  GET_SOCIAL_MEDIA_SUCCESS,
  GET_SOCIAL_MEDIA_FAILED,
  GET_SOCIAL_MEDIA_RESET,
  CREATE_SOCIAL_MEDIA,
  CREATE_SOCIAL_MEDIA_FAILED,
  CREATE_SOCIAL_MEDIA_SUCCESS,
  UPDATE_SOCIAL_MEDIA,
  UPDATE_SOCIAL_MEDIA_FAILED,
  UPDATE_SOCIAL_MEDIA_SUCCESS,
  UPDATE_SOCIAL_MEDIA_RESET,
  CREATE_SOCIAL_MEDIA_RESET,
  DELETE_SOCIAL_MEDIA,
  DELETE_SOCIAL_MEDIA_SUCCESS,
  DELETE_SOCIAL_MEDIA_FAILED,
  DELETE_SOCIAL_MEDIA_RESET,
  UPDATE_SOCIAL_MEDIA_STATUS,
  UPDATE_SOCIAL_MEDIA_STATUS_SUCCESS,
  UPDATE_SOCIAL_MEDIA_STATUS_RESET,
  UPDATE_SOCIAL_MEDIA_STATUS_FAILED,
} from "./constants";

export const socialMediaAllReducer = (
  state = {
    socialMediaAllData: null,
    socialMediaAllLoading: false,
    socialMediaAllError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_SOCIAL_MEDIA:
      return {
        ...state,
        socialMediaAllLoading: true,
        socialMediaAllData: null,
        socialMediaAllError: null,
      };
    case GET_ALL_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        socialMediaAllData: action.payload,
        socialMediaAllLoading: false,
        socialMediaAllError: null,
      };
    case GET_ALL_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        socialMediaAllLoading: false,
        socialMediaAllData: null,
        socialMediaAllError: action.payload,
      };
    case GET_ALL_SOCIAL_MEDIA_RESET:
      return {
        ...state,
        socialMediaAllLoading: false,
        socialMediaAllData: null,
        socialMediaAllError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const socialMediaReducer = (
  state = {
    socialMediaData: null,
    socialMediaLoading: false,
    socialMediaError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_SOCIAL_MEDIA:
      return {
        ...state,
        socialMediaLoading: true,
        socialMediaData: null,
        socialMediaError: null,
      };
    case GET_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        socialMediaData: action.payload,
        socialMediaLoading: false,
        socialMediaError: null,
      };
    case GET_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        socialMediaLoading: false,
        socialMediaData: null,
        socialMediaError: action.payload,
      };
    case GET_SOCIAL_MEDIA_RESET:
      return {
        ...state,
        socialMediaLoading: false,
        socialMediaData: null,
        socialMediaError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createSocialMediaReducer = (
  state = {
    createSocialMediaData: null,
    createSocialMediaLoading: false,
    createSocialMediaError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_SOCIAL_MEDIA:
      return {
        ...state,
        createSocialMediaLoading: true,
        createSocialMediaData: null,
        createSocialMediaError: null,
      };
    case CREATE_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        createSocialMediaData: action.payload,
        createSocialMediaLoading: false,
        createSocialMediaError: null,
      };
    case CREATE_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        createSocialMediaLoading: false,
        createSocialMediaData: null,
        createSocialMediaError: action.payload,
      };
    case CREATE_SOCIAL_MEDIA_RESET:
      return {
        ...state,
        createSocialMediaLoading: false,
        createSocialMediaData: null,
        createSocialMediaError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateSocialMediaReducer = (
  state = {
    updateSocialMediaData: null,
    updateSocialMediaLoading: false,
    updateSocialMediaError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_SOCIAL_MEDIA:
      return {
        ...state,
        updateSocialMediaLoading: true,
        updateSocialMediaData: null,
        updateSocialMediaError: null,
      };
    case UPDATE_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        updateSocialMediaData: action.payload,
        updateSocialMediaLoading: false,
        updateSocialMediaError: null,
      };
    case UPDATE_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        updateSocialMediaLoading: false,
        updateSocialMediaData: null,
        updateSocialMediaError: action.payload,
      };
    case UPDATE_SOCIAL_MEDIA_RESET:
      return {
        ...state,
        updateSocialMediaLoading: false,
        updateSocialMediaData: null,
        updateSocialMediaError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteSocialMediaReducer = (
  state = {
    deleteSocialMediaData: null,
    deleteSocialMediaLoading: false,
    deleteSocialMediaError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_SOCIAL_MEDIA:
      return {
        ...state,
        deleteSocialMediaLoading: true,
        deleteSocialMediaData: null,
        deleteSocialMediaError: null,
      };
    case DELETE_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        deleteSocialMediaData: action.payload,
        deleteSocialMediaLoading: false,
        deleteSocialMediaError: null,
      };
    case DELETE_SOCIAL_MEDIA_FAILED:
      return {
        ...state,
        deleteSocialMediaLoading: false,
        deleteSocialMediaData: null,
        deleteSocialMediaError: action.payload,
      };
    case DELETE_SOCIAL_MEDIA_RESET:
      return {
        ...state,
        deleteSocialMediaLoading: false,
        deleteSocialMediaData: null,
        deleteSocialMediaError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const socialMediaStatusReducer = (
  state = {
    socialMediaStatusData: null,
    socialMediaStatusLoading: false,
    socialMediaStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_SOCIAL_MEDIA_STATUS:
      return {
        ...state,
        socialMediaStatusLoading: true,
        socialMediaStatusData: null,
        socialMediaStatusError: null,
      };
    case UPDATE_SOCIAL_MEDIA_STATUS_SUCCESS:
      return {
        ...state,
        socialMediaStatusData: action.payload,
        socialMediaStatusLoading: false,
        socialMediaStatusError: null,
      };
    case UPDATE_SOCIAL_MEDIA_STATUS_FAILED:
      return {
        ...state,
        socialMediaStatusLoading: false,
        socialMediaStatusData: null,
        socialMediaStatusError: action.payload,
      };
    case UPDATE_SOCIAL_MEDIA_STATUS_RESET:
      return {
        ...state,
        socialMediaStatusLoading: false,
        socialMediaStatusData: null,
        socialMediaStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

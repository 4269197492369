import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_FAILED,
  GET_COURSE_RESET,
  GET_ALL_COURSE_RESET,
  GET_ALL_COURSE_FAILED,
  GET_ALL_COURSE_SUCCESS,
  GET_ALL_COURSE,
  CREATE_COURSE,
  CREATE_COURSE_FAILED,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_RESET,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILED,
  UPDATE_COURSE_RESET,
  DELETE_COURSE,
  DELETE_COURSE_FAILED,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_RESET,
  UPDATE_COURSE_STATUS,
  UPDATE_COURSE_STATUS_SUCCESS,
  UPDATE_COURSE_STATUS_FAILED,
  UPDATE_COURSE_STATUS_RESET,
  GET_COURSE_BY_CALSSID_SUCCESS,
  GET_COURSE_BY_CALSSID,
  GET_COURSE_BY_CALSSID_FAILED,
  GET_COURSE_BY_CALSSID_RESET,
  GET_GRADE_BY_COURSEID,
  GET_GRADE_BY_COURSEID_RESET,
  GET_GRADE_BY_COURSEID_FAILED,
  GET_GRADE_BY_COURSEID_SUCCESS,
} from "./constants";

export function getCourse(requestBody) {
  return { type: GET_COURSE, payload: { requestBody } };
}

export function getCourseSuccess(result) {
  return { type: GET_COURSE_SUCCESS, payload: result };
}

export function getCourseFailed(error) {
  return { type: GET_COURSE_FAILED, payload: error };
}

export function getCourseReset() {
  return { type: GET_COURSE_RESET, payload: {} };
}

export function getAllCourse() {
  return { type: GET_ALL_COURSE, payload: {} };
}

export function getAllCourseSuccess(result) {
  return { type: GET_ALL_COURSE_SUCCESS, payload: result };
}

export function getAllCourseFailed(error) {
  return { type: GET_ALL_COURSE_FAILED, payload: error };
}

export function getAllCourseReset() {
  return { type: GET_ALL_COURSE_RESET, payload: {} };
}

export function createCourse(requestBody) {
  return { type: CREATE_COURSE, payload: { requestBody } };
}

export function createCourseSuccess(result) {
  return { type: CREATE_COURSE_SUCCESS, payload: result };
}

export function createCourseFailed(error) {
  return { type: CREATE_COURSE_FAILED, payload: error };
}

export function createCourseReset() {
  return { type: CREATE_COURSE_RESET, payload: {} };
}

export function updateCourse(requestBody, id) {
  return { type: UPDATE_COURSE, payload: { requestBody, id } };
}

export function updateCourseSuccess(result) {
  return { type: UPDATE_COURSE_SUCCESS, payload: result };
}

export function updateCourseReset() {
  return { type: UPDATE_COURSE_RESET, payload: {} };
}

export function updateCourseFailed(error) {
  return { type: UPDATE_COURSE_FAILED, payload: error };
}

export function deleteCourse(requestBody) {
  return { type: DELETE_COURSE, payload: { requestBody } };
}

export function deleteCourseSuccess(result) {
  return { type: DELETE_COURSE_SUCCESS, payload: result };
}
export function deleteCourseFailed(error) {
  return { type: DELETE_COURSE_FAILED, payload: error };
}

export function deleteCourseReset(error) {
  return { type: DELETE_COURSE_RESET, payload: error };
}

export function updateCourseStatus(requestBody) {
  return { type: UPDATE_COURSE_STATUS, payload: { requestBody } };
}

export function updateCourseStatusFailed(result) {
  return { type: UPDATE_COURSE_STATUS_FAILED, payload: result };
}

export function updateCourseStatusSuccess(error) {
  return { type: UPDATE_COURSE_STATUS_SUCCESS, payload: error };
}

export function updateCourseStatusReset(error) {
  return { type: UPDATE_COURSE_STATUS_RESET, payload: { error } };
}

export function getCourseByClassId(requestBody) {
  return { type: GET_COURSE_BY_CALSSID, payload: { requestBody } };
}

export function getCourseByClassIdSuccess(result) {
  return { type: GET_COURSE_BY_CALSSID_SUCCESS, payload: result };
}

export function getCourseByClassIdFailed(error) {
  return { type: GET_COURSE_BY_CALSSID_FAILED, payload: error };
}

export function getCourseByClassIdReset() {
  return { type: GET_COURSE_BY_CALSSID_RESET, payload: {} };
}

export function getGradeByCourseId(requestBody) {
  console.log("getGradeByCourseId", requestBody);
  return { type: GET_GRADE_BY_COURSEID, payload: { requestBody } };
}

export function getGradeByCourseIdSuccess(result) {
  return { type: GET_GRADE_BY_COURSEID_SUCCESS, payload: result };
}

export function getGradeByCourseIdFailed(error) {
  return { type: GET_GRADE_BY_COURSEID_FAILED, payload: error };
}

export function getGradeByCourseIdReset() {
  return { type: GET_GRADE_BY_COURSEID_RESET, payload: {} };
}

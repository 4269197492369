export const GET_CLASSROOM = "GET_CLASSROOM";
export const GET_CLASSROOM_SUCCESS = "GET_CLASSROOM_SUCCESS";
export const GET_CLASSROOM_FAILED = "GET_CLASSROOM_FAILED";
export const GET_CLASSROOM_RESET = "GET_CLASSROOM_RESET";
export const CREATE_CLASSROOM = "CREATE_CLASSROOM";
export const CREATE_CLASSROOM_SUCCESS = "CREATE_CLASSROOM_SUCCESS";
export const CREATE_CLASSROOM_FAILED = "CREATE_CLASSROOM_FAILED";
export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const UPDATE_CLASSROOM_SUCCESS = "UPDATE_CLASSROOM_SUCCESS";
export const UPDATE_CLASSROOM_FAILED = "UPDATE_CLASSROOM_FAILED";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const DELETE_CLASSROOM_SUCCESS = "DELETE_CLASSROOM_SUCCESS";
export const DELETE_CLASSROOM_FAILED = "DELETE_CLASSROOM_FAILED";
export const UPDATE_CLASSROOM_STATUS = "UPDATE_CLASSROOM_STATUS";
export const UPDATE_CLASSROOM_STATUS_SUCCESS =
  "UPDATE_CLASSROOM_STATUS_SUCCESS";
export const UPDATE_CLASSROOM_STATUS_FAILED = "UPDATE_CLASSROOM_STATUS_FAILED";
export const UPDATE_CLASSROOM_RESET = "UPDATE_CLASSROOM_RESET";
export const CREATE_CLASSROOM_RESET = "CREATE_CLASSROOM_RESET";
export const DELETE_CLASSROOM_RESET = "DELETE_CLASSROOM_RESET";
export const UPDATE_CLASSROOM_STATUS_RESET = "UPDATE_CLASSROOM_STATUS_RESET";

import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_LEAD,
  DELETE_LEAD,
  GET_LEAD,
  GET_LEAD_REPORT_MONTH,
  GET_LEAD_REPORT_WEEK,
  GET_LEAD_REPORT_YEAR,
  UPDATE_LEAD,
  UPDATE_LEAD_STATUS,
} from "./constants";
import {
  createLeadApi,
  deleteLeadApi,
  getLeadApi,
  getLeadReportApi,
  getLeadReportMonthApi,
  getLeadReportWeekApi,
  getLeadReportYearApi,
  updateLeadApi,
  updateLeadStatusApi,
} from "./api";
import {
  createLeadFailed,
  createLeadSuccess,
  deleteLeadFailed,
  deleteLeadSuccess,
  getLeadFailed,
  getLeadReportByMonthFailed,
  getLeadReportByMonthSuccess,
  getLeadReportByWeekFailed,
  getLeadReportByWeekSuccess,
  getLeadReportByYearFailed,
  getLeadReportByYearSuccess,
  getLeadSuccess,
  updateLeadFailed,
  updateLeadStatusFailed,
  updateLeadStatusSuccess,
  updateLeadSuccess,
} from "./action";

function* getLead(action) {
  try {
    const response = yield call(getLeadApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getLeadSuccess(response));
    } else {
      yield put(getLeadFailed(response));
    }
  } catch (e) {
    yield put(getLeadFailed(e.response));
  }
}

function* createLead(action) {
  try {
    const response = yield call(createLeadApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createLeadSuccess(response));
    } else {
      yield put(createLeadFailed(response));
    }
  } catch (e) {
    yield put(createLeadFailed(e.response));
  }
}

function* updateLead(action) {
  try {
    const response = yield call(updateLeadApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateLeadSuccess(response));
    } else {
      yield put(updateLeadFailed(response));
    }
  } catch (e) {
    yield put(updateLeadFailed(e.response));
  }
}

function* updateLeadStatus(action) {
  try {
    const response = yield call(
      updateLeadStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateLeadStatusSuccess(response));
    } else {
      yield put(updateLeadStatusFailed(response));
    }
  } catch (e) {
    yield put(updateLeadStatusFailed(e.response));
  }
}

function* deleteLead(action) {
  try {
    const response = yield call(deleteLeadApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteLeadSuccess(response));
    } else {
      yield put(deleteLeadFailed(response));
    }
  } catch (e) {
    yield put(updateLeadFailed(e.response));
  }
}

function* getLeadReportMonth(action) {
  try {
    const response = yield call(
      getLeadReportMonthApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(getLeadReportByMonthSuccess(response));
    } else {
      yield put(getLeadReportByMonthFailed(response));
    }
  } catch (e) {
    yield put(getLeadReportByMonthFailed(e.response));
  }
}

function* getLeadReportYear(action) {
  try {
    const response = yield call(
      getLeadReportYearApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(getLeadReportByYearSuccess(response));
    } else {
      yield put(getLeadReportByYearFailed(response));
    }
  } catch (e) {
    yield put(getLeadReportByYearFailed(e.response));
  }
}

function* getLeadReportWeek(action) {
  try {
    const response = yield call(
      getLeadReportWeekApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      console.log(response);
      yield put(getLeadReportByWeekSuccess(response));
    } else {
      yield put(getLeadReportByWeekFailed(response));
    }
  } catch (e) {
    yield put(getLeadReportByWeekFailed(e.response));
  }
}

export default function* getLeadSaga() {
  yield takeEvery(GET_LEAD, getLead);
  yield takeEvery(CREATE_LEAD, createLead);
  yield takeEvery(UPDATE_LEAD, updateLead);
  yield takeEvery(DELETE_LEAD, deleteLead);
  yield takeEvery(UPDATE_LEAD_STATUS, updateLeadStatus);
  yield takeEvery(GET_LEAD_REPORT_MONTH, getLeadReportMonth);
  yield takeEvery(GET_LEAD_REPORT_WEEK, getLeadReportWeek);
  yield takeEvery(GET_LEAD_REPORT_YEAR, getLeadReportYear);
}

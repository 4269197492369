export const GET_ALL_SESSION = "GET_ALL_SESSION";
export const GET_ALL_SESSION_SUCCESS = "GET_ALL_SESSION_SUCCESS";
export const GET_ALL_SESSION_FAILED = "GET_ALL_SESSION_FAILED";
export const GET_ALL_SESSION_RESET = "GET_ALL_SESSION_RESET";
export const GET_SESSION = "GET_SESSION";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_FAILED = "GET_SESSION_FAILED";
export const GET_SESSION_RESET = "GET_SESSION_RESET";
export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_FAILED = "CREATE_SESSION_FAILED";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const UPDATE_SESSION_SUCCESS = "UPDATE_SESSION_SUCCESS";
export const UPDATE_SESSION_FAILED = "UPDATE_SESSION_FAILED";
export const DELETE_SESSION = "DELETE_SESSION";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_FAILED = "DELETE_SESSION_FAILED";
export const UPDATE_SESSION_STATUS = "UPDATE_SESSION_STATUS";
export const UPDATE_SESSION_STATUS_SUCCESS = "UPDATE_SESSION_STATUS_SUCCESS";
export const UPDATE_SESSION_STATUS_FAILED = "UPDATE_SESSION_STATUS_FAILED";
export const UPDATE_SESSION_RESET = "UPDATE_SESSION_RESET";
export const CREATE_SESSION_RESET = "CREATE_SESSION_RESET";
export const DELETE_SESSION_RESET = "DELETE_SESSION_RESET";
export const UPDATE_SESSION_STATUS_RESET = "UPDATE_SESSION_STATUS_RESET";

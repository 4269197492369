import { put, call, takeEvery } from 'redux-saga/effects'
import {
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_ALL_ROLE,
  GET_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_STATUS,
} from './constants'
import {
  createEmployeeApi,
  deleteEmployeeApi,
  getAllRoleApi,
  getEmployeeApi,
  updateEmployeeApi,
  updateEmployeeStatusApi,
} from './api'
import {
  createEmployeeFailed,
  createEmployeeSuccess,
  deleteEmployeeFailed,
  deleteEmployeeSuccess,
  getAllRoleFailed,
  getAllRoleSuccess,
  getEmployeeFailed,
  getEmployeeSuccess,
  updateEmployeeFailed,
  updateEmployeeStatusFailed,
  updateEmployeeStatusSuccess,
  updateEmployeeSuccess,
} from './action'

function* getEmployee(action) {
  try {
    const response = yield call(getEmployeeApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getEmployeeSuccess(response))
    } else {
      yield put(getEmployeeFailed(response))
    }
  } catch (e) {
    yield put(getEmployeeFailed(e.response))
  }
}

function* getAllRole(action) {
  try {
    const response = yield call(getAllRoleApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getAllRoleSuccess(response))
    } else {
      yield put(getAllRoleFailed(response))
    }
  } catch (e) {
    yield put(getAllRoleFailed(e.response))
  }
}

function* createEmployee(action) {
  try {
    const response = yield call(createEmployeeApi, action.payload.requestBody)
    if (response && response.status === 201) {
      yield put(createEmployeeSuccess(response))
    } else {
      yield put(createEmployeeFailed(response))
    }
  } catch (e) {
    yield put(createEmployeeFailed(e.response))
  }
}

function* updateEmployee(action) {
  try {
    const response = yield call(updateEmployeeApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(updateEmployeeSuccess(response))
    } else {
      yield put(updateEmployeeFailed(response))
    }
  } catch (e) {
    yield put(getEmployeeFailed(e.response))
  }
}

function* updateEmployeeStatus(action) {
  try {
    const response = yield call(updateEmployeeStatusApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(updateEmployeeStatusSuccess(response))
    } else {
      yield put(updateEmployeeStatusFailed(response))
    }
  } catch (e) {
    yield put(updateEmployeeStatusFailed(e.response))
  }
}

function* deleteEmployee(action) {
  try {
    const response = yield call(deleteEmployeeApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(deleteEmployeeSuccess(response))
    } else {
      yield put(deleteEmployeeFailed(response))
    }
  } catch (e) {
    yield put(updateEmployeeFailed(e.response))
  }
}

export default function* getEmployeeSaga() {
  yield takeEvery(GET_EMPLOYEE, getEmployee)
  yield takeEvery(CREATE_EMPLOYEE, createEmployee)
  yield takeEvery(UPDATE_EMPLOYEE, updateEmployee)
  yield takeEvery(UPDATE_EMPLOYEE_STATUS, updateEmployeeStatus)
  yield takeEvery(DELETE_EMPLOYEE, deleteEmployee)
  yield takeEvery(GET_ALL_ROLE, getAllRole)
}

import {
  GET_COURSES_GRADE,
  GET_COURSES_GRADE_SUCCESS,
  GET_COURSES_GRADE_FAILED,
  GET_COURSES_GRADE_RESET,
  CREATE_COURSES_GRADE,
  CREATE_COURSES_GRADE_FAILED,
  CREATE_COURSES_GRADE_SUCCESS,
  UPDATE_COURSES_GRADE,
  UPDATE_COURSES_GRADE_FAILED,
  UPDATE_COURSES_GRADE_SUCCESS,
  UPDATE_COURSES_GRADE_RESET,
  CREATE_COURSES_GRADE_RESET,
  GET_COURSES_AND_GRADE,
  GET_COURSES_AND_GRADE_SUCCESS,
  GET_COURSES_AND_GRADE_FAILED,
  GET_COURSES_AND_GRADE_RESET,
  DELETE_COURSES_GRADE,
  DELETE_COURSES_GRADE_FAILED,
  DELETE_COURSES_GRADE_SUCCESS,
  ASSIGN_GRADE_TO_COURSES,
  ASSIGN_GRADE_TO_COURSES_SUCCESS,
  ASSIGN_GRADE_TO_COURSES_FAILED,
  ASSIGN_GRADE_TO_COURSES_RESET,
} from "./constants";

export const courseGradeReducer = (
  state = {
    courseGradedata: null,
    courseGradeloading: false,
    courseGradeerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_COURSES_GRADE:
      return {
        ...state,
        courseGradeloading: true,
        courseGradedata: null,
        courseGradeerror: null,
      };
    case GET_COURSES_GRADE_SUCCESS:
      return {
        ...state,
        courseGradedata: action.payload,
        courseGradeloading: false,
        courseGradeerror: null,
      };
    case GET_COURSES_GRADE_FAILED:
      return {
        ...state,
        courseGradeloading: false,
        courseGradedata: null,
        courseGradeerror: action.payload,
      };
    case GET_COURSES_GRADE_RESET:
      return {
        ...state,
        courseGradeloading: false,
        courseGradedata: null,
        courseGradeerror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getAllCourseAndGradeReducer = (
  state = {
    getAllCourseAndGradedata: null,
    getAllCourseAndGradeloading: false,
    getAllCourseAndGradeerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_COURSES_AND_GRADE:
      return {
        ...state,
        getAllCourseAndGradeloading: true,
        getAllCourseAndGradedata: null,
        getAllCourseAndGradeerror: null,
      };
    case GET_COURSES_AND_GRADE_SUCCESS:
      return {
        ...state,
        getAllCourseAndGradedata: action.payload,
        getAllCourseAndGradeloading: false,
        getAllCourseAndGradeerror: null,
      };
    case GET_COURSES_AND_GRADE_FAILED:
      return {
        ...state,
        getAllCourseAndGradeloading: false,
        getAllCourseAndGradedata: null,
        getAllCourseAndGradeerror: action.payload,
      };
    case GET_COURSES_AND_GRADE_RESET:
      return {
        ...state,
        getAllCourseAndGradeloading: false,
        getAllCourseAndGradedata: null,
        getAllCourseAndGradeerror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createCourseGradeReducer = (
  state = {
    createCourseGradeData: null,
    createCourseGradeLoading: false,
    createCourseGradeError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_COURSES_GRADE:
      return {
        ...state,
        createCourseGradeLoading: true,
        createCourseGradeData: null,
        createCourseGradeError: null,
      };
    case CREATE_COURSES_GRADE_SUCCESS:
      return {
        ...state,
        createCourseGradeData: action.payload,
        createCourseGradeLoading: false,
        createCourseGradeError: null,
      };
    case CREATE_COURSES_GRADE_FAILED:
      return {
        ...state,
        createCourseGradeLoading: false,
        createCourseGradeData: null,
        createCourseGradeError: action.payload,
      };
    case CREATE_COURSES_GRADE_RESET:
      return {
        ...state,
        createCourseGradeLoading: false,
        createCourseGradeData: null,
        createCourseGradeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateCourseGradeReducer = (
  state = {
    updateCourseGradeData: null,
    updateCourseGradeLoading: false,
    updateCourseGradeError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_COURSES_GRADE:
      return {
        ...state,
        updateCourseGradeLoading: true,
        updateCourseGradeData: null,
        updateCourseGradeError: null,
      };
    case UPDATE_COURSES_GRADE_SUCCESS:
      console.log("UPDATE_COURSES_GRADE_SUCCESS", UPDATE_COURSES_GRADE_SUCCESS);
      return {
        ...state,
        updateCourseGradeData: action.payload,
        updateCourseGradeLoading: false,
        updateCourseGradeError: null,
      };
    case UPDATE_COURSES_GRADE_FAILED:
      return {
        ...state,
        updateCourseGradeLoading: false,
        updateCourseGradeData: null,
        updateCourseGradeError: action.payload,
      };
    case UPDATE_COURSES_GRADE_RESET:
      return {
        ...state,
        updateCourseGradeLoading: false,
        updateCourseGradeData: null,
        updateCourseGradeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteCourseGradeReducer = (
  state = {
    deleteCourseGradeData: null,
    deleteCourseGradeLoading: false,
    deleteCourseGradeError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_COURSES_GRADE:
      return {
        ...state,
        deleteCourseGradeLoading: true,
        deleteCourseGradeData: null,
        deleteCourseGradeError: null,
      };
    case DELETE_COURSES_GRADE_SUCCESS:
      return {
        ...state,
        deleteCourseGradeData: action.payload,
        deleteCourseGradeLoading: false,
        deleteCourseGradeError: null,
      };
    case DELETE_COURSES_GRADE_FAILED:
      return {
        ...state,
        deleteCourseGradeLoading: false,
        deleteCourseGradeData: null,
        deleteCourseGradeError: action.payload,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const courseGradeStatusReducer = (
  state = {
    courseGradeStatusData: null,
    courseGradeloading: false,
    courseGradeerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_COURSES_GRADE:
      return {
        ...state,
        courseGradeStatusLoading: true,
        courseGradeStatusData: null,
        courseGradeStatusError: null,
      };
    case GET_COURSES_GRADE_SUCCESS:
      return {
        ...state,
        courseGradeStatusData: action.payload,
        courseGradeStatusLoading: false,
        courseGradeStatusError: null,
      };
    case GET_COURSES_GRADE_FAILED:
      return {
        ...state,
        courseGradeStatusLoading: false,
        courseGradeStatusData: null,
        courseGradeStatusError: action.payload,
      };
    case GET_COURSES_GRADE_RESET:
      return {
        ...state,
        courseGradeStatusLoading: false,
        courseGradeStatusData: null,
        courseGradeStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const assignCourseGradeReducer = (
  state = {
    assignCourseGradeData: null,
    assignCourseGradeLoading: false,
    assignCourseGradeError: null,
  },
  action
) => {
  switch (action.type) {
    case ASSIGN_GRADE_TO_COURSES:
      return {
        ...state,
        assignCourseGradeLoading: true,
        assignCourseGradeData: null,
        assignCourseGradeError: null,
      };
    case ASSIGN_GRADE_TO_COURSES_SUCCESS:
      return {
        ...state,
        assignCourseGradeData: action.payload,
        assignCourseGradeLoading: false,
        assignCourseGradeError: null,
      };
    case ASSIGN_GRADE_TO_COURSES_FAILED:
      return {
        ...state,
        assignCourseGradeLoading: false,
        assignCourseGradeData: null,
        assignCourseGradeError: action.payload,
      };
    case ASSIGN_GRADE_TO_COURSES_RESET:
      return {
        ...state,
        assignCourseGradeLoading: false,
        assignCourseGradeData: null,
        assignCourseGradeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import {
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILED,
  GET_VEHICLE_RESET,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE,
  CREATE_VEHICLE_FAILED,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILED,
  UPDATE_VEHICLE_RESET,
  CREATE_VEHICLE_RESET,
  DELETE_VEHICLE,
  DELETE_VEHICLE_FAILED,
  DELETE_VEHICLE_RESET,
  DELETE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_STATUS,
  UPDATE_VEHICLE_STATUS_RESET,
  UPDATE_VEHICLE_STATUS_FAILED,
  UPDATE_VEHICLE_STATUS_SUCCESS,
} from "./constants";

export function getVehicle() {
  return { type: GET_VEHICLE, payload: {} };
}

export function getVehicleSuccess(result) {
  return { type: GET_VEHICLE_SUCCESS, payload: result };
}

export function getVehicleFailed(error) {
  return { type: GET_VEHICLE_FAILED, payload: error };
}

export function getVehicleReset() {
  return { type: GET_VEHICLE_RESET, payload: {} };
}

export function createVehicle(requestBody) {
  console.log("action createVehicle")
  return { type: CREATE_VEHICLE, payload: { requestBody } };
}

export function createVehicleSuccess(result) {
  console.log("action createVehicleSuccess")
  return { type: CREATE_VEHICLE_SUCCESS, payload: result };
}

export function createVehicleFailed(error) {
  console.log("action createVehicleFailed")
  return { type: CREATE_VEHICLE_FAILED, payload: error };
}

export function createVehicleReset() {
  console.log("action createVehicleReset")
  return { type: CREATE_VEHICLE_RESET, payload: {} };
}

export function updateVehicle(requestBody) {
  console.log("action updateVehicle");
  return { type: UPDATE_VEHICLE, payload: { requestBody } };
}

export function updateVehicleSuccess(result) {
  return { type: UPDATE_VEHICLE_SUCCESS, payload: result };
}

export function updateVehicleFailed(error) {
  return { type: UPDATE_VEHICLE_FAILED, payload: error };
}

export function updateVehicleReset() {
  return { type: UPDATE_VEHICLE_RESET, payload: {} };
}

export function deleteVehicle(requestBody) {
  return { type: DELETE_VEHICLE, payload: { requestBody } };
}

export function deleteVehicleSuccess(result) {
  return { type: DELETE_VEHICLE_SUCCESS, payload: result };
}

export function deleteVehicleFailed(error) {
  return { type: DELETE_VEHICLE_FAILED, payload: error };
}

export function deleteVehicleReset() {
  return { type: DELETE_VEHICLE_RESET, payload: {} };
}

export function updateVehicleStatus(requestBody) {
  return { type: UPDATE_VEHICLE_STATUS, payload: { requestBody } };
}

export function updateVehicleStatusSuccess(result) {
  return { type: UPDATE_VEHICLE_STATUS_SUCCESS, payload: result };
}

export function updateVehicleStatusFailed(error) {
  return { type: UPDATE_VEHICLE_STATUS_FAILED, payload: error };
}

export function updateVehicleStatusReset() {
  return { type: UPDATE_VEHICLE_STATUS_RESET, payload: {} };
}

export const GET_CAMP = "GET_CAMP";
export const GET_CAMP_SUCCESS = "GET_CAMP_SUCCESS";
export const GET_CAMP_FAILED = "GET_CAMP_FAILED";
export const GET_CAMP_RESET = "GET_CAMP_RESET";

export const GET_CAMP_AND_COURSE = "GET_CAMP_AND_COURSE";
export const GET_CAMP_AND_COURSE_SUCCESS = "GET_CAMP_AND_COURSE_SUCCESS";
export const GET_CAMP_AND_COURSE_FAILED = "GET_CAMP_AND_COURSE_FAILED";
export const GET_CAMP_AND_COURSE_RESET = "GET_CAMP_AND_COURSE_RESET";

export const GET_CAMP_BY_ID = "GET_CAMP_BY_ID";
export const GET_CAMP_BY_ID_SUCCESS = "GET_CAMP_BY_ID_SUCCESS";
export const GET_CAMP_BY_ID_FAILED = "GET_CAMP_BY_ID_FAILED";
export const GET_CAMP_BY_ID_RESET = "GET_CAMP_BY_ID_RESET";

export const CREATE_CAMP = "CREATE_CAMP";
export const CREATE_CAMP_SUCCESS = "CREATE_CAMP_SUCCESS";
export const CREATE_CAMP_FAILED = "CREATE_CAMP_FAILED";
export const UPDATE_CAMP = "UPDATE_CAMP";
export const UPDATE_CAMP_SUCCESS = "UPDATE_CAMP_SUCCESS";
export const UPDATE_CAMP_FAILED = "UPDATE_CAMP_FAILED";
export const DELETE_CAMP = "DELETE_CAMP";
export const DELETE_CAMP_SUCCESS = "DELETE_CAMP_SUCCESS";
export const DELETE_CAMP_FAILED = "DELETE_CAMP_FAILED";
export const UPDATE_CAMP_STATUS = "UPDATE_CAMP_STATUS";
export const UPDATE_CAMP_STATUS_SUCCESS = "UPDATE_CAMP_STATUS_SUCCESS";
export const UPDATE_CAMP_STATUS_FAILED = "UPDATE_CAMP_STATUS_FAILED";
export const UPDATE_CAMP_RESET = "UPDATE_CAMP_RESET";
export const CREATE_CAMP_RESET = "CREATE_CAMP_RESET";
export const DELETE_CAMP_RESET = "DELETE_CAMP_RESET";
export const UPDATE_CAMP_STATUS_RESET = "UPDATE_CAMP_STATUS_RESET";
export const GET_CAMP_TYPE = "GET_CAMP_TYPE";
export const GET_CAMP_TYPE_SUCCESS = "GET_CAMP_TYPE_SUCCESS";
export const GET_CAMP_TYPE_FAILED = "GET_CAMP_TYPE_FAILED";
export const GET_CAMP_TYPE_RESET = "GET_CAMP_TYPE_RESET";
export const CREATE_CAMP_TYPE = "CREATE_CAMP_TYPE";
export const CREATE_CAMP_TYPE_SUCCESS = "CREATE_CAMP_TYPE_SUCCESS";
export const CREATE_CAMP_TYPE_FAILED = "CREATE_CAMP_TYPE_FAILED";
export const UPDATE_CAMP_TYPE = "UPDATE_CAMP_TYPE";
export const UPDATE_CAMP_TYPE_SUCCESS = "UPDATE_CAMP_TYPE_SUCCESS";
export const UPDATE_CAMP_TYPE_FAILED = "UPDATE_CAMP_TYPE_FAILED";
export const DELETE_CAMP_TYPE = "DELETE_CAMP_TYPE";
export const DELETE_CAMP_TYPE_SUCCESS = "DELETE_CAMP_TYPE_SUCCESS";
export const DELETE_CAMP_TYPE_FAILED = "DELETE_CAMP_TYPE_FAILED";
export const UPDATE_CAMP_TYPE_STATUS = "UPDATE_CAMP_TYPE_STATUS";
export const UPDATE_CAMP_TYPE_STATUS_SUCCESS =
  "UPDATE_CAMP_TYPE_STATUS_SUCCESS";
export const UPDATE_CAMP_TYPE_STATUS_FAILED = "UPDATE_CAMP_TYPE_STATUS_FAILED";
export const UPDATE_CAMP_TYPE_RESET = "UPDATE_CAMP_TYPE_RESET";
export const CREATE_CAMP_TYPE_RESET = "CREATE_CAMP_TYPE_RESET";
export const DELETE_CAMP_TYPE_RESET = "DELETE_CAMP_TYPE_RESET";
export const UPDATE_CAMP_TYPE_STATUS_RESET = "UPDATE_CAMP_TYPE_STATUS_RESET";

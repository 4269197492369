import { GET_PARENT, GET_PARENT_SUCCESS, GET_PARENT_FAILED, GET_PARENT_RESET } from './constants'

export function getAllParent(requestBody) {
  return { type: GET_PARENT, payload: { requestBody } }
}

export function getAllParentSuccess(result) {
  return { type: GET_PARENT_SUCCESS, payload: result }
}

export function getAllParentFailed(error) {
  return { type: GET_PARENT_FAILED, payload: error }
}

export function getAllParentReset() {
  return { type: GET_PARENT_RESET, payload: {} }
}

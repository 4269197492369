import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../redux/rootReducers'
import rootSaga from '../redux/rootSagas'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

const routerMiddleware1 = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const middleware = [routerMiddleware1, sagaMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))
sagaMiddleware.run(rootSaga)

export default store

import {
  GET_CAMP,
  GET_CAMP_SUCCESS,
  GET_CAMP_FAILED,
  GET_CAMP_RESET,
  CREATE_CAMP_SUCCESS,
  CREATE_CAMP,
  CREATE_CAMP_FAILED,
  UPDATE_CAMP,
  UPDATE_CAMP_SUCCESS,
  UPDATE_CAMP_FAILED,
  DELETE_CAMP,
  DELETE_CAMP_SUCCESS,
  DELETE_CAMP_FAILED,
  UPDATE_CAMP_STATUS_SUCCESS,
  UPDATE_CAMP_STATUS,
  UPDATE_CAMP_STATUS_FAILED,
  UPDATE_CAMP_RESET,
  CREATE_CAMP_RESET,
  DELETE_CAMP_RESET,
  UPDATE_CAMP_STATUS_RESET,
  GET_CAMP_TYPE,
  GET_CAMP_TYPE_SUCCESS,
  GET_CAMP_TYPE_FAILED,
  GET_CAMP_TYPE_RESET,
  CREATE_CAMP_TYPE_SUCCESS,
  CREATE_CAMP_TYPE,
  CREATE_CAMP_TYPE_FAILED,
  UPDATE_CAMP_TYPE,
  UPDATE_CAMP_TYPE_SUCCESS,
  UPDATE_CAMP_TYPE_FAILED,
  DELETE_CAMP_TYPE,
  DELETE_CAMP_TYPE_SUCCESS,
  DELETE_CAMP_TYPE_FAILED,
  UPDATE_CAMP_TYPE_STATUS_SUCCESS,
  UPDATE_CAMP_TYPE_STATUS,
  UPDATE_CAMP_TYPE_STATUS_FAILED,
  UPDATE_CAMP_TYPE_RESET,
  CREATE_CAMP_TYPE_RESET,
  DELETE_CAMP_TYPE_RESET,
  UPDATE_CAMP_TYPE_STATUS_RESET,
  GET_CAMP_BY_ID_SUCCESS,
  GET_CAMP_BY_ID_FAILED,
  GET_CAMP_BY_ID_RESET,
  GET_CAMP_BY_ID,
  GET_CAMP_AND_COURSE_SUCCESS,
  GET_CAMP_AND_COURSE_FAILED,
  GET_CAMP_AND_COURSE_RESET,
  GET_CAMP_AND_COURSE,
} from "./constants";

export function getCamp(requestBody) {
  return { type: GET_CAMP, payload: { requestBody } };
}

export function getCampSuccess(result) {
  return { type: GET_CAMP_SUCCESS, payload: result };
}

export function getCampFailed(error) {
  return { type: GET_CAMP_FAILED, payload: error };
}

export function getCampReset() {
  return { type: GET_CAMP_RESET, payload: {} };
}

export function getCampAndCourse(requestBody) {
  console.log("getCampAndCourse");
  return { type: GET_CAMP_AND_COURSE, payload: { requestBody } };
}

export function getCampAndCourseSuccess(result) {
  return { type: GET_CAMP_AND_COURSE_SUCCESS, payload: result };
}

export function getCampAndCourseFailed(error) {
  return { type: GET_CAMP_AND_COURSE_FAILED, payload: error };
}

export function getCampAndCourseReset() {
  return { type: GET_CAMP_AND_COURSE_RESET, payload: {} };
}

export function getCampById(requestBody) {
  return { type: GET_CAMP_BY_ID, payload: { requestBody } };
}

export function getCampByIdSuccess(result) {
  return { type: GET_CAMP_BY_ID_SUCCESS, payload: result };
}

export function getCampByIdFailed(error) {
  return { type: GET_CAMP_BY_ID_FAILED, payload: error };
}

export function getCampByIdReset() {
  return { type: GET_CAMP_BY_ID_RESET, payload: {} };
}

export function createCamp(requestBody) {
  return { type: CREATE_CAMP, payload: { requestBody } };
}

export function createCampSuccess(result) {
  return { type: CREATE_CAMP_SUCCESS, payload: result };
}

export function createCampFailed(error) {
  return { type: CREATE_CAMP_FAILED, payload: error };
}

export function createCampReset() {
  return { type: CREATE_CAMP_RESET, payload: {} };
}

export function updateCamp(requestBody) {
  return { type: UPDATE_CAMP, payload: { requestBody } };
}

export function updateCampSuccess(result) {
  return { type: UPDATE_CAMP_SUCCESS, payload: result };
}

export function updateCampFailed(error) {
  return { type: UPDATE_CAMP_FAILED, payload: error };
}

export function updateCampReset() {
  return { type: UPDATE_CAMP_RESET, payload: {} };
}

export function updateCampStatus(requestBody) {
  return { type: UPDATE_CAMP_STATUS, payload: { requestBody } };
}

export function updateCampStatusSuccess(result) {
  return { type: UPDATE_CAMP_STATUS_SUCCESS, payload: result };
}

export function updateCampStatusFailed(error) {
  return { type: UPDATE_CAMP_STATUS_FAILED, payload: error };
}

export function updateCampStatusReset(error) {
  return { type: UPDATE_CAMP_STATUS_RESET, payload: error };
}

export function deleteCamp(requestBody) {
  return { type: DELETE_CAMP, payload: { requestBody } };
}

export function deleteCampSuccess(result) {
  return { type: DELETE_CAMP_SUCCESS, payload: result };
}

export function deleteCampFailed(error) {
  return { type: DELETE_CAMP_FAILED, payload: error };
}

export function deleteCampReset() {
  return { type: DELETE_CAMP_RESET, payload: {} };
}

export function getCampType(requestBody) {
  return { type: GET_CAMP_TYPE, payload: { requestBody } };
}

export function getCampTypeSuccess(result) {
  return { type: GET_CAMP_TYPE_SUCCESS, payload: result };
}

export function getCampTypeFailed(error) {
  return { type: GET_CAMP_TYPE_FAILED, payload: error };
}

export function getCampTypeReset() {
  return { type: GET_CAMP_TYPE_RESET, payload: {} };
}

export function createCampType(requestBody) {
  return { type: CREATE_CAMP_TYPE, payload: { requestBody } };
}

export function createCampTypeSuccess(result) {
  return { type: CREATE_CAMP_TYPE_SUCCESS, payload: result };
}

export function createCampTypeFailed(error) {
  return { type: CREATE_CAMP_TYPE_FAILED, payload: error };
}

export function createCampTypeReset() {
  return { type: CREATE_CAMP_TYPE_RESET, payload: {} };
}

export function updateCampType(requestBody) {
  return { type: UPDATE_CAMP_TYPE, payload: { requestBody } };
}

export function updateCampTypeSuccess(result) {
  return { type: UPDATE_CAMP_TYPE_SUCCESS, payload: result };
}

export function updateCampTypeFailed(error) {
  return { type: UPDATE_CAMP_TYPE_FAILED, payload: error };
}

export function updateCampTypeReset() {
  return { type: UPDATE_CAMP_TYPE_RESET, payload: {} };
}

export function updateCampTypeStatus(requestBody) {
  return { type: UPDATE_CAMP_TYPE_STATUS, payload: { requestBody } };
}

export function updateCampTypeStatusSuccess(result) {
  return { type: UPDATE_CAMP_TYPE_STATUS_SUCCESS, payload: result };
}

export function updateCampTypeStatusFailed(error) {
  return { type: UPDATE_CAMP_TYPE_STATUS_FAILED, payload: error };
}

export function updateCampTypeStatusReset(error) {
  return { type: UPDATE_CAMP_TYPE_STATUS_RESET, payload: error };
}

export function deleteCampType(requestBody) {
  return { type: DELETE_CAMP_TYPE, payload: { requestBody } };
}

export function deleteCampTypeSuccess(result) {
  return { type: DELETE_CAMP_TYPE_SUCCESS, payload: result };
}

export function deleteCampTypeFailed(error) {
  return { type: DELETE_CAMP_TYPE_FAILED, payload: error };
}

export function deleteCampTypeReset() {
  return { type: DELETE_CAMP_TYPE_RESET, payload: {} };
}

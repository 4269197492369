import {
  GET_SOCIAL_MEDIA,
  GET_SOCIAL_MEDIA_SUCCESS,
  GET_SOCIAL_MEDIA_FAILED,
  GET_SOCIAL_MEDIA_RESET,
  CREATE_SOCIAL_MEDIA_SUCCESS,
  CREATE_SOCIAL_MEDIA,
  CREATE_SOCIAL_MEDIA_FAILED,
  UPDATE_SOCIAL_MEDIA,
  UPDATE_SOCIAL_MEDIA_SUCCESS,
  UPDATE_SOCIAL_MEDIA_FAILED,
  DELETE_SOCIAL_MEDIA,
  DELETE_SOCIAL_MEDIA_SUCCESS,
  DELETE_SOCIAL_MEDIA_FAILED,
  UPDATE_SOCIAL_MEDIA_STATUS_SUCCESS,
  UPDATE_SOCIAL_MEDIA_STATUS,
  UPDATE_SOCIAL_MEDIA_STATUS_FAILED,
  UPDATE_SOCIAL_MEDIA_RESET,
  CREATE_SOCIAL_MEDIA_RESET,
  DELETE_SOCIAL_MEDIA_RESET,
  UPDATE_SOCIAL_MEDIA_STATUS_RESET,
  GET_ALL_SOCIAL_MEDIA_RESET,
  GET_ALL_SOCIAL_MEDIA,
  GET_ALL_SOCIAL_MEDIA_SUCCESS,
  GET_ALL_SOCIAL_MEDIA_FAILED,
} from "./constants";

export function getAllSocialMedia(requestBody) {
  return { type: GET_ALL_SOCIAL_MEDIA, payload: { requestBody } };
}

export function getAllSocialMediaSuccess(result) {
  return { type: GET_ALL_SOCIAL_MEDIA_SUCCESS, payload: result };
}

export function getAllSocialMediaFailed(error) {
  return { type: GET_ALL_SOCIAL_MEDIA_FAILED, payload: error };
}

export function getAllSocialMediaReset() {
  return { type: GET_ALL_SOCIAL_MEDIA_RESET, payload: {} };
}

export function getSocialMedia(requestBody) {
  return { type: GET_SOCIAL_MEDIA, payload: { requestBody } };
}

export function getSocialMediaSuccess(result) {
  return { type: GET_SOCIAL_MEDIA_SUCCESS, payload: result };
}

export function getSocialMediaFailed(error) {
  return { type: GET_SOCIAL_MEDIA_FAILED, payload: error };
}

export function getSocialMediaReset() {
  return { type: GET_SOCIAL_MEDIA_RESET, payload: {} };
}

export function createSocialMedia(requestBody) {
  return { type: CREATE_SOCIAL_MEDIA, payload: { requestBody } };
}

export function createSocialMediaSuccess(result) {
  return { type: CREATE_SOCIAL_MEDIA_SUCCESS, payload: result };
}

export function createSocialMediaFailed(error) {
  return { type: CREATE_SOCIAL_MEDIA_FAILED, payload: error };
}

export function createSocialMediaReset() {
  return { type: CREATE_SOCIAL_MEDIA_RESET, payload: {} };
}

export function updateSocialMedia(requestBody) {
  return { type: UPDATE_SOCIAL_MEDIA, payload: { requestBody } };
}

export function updateSocialMediaSuccess(result) {
  return { type: UPDATE_SOCIAL_MEDIA_SUCCESS, payload: result };
}

export function updateSocialMediaFailed(error) {
  return { type: UPDATE_SOCIAL_MEDIA_FAILED, payload: error };
}

export function updateSocialMediaReset() {
  return { type: UPDATE_SOCIAL_MEDIA_RESET, payload: {} };
}

export function updateSocialMediaStatus(requestBody) {
  return { type: UPDATE_SOCIAL_MEDIA_STATUS, payload: { requestBody } };
}

export function updateSocialMediaStatusSuccess(result) {
  return { type: UPDATE_SOCIAL_MEDIA_STATUS_SUCCESS, payload: result };
}

export function updateSocialMediaStatusFailed(error) {
  return { type: UPDATE_SOCIAL_MEDIA_STATUS_FAILED, payload: error };
}

export function updateSocialMediaStatusReset(error) {
  return { type: UPDATE_SOCIAL_MEDIA_STATUS_RESET, payload: error };
}

export function deleteSocialMedia(requestBody) {
  return { type: DELETE_SOCIAL_MEDIA, payload: { requestBody } };
}

export function deleteSocialMediaSuccess(result) {
  return { type: DELETE_SOCIAL_MEDIA_SUCCESS, payload: result };
}

export function deleteSocialMediaFailed(error) {
  return { type: DELETE_SOCIAL_MEDIA_FAILED, payload: error };
}

export function deleteSocialMediaReset() {
  return { type: DELETE_SOCIAL_MEDIA_RESET, payload: {} };
}

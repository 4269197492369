import { jwtDecode } from "jwt-decode";

export const checkTokenExpiration = () => {
  const decodedToken = jwtDecode(localStorage.getItem("token"));
  const currentTime = Date.now() / 1000;

  if (decodedToken.exp < currentTime) {
    // Token has expired
    localStorage.removeItem("token");
    setAuth(null);
    return false;
  }
  return true;
};

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILED = "GET_COURSE_FAILED";
export const GET_COURSE_RESET = "GET_COURSE_RESET";

export const GET_ALL_COURSE_TYPE = "GET_ALL_COURSE_TYPE";
export const GET_ALL_COURSE_TYPE_SUCCESS = "GET_ALL_COURSE_TYPE_SUCCESS";
export const GET_ALL_COURSE_TYPE_FAILED = "GET_ALL_COURSE_TYPE_FAILED";
export const GET_ALL_COURSE_TYPE_RESET = "GET_ALL_COURSE_TYPE_RESET";

export const GET_ALL_COURSE = "GET_ALL_COURSE";
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS";
export const GET_ALL_COURSE_FAILED = "GET_ALL_COURSE_FAILED";
export const GET_ALL_COURSE_RESET = "GET_ALL_COURSE_RESET";

export const CREATE_COURSE_TYPE = "CREATE_COURSE_TYPE";
export const CREATE_COURSE_TYPE_SUCCESS = "CREATE_COURSE_TYPE_SUCCESS";
export const CREATE_COURSE_TYPE_FAILED = "CREATE_COURSE_TYPE_FAILED";

export const UPDATE_COURSE_TYPE = "UPDATE_COURSE_TYPE";
export const UPDATE_COURSE_TYPE_SUCCESS = "UPDATE_COURSE_TYPE_SUCCESS";
export const UPDATE_COURSE_TYPE_FAILED = "UPDATE_COURSE_TYPE_FAILED";

export const DELETE_COURSE_TYPE = "DELETE_COURSE_TYPE";
export const DELETE_COURSE_TYPE_SUCCESS = "DELETE_COURSE_TYPE_SUCCESS";
export const DELETE_COURSE_TYPE_FAILED = "DELETE_COURSE_TYPE_FAILED";

export const CREATE_COURSE = "CREATE_COURSE";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILED = "CREATE_COURSE_FAILED";
export const CREATE_COURSE_RESET = "CREATE_COURSE_RESET";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILED = "UPDATE_COURSE_FAILED";
export const UPDATE_COURSE_RESET = "UPDATE_COURSE_RESET";
export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILED = "DELETE_COURSE_FAILED";
export const DELETE_COURSE_RESET = "DELETE_COURSE_RESET";
export const UPDATE_COURSE_STATUS = "UPDATE_COURSE_STATUS";
export const UPDATE_COURSE_STATUS_FAILED = "UPDATE_COURSE_STATUS_FAILED";
export const UPDATE_COURSE_STATUS_SUCCESS = "UPDATE_COURSE_STATUS_SUCCESS";
export const UPDATE_COURSE_STATUS_RESET = "UPDATE_COURSE_STATUS_RESET";

export const GET_COURSE_BY_CALSSID = "GET_COURSE_BY_CALSSID";
export const GET_COURSE_BY_CALSSID_SUCCESS = "GET_COURSE_BY_CALSSID_SUCCESS";
export const GET_COURSE_BY_CALSSID_FAILED = "GET_COURSE_BY_CALSSID_FAILED";
export const GET_COURSE_BY_CALSSID_RESET = "GET_COURSE_BY_CALSSID_RESET";

export const GET_GRADE_BY_COURSEID = "GET_GRADE_BY_COURSEID";
export const GET_GRADE_BY_COURSEID_SUCCESS = "GET_GRADE_BY_COURSEID_SUCCESS";
export const GET_GRADE_BY_COURSEID_FAILED = "GET_GRADE_BY_COURSEID_FAILED";
export const GET_GRADE_BY_COURSEID_RESET = "GET_COURSE_BY_CALSSID_RESET";

export const GET_ALL_SOCIAL_MEDIA = "GET_ALL_SOCIAL_MEDIA";
export const GET_ALL_SOCIAL_MEDIA_SUCCESS = "GET_ALL_SOCIAL_MEDIA_SUCCESS";
export const GET_ALL_SOCIAL_MEDIA_FAILED = "GET_ALL_SOCIAL_MEDIA_FAILED";
export const GET_ALL_SOCIAL_MEDIA_RESET = "GET_ALL_SOCIAL_MEDIA_RESET";
export const GET_SOCIAL_MEDIA = "GET_SOCIAL_MEDIA";
export const GET_SOCIAL_MEDIA_SUCCESS = "GET_SOCIAL_MEDIA_SUCCESS";
export const GET_SOCIAL_MEDIA_FAILED = "GET_SOCIAL_MEDIA_FAILED";
export const GET_SOCIAL_MEDIA_RESET = "GET_SOCIAL_MEDIA_RESET";
export const CREATE_SOCIAL_MEDIA = "CREATE_SOCIAL_MEDIA";
export const CREATE_SOCIAL_MEDIA_SUCCESS = "CREATE_SOCIAL_MEDIA_SUCCESS";
export const CREATE_SOCIAL_MEDIA_FAILED = "CREATE_SOCIAL_MEDIA_FAILED";
export const UPDATE_SOCIAL_MEDIA = "UPDATE_SOCIAL_MEDIA";
export const UPDATE_SOCIAL_MEDIA_SUCCESS = "UPDATE_SOCIAL_MEDIA_SUCCESS";
export const UPDATE_SOCIAL_MEDIA_FAILED = "UPDATE_SOCIAL_MEDIA_FAILED";
export const DELETE_SOCIAL_MEDIA = "DELETE_SOCIAL_MEDIA";
export const DELETE_SOCIAL_MEDIA_SUCCESS = "DELETE_SOCIAL_MEDIA_SUCCESS";
export const DELETE_SOCIAL_MEDIA_FAILED = "DELETE_SOCIAL_MEDIA_FAILED";
export const UPDATE_SOCIAL_MEDIA_STATUS = "UPDATE_SOCIAL_MEDIA_STATUS";
export const UPDATE_SOCIAL_MEDIA_STATUS_SUCCESS =
  "UPDATE_SOCIAL_MEDIA_STATUS_SUCCESS";
export const UPDATE_SOCIAL_MEDIA_STATUS_FAILED =
  "UPDATE_SOCIAL_MEDIA_STATUS_FAILED";
export const UPDATE_SOCIAL_MEDIA_RESET = "UPDATE_SOCIAL_MEDIA_RESET";
export const CREATE_SOCIAL_MEDIA_RESET = "CREATE_SOCIAL_MEDIA_RESET";
export const DELETE_SOCIAL_MEDIA_RESET = "DELETE_SOCIAL_MEDIA_RESET";
export const UPDATE_SOCIAL_MEDIA_STATUS_RESET =
  "UPDATE_SOCIAL_MEDIA_STATUS_RESET";

import React, { Component, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import RequireAuth from "./containers/RequireAuth";
import { jwtDecode } from "jwt-decode";
import { CSpinner } from "@coreui/react";
const loading = (
  <div className="d-flex justify-content-center align-items-center vh-100">
    <CSpinner color="primary" />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import("./pages/superAdmin/superAdminLayout")
);
const AdminLayout = React.lazy(() => import("./pages/admin/adminLayout"));

const SuperAdminLayout = React.lazy(() =>
  import("./pages/superAdmin/superAdminLayout")
);
const StudentForm = React.lazy(() => import("./pages/employee/addEmployee.js"));

// Pages
const Login = React.lazy(() => import("./pages/login/login"));
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import("./pages/page404/page404"));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ROLES = {
  User: "user",
  SuperAdmin: "ROLE_SUPERADMIN",
  Admin: "ROLE_ADMIN",
};

function App() {
  const token = localStorage.getItem("token");
  const jwtData = token ? jwtDecode(token) : null;
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/studentForm" element={<StudentForm />} />
        <Route
          path="/*"
          element={
            <RequireAuth allowedRoles={[ROLES.SuperAdmin, ROLES.Admin]} />
          }
        >
          {jwtData?.roles &&
            jwtData?.roles.length > 0 &&
            (jwtData.roles[0] === ROLES.Admin ? (
              <Route path="*" element={<AdminLayout />} />
            ) : jwtData.roles[0] === ROLES.SuperAdmin ? (
              <Route path="*" element={<SuperAdminLayout />} />
            ) : (
              <Route path="*" element={<Page404 />} />
            ))}
        </Route>
      </Routes>
    </Suspense>
  );
}
export default App;

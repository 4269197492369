import {
  GET_LEAD,
  GET_LEAD_SUCCESS,
  GET_LEAD_FAILED,
  GET_LEAD_RESET,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD,
  CREATE_LEAD_FAILED,
  UPDATE_LEAD,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAILED,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAILED,
  UPDATE_LEAD_STATUS_SUCCESS,
  UPDATE_LEAD_STATUS,
  UPDATE_LEAD_STATUS_FAILED,
  UPDATE_LEAD_RESET,
  CREATE_LEAD_RESET,
  DELETE_LEAD_RESET,
  UPDATE_LEAD_STATUS_RESET,
  GET_LEAD_REPORT_MONTH,
  GET_LEAD_REPORT_MONTH_SUCCESS,
  GET_LEAD_REPORT_MONTH_FAILED,
  GET_LEAD_REPORT_MONTH_RESET,
  GET_LEAD_REPORT_WEEK,
  GET_LEAD_REPORT_WEEK_SUCCESS,
  GET_LEAD_REPORT_WEEK_FAILED,
  GET_LEAD_REPORT_WEEK_RESET,
  GET_LEAD_REPORT_YEAR,
  GET_LEAD_REPORT_YEAR_SUCCESS,
  GET_LEAD_REPORT_YEAR_FAILED,
  GET_LEAD_REPORT_YEAR_RESET,
} from "./constants";

export function getLead(requestBody) {
  return { type: GET_LEAD, payload: { requestBody } };
}

export function getLeadSuccess(result) {
  return { type: GET_LEAD_SUCCESS, payload: result };
}

export function getLeadFailed(error) {
  return { type: GET_LEAD_FAILED, payload: error };
}

export function getLeadReset() {
  return { type: GET_LEAD_RESET, payload: {} };
}

export function createLead(requestBody) {
  return { type: CREATE_LEAD, payload: { requestBody } };
}

export function createLeadSuccess(result) {
  return { type: CREATE_LEAD_SUCCESS, payload: result };
}

export function createLeadFailed(error) {
  return { type: CREATE_LEAD_FAILED, payload: error };
}

export function createLeadReset() {
  return { type: CREATE_LEAD_RESET, payload: {} };
}

export function updateLead(requestBody) {
  return { type: UPDATE_LEAD, payload: { requestBody } };
}

export function updateLeadSuccess(result) {
  return { type: UPDATE_LEAD_SUCCESS, payload: result };
}

export function updateLeadFailed(error) {
  return { type: UPDATE_LEAD_FAILED, payload: error };
}

export function updateLeadReset() {
  return { type: UPDATE_LEAD_RESET, payload: {} };
}

export function updateLeadStatus(requestBody) {
  return { type: UPDATE_LEAD_STATUS, payload: { requestBody } };
}

export function updateLeadStatusSuccess(result) {
  return { type: UPDATE_LEAD_STATUS_SUCCESS, payload: result };
}

export function updateLeadStatusFailed(error) {
  return { type: UPDATE_LEAD_STATUS_FAILED, payload: error };
}

export function updateLeadStatusReset(error) {
  return { type: UPDATE_LEAD_STATUS_RESET, payload: error };
}

export function deleteLead(requestBody) {
  return { type: DELETE_LEAD, payload: { requestBody } };
}

export function deleteLeadSuccess(result) {
  return { type: DELETE_LEAD_SUCCESS, payload: result };
}

export function deleteLeadFailed(error) {
  return { type: DELETE_LEAD_FAILED, payload: error };
}

export function deleteLeadReset() {
  return { type: DELETE_LEAD_RESET, payload: {} };
}

export function getLeadReportByMonth(requestBody) {
  return { type: GET_LEAD_REPORT_MONTH, payload: { requestBody } };
}

export function getLeadReportByMonthSuccess(result) {
  return { type: GET_LEAD_REPORT_MONTH_SUCCESS, payload: result };
}

export function getLeadReportByMonthFailed(error) {
  return { type: GET_LEAD_REPORT_MONTH_FAILED, payload: error };
}

export function getLeadReportByMonthReset() {
  return { type: GET_LEAD_REPORT_MONTH_RESET, payload: {} };
}

export function getLeadReportByWeek(requestBody) {
  return { type: GET_LEAD_REPORT_WEEK, payload: { requestBody } };
}

export function getLeadReportByWeekSuccess(result) {
  return { type: GET_LEAD_REPORT_WEEK_SUCCESS, payload: result };
}

export function getLeadReportByWeekFailed(error) {
  return { type: GET_LEAD_REPORT_WEEK_FAILED, payload: error };
}

export function getLeadReportByWeekReset() {
  return { type: GET_LEAD_REPORT_WEEK_RESET, payload: {} };
}

export function getLeadReportByYear(requestBody) {
  return { type: GET_LEAD_REPORT_YEAR, payload: { requestBody } };
}

export function getLeadReportByYearSuccess(result) {
  return { type: GET_LEAD_REPORT_YEAR_SUCCESS, payload: result };
}

export function getLeadReportByYearFailed(error) {
  return { type: GET_LEAD_REPORT_YEAR_FAILED, payload: error };
}

export function getLeadReportByYearReset() {
  return { type: GET_LEAD_REPORT_YEAR_RESET, payload: {} };
}

import {
  GET_FOLLOWUP,
  GET_FOLLOWUP_SUCCESS,
  GET_FOLLOWUP_FAILED,
  GET_FOLLOWUP_RESET,
  CREATE_FOLLOWUP,
  CREATE_FOLLOWUP_FAILED,
  CREATE_FOLLOWUP_SUCCESS,
  UPDATE_FOLLOWUP,
  UPDATE_FOLLOWUP_FAILED,
  UPDATE_FOLLOWUP_SUCCESS,
  UPDATE_FOLLOWUP_RESET,
  CREATE_FOLLOWUP_RESET,
  DELETE_FOLLOWUP,
  DELETE_FOLLOWUP_SUCCESS,
  DELETE_FOLLOWUP_FAILED,
  DELETE_FOLLOWUP_RESET,
  UPDATE_FOLLOWUP_STATUS,
  UPDATE_FOLLOWUP_STATUS_SUCCESS,
  UPDATE_FOLLOWUP_STATUS_RESET,
  UPDATE_FOLLOWUP_STATUS_FAILED,
  GET_ALL_FOLLOWUP,
  GET_ALL_FOLLOWUP_SUCCESS,
  GET_ALL_FOLLOWUP_FAILED,
  GET_ALL_FOLLOWUP_RESET,
} from "./constants";

export const followupsReducer = (
  state = {
    followupsData: null,
    followupsLoading: false,
    followupserror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_FOLLOWUP:
      return {
        ...state,
        followupsLoading: true,
        followupsData: null,
        followupsError: null,
      };
    case GET_ALL_FOLLOWUP_SUCCESS:
      return {
        ...state,
        followupsData: action.payload,
        followupsLoading: false,
        followupsError: null,
      };
    case GET_ALL_FOLLOWUP_FAILED:
      return {
        ...state,
        followupsLoading: false,
        followupsData: null,
        followupsError: action.payload,
      };
    case GET_ALL_FOLLOWUP_RESET:
      return {
        ...state,
        followupsLoading: false,
        followupsData: null,
        followupsError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};
export const followupReducer = (
  state = {
    followupData: null,
    followupLoading: false,
    followuperror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_FOLLOWUP:
      return {
        ...state,
        followupLoading: true,
        followupData: null,
        followupError: null,
      };
    case GET_FOLLOWUP_SUCCESS:
      return {
        ...state,
        followupData: action.payload,
        followupLoading: false,
        followupError: null,
      };
    case GET_FOLLOWUP_FAILED:
      return {
        ...state,
        followupLoading: false,
        followupData: null,
        followupError: action.payload,
      };
    case GET_FOLLOWUP_RESET:
      return {
        ...state,
        followupLoading: false,
        followupData: null,
        followupError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createFollowupReducer = (
  state = {
    createFollowupData: null,
    createFollowupLoading: false,
    createFollowupError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_FOLLOWUP:
      return {
        ...state,
        createFollowupLoading: true,
        createFollowupData: null,
        createFollowupError: null,
      };
    case CREATE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        createFollowupData: action.payload,
        createFollowupLoading: false,
        createFollowupError: null,
      };
    case CREATE_FOLLOWUP_FAILED:
      return {
        ...state,
        createFollowupLoading: false,
        createFollowupData: null,
        createFollowupError: action.payload,
      };
    case CREATE_FOLLOWUP_RESET:
      return {
        ...state,
        createFollowupLoading: false,
        createFollowupData: null,
        createFollowupError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateFollowupReducer = (
  state = {
    updateFollowupData: null,
    updateFollowupLoading: false,
    updateFollowupError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FOLLOWUP:
      return {
        ...state,
        updateFollowupLoading: true,
        updateFollowupData: null,
        updateFollowupError: null,
      };
    case UPDATE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        updateFollowupData: action.payload,
        updateFollowupLoading: false,
        updateFollowupError: null,
      };
    case UPDATE_FOLLOWUP_FAILED:
      return {
        ...state,
        updateFollowupLoading: false,
        updateFollowupData: null,
        updateFollowupError: action.payload,
      };
    case UPDATE_FOLLOWUP_RESET:
      return {
        ...state,
        updateFollowupLoading: false,
        updateFollowupData: null,
        updateFollowupError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteFollowupReducer = (
  state = {
    deleteFollowupData: null,
    deleteFollowupLoading: false,
    deleteFollowupError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_FOLLOWUP:
      return {
        ...state,
        deleteFollowupLoading: true,
        deleteFollowupData: null,
        deleteFollowupError: null,
      };
    case DELETE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        deleteFollowupData: action.payload,
        deleteFollowupLoading: false,
        deleteFollowupError: null,
      };
    case DELETE_FOLLOWUP_FAILED:
      return {
        ...state,
        deleteFollowupLoading: false,
        deleteFollowupData: null,
        deleteFollowupError: action.payload,
      };
    case DELETE_FOLLOWUP_RESET:
      return {
        ...state,
        deleteFollowupLoading: false,
        deleteFollowupData: null,
        deleteFollowupError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const followupStatusReducer = (
  state = {
    FollowupStatusData: null,
    FollowupStatusLoading: false,
    Followuperror: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FOLLOWUP_STATUS:
      return {
        ...state,
        FollowupStatusLoading: true,
        FollowupStatusData: null,
        FollowupStatusError: null,
      };
    case UPDATE_FOLLOWUP_STATUS_SUCCESS:
      return {
        ...state,
        FollowupStatusData: action.payload,
        FollowupStatusLoading: false,
        FollowupStatusError: null,
      };
    case UPDATE_FOLLOWUP_STATUS_FAILED:
      return {
        ...state,
        FollowupStatusLoading: false,
        FollowupStatusData: null,
        FollowupStatusError: action.payload,
      };
    case UPDATE_FOLLOWUP_STATUS_RESET:
      return {
        ...state,
        FollowupStatusLoading: false,
        FollowupStatusData: null,
        FollowupStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

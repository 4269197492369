import {
  GET_ALL_SESSION,
  GET_ALL_SESSION_SUCCESS,
  GET_ALL_SESSION_FAILED,
  GET_ALL_SESSION_RESET,
  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILED,
  GET_SESSION_RESET,
  CREATE_SESSION,
  CREATE_SESSION_FAILED,
  CREATE_SESSION_SUCCESS,
  UPDATE_SESSION,
  UPDATE_SESSION_FAILED,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_RESET,
  CREATE_SESSION_RESET,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAILED,
  DELETE_SESSION_RESET,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_STATUS_SUCCESS,
  UPDATE_SESSION_STATUS_RESET,
  UPDATE_SESSION_STATUS_FAILED,
} from "./constants";

export const sessionAllReducer = (
  state = {
    sessionAllData: null,
    sessionAllLoading: false,
    sessionAllError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_SESSION:
      return {
        ...state,
        sessionAllLoading: true,
        sessionAllData: null,
        sessionAllError: null,
      };
    case GET_ALL_SESSION_SUCCESS:
      return {
        ...state,
        sessionAllData: action.payload,
        sessionAllLoading: false,
        sessionAllError: null,
      };
    case GET_ALL_SESSION_FAILED:
      return {
        ...state,
        sessionAllLoading: false,
        sessionAllData: null,
        sessionAllError: action.payload,
      };
    case GET_ALL_SESSION_RESET:
      return {
        ...state,
        sessionAllLoading: false,
        sessionAllData: null,
        sessionAllError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const sessionReducer = (
  state = {
    sessionData: null,
    sessionLoading: false,
    sessionError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_SESSION:
      return {
        ...state,
        sessionLoading: true,
        sessionData: null,
        sessionError: null,
      };
    case GET_SESSION_SUCCESS:
      return {
        ...state,
        sessionData: action.payload,
        sessionLoading: false,
        sessionError: null,
      };
    case GET_SESSION_FAILED:
      return {
        ...state,
        sessionLoading: false,
        sessionData: null,
        sessionError: action.payload,
      };
    case GET_SESSION_RESET:
      return {
        ...state,
        sessionLoading: false,
        sessionData: null,
        sessionError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createSessionReducer = (
  state = {
    createSessionData: null,
    createSessionLoading: false,
    createSessionError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_SESSION:
      return {
        ...state,
        createSessionLoading: true,
        createSessionData: null,
        createSessionError: null,
      };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        createSessionData: action.payload,
        createSessionLoading: false,
        createSessionError: null,
      };
    case CREATE_SESSION_FAILED:
      return {
        ...state,
        createSessionLoading: false,
        createSessionData: null,
        createSessionError: action.payload,
      };
    case CREATE_SESSION_RESET:
      return {
        ...state,
        createSessionLoading: false,
        createSessionData: null,
        createSessionError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateSessionReducer = (
  state = {
    updateSessionData: null,
    updateSessionLoading: false,
    updateSessionError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_SESSION:
      return {
        ...state,
        updateSessionLoading: true,
        updateSessionData: null,
        updateSessionError: null,
      };
    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        updateSessionData: action.payload,
        updateSessionLoading: false,
        updateSessionError: null,
      };
    case UPDATE_SESSION_FAILED:
      return {
        ...state,
        updateSessionLoading: false,
        updateSessionData: null,
        updateSessionError: action.payload,
      };
    case UPDATE_SESSION_RESET:
      return {
        ...state,
        updateSessionLoading: false,
        updateSessionData: null,
        updateSessionError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteSessionReducer = (
  state = {
    deleteSessionData: null,
    deleteSessionLoading: false,
    deleteSessionError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_SESSION:
      return {
        ...state,
        deleteSessionLoading: true,
        deleteSessionData: null,
        deleteSessionError: null,
      };
    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        deleteSessionData: action.payload,
        deleteSessionLoading: false,
        deleteSessionError: null,
      };
    case DELETE_SESSION_FAILED:
      return {
        ...state,
        deleteSessionLoading: false,
        deleteSessionData: null,
        deleteSessionError: action.payload,
      };
    case DELETE_SESSION_RESET:
      return {
        ...state,
        deleteSessionLoading: false,
        deleteSessionData: null,
        deleteSessionError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const sessionStatusReducer = (
  state = {
    sessionStatusData: null,
    sessionStatusLoading: false,
    sessionStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_SESSION_STATUS:
      return {
        ...state,
        sessionStatusLoading: true,
        sessionStatusData: null,
        sessionStatusError: null,
      };
    case UPDATE_SESSION_STATUS_SUCCESS:
      return {
        ...state,
        sessionStatusData: action.payload,
        sessionStatusLoading: false,
        sessionStatusError: null,
      };
    case UPDATE_SESSION_STATUS_FAILED:
      return {
        ...state,
        sessionStatusLoading: false,
        sessionStatusData: null,
        sessionStatusError: action.payload,
      };
    case UPDATE_SESSION_STATUS_RESET:
      return {
        ...state,
        sessionStatusLoading: false,
        sessionStatusData: null,
        sessionStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import {
  GET_LOCATION,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILED,
  GET_LOCATION_RESET,
  CREATE_LOCATION,
  CREATE_LOCATION_FAILED,
  CREATE_LOCATION_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAILED,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_RESET,
  CREATE_LOCATION_RESET,
  UPDATE_LOCATION_STATUS_RESET,
  UPDATE_LOCATION_STATUS_FAILED,
  UPDATE_LOCATION_STATUS_SUCCESS,
  UPDATE_LOCATION_STATUS,
  DELETE_LOCATION_RESET,
  DELETE_LOCATION_FAILED,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION,
} from "./constants";

export const getLocationReducer = (
  state = {
    getLocationdata: null,
    getLocationloading: false,
    getLocationerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_LOCATION:
      return {
        ...state,
        getLocationloading: true,
        getLocationdata: null,
        getLocationerror: null,
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        getLocationdata: action.payload,
        getLocationloading: false,
        getLocationerror: null,
      };
    case GET_LOCATION_FAILED:
      return {
        ...state,
        getLocationloading: false,
        getLocationdata: null,
        getLocationerror: action.payload,
      };
    case GET_LOCATION_RESET:
      return {
        ...state,
        getLocationloading: false,
        getLocationdata: null,
        getLocationerror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createLocationReducer = (
  state = {
    createLocationData: null,
    createLocationLoading: false,
    createLocationError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_LOCATION:
      console.log("CREATE_LOCATION", action.type);
      return {
        ...state,
        createLocationLoading: true,
        createLocationData: null,
        createLocationError: null,
      };
    case CREATE_LOCATION_SUCCESS:
      console.log("CREATE_LOCATION_SUCCESS", action.type);
      return {
        ...state,
        createLocationData: action.payload,
        createLocationLoading: false,
        createLocationError: null,
      };
    case CREATE_LOCATION_FAILED:
      return {
        ...state,
        createLocationLoading: false,
        createLocationData: null,
        createLocationError: action.payload,
      };
    case CREATE_LOCATION_RESET:
      return {
        ...state,
        createLocationLoading: false,
        createLocationData: null,
        createLocationError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateLocationReducer = (
  state = {
    updateLocationData: null,
    updateLocationLoading: false,
    updateLocationError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_LOCATION:
      return {
        ...state,
        updateLocationLoading: true,
        updateLocationData: null,
        updateLocationError: null,
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        updateLocationData: action.payload,
        updateLocationLoading: false,
        updateLocationError: null,
      };
    case UPDATE_LOCATION_FAILED:
      return {
        ...state,
        updateLocationLoading: false,
        updateLocationData: null,
        updateLocationError: action.payload,
      };
    case UPDATE_LOCATION_RESET:
      return {
        ...state,
        updateLocationLoading: false,
        updateLocationData: null,
        updateLocationError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteLocationReducer = (
  state = {
    deleteLocationData: null,
    deleteLocationLoading: false,
    deleteLocationError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_LOCATION:
      return {
        ...state,
        deleteLocationLoading: true,
        deleteLocationData: null,
        deleteLocationError: null,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        deleteLocationData: action.payload,
        deleteLocationLoading: false,
        deleteLocationError: null,
      };
    case DELETE_LOCATION_FAILED:
      return {
        ...state,
        deleteLocationLoading: false,
        deleteLocationData: null,
        deleteLocationError: action.payload,
      };
    case DELETE_LOCATION_RESET:
      return {
        ...state,
        deleteLocationLoading: false,
        deleteLocationData: null,
        deleteLocationError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateLocationStatusReducer = (
  state = {
    updateLocationStatusData: null,
    updateLocationStatusLoading: false,
    updateLocationStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_LOCATION_STATUS:
      return {
        ...state,
        updateLocationStatusLoading: true,
        updateLocationStatusData: null,
        updateLocationStatusError: null,
      };
    case UPDATE_LOCATION_STATUS_SUCCESS:
      return {
        ...state,
        updateLocationStatusData: action.payload,
        updateLocationStatusLoading: false,
        updateLocationStatusError: null,
      };
    case UPDATE_LOCATION_STATUS_FAILED:
      return {
        ...state,
        updateLocationStatusLoading: false,
        updateLocationStatusData: null,
        updateLocationStatusError: action.payload,
      };
    case UPDATE_LOCATION_STATUS_RESET:
      return {
        ...state,
        updateLocationStatusLoading: false,
        updateLocationStatusData: null,
        updateLocationStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import {
  GET_COURSES_GRADE,
  GET_COURSES_GRADE_SUCCESS,
  GET_COURSES_GRADE_FAILED,
  GET_COURSES_GRADE_RESET,
  CREATE_COURSES_GRADE_SUCCESS,
  CREATE_COURSES_GRADE,
  CREATE_COURSES_GRADE_FAILED,
  UPDATE_COURSES_GRADE,
  UPDATE_COURSES_GRADE_SUCCESS,
  UPDATE_COURSES_GRADE_FAILED,
  DELETE_COURSES_GRADE,
  DELETE_COURSES_GRADE_SUCCESS,
  DELETE_COURSES_GRADE_FAILED,
  UPDATE_COURSES_GRADE_STATUS_SUCCESS,
  UPDATE_COURSES_GRADE_STATUS,
  UPDATE_COURSES_GRADE_STATUS_FAILED,
  UPDATE_COURSES_GRADE_RESET,
  CREATE_COURSES_GRADE_RESET,
  GET_COURSES_AND_GRADE,
  GET_COURSES_AND_GRADE_SUCCESS,
  GET_COURSES_AND_GRADE_FAILED,
  GET_COURSES_AND_GRADE_RESET,
  DELETE_COURSES_GRADE_RESET,
  ASSIGN_GRADE_TO_COURSES,
  ASSIGN_GRADE_TO_COURSES_SUCCESS,
  ASSIGN_GRADE_TO_COURSES_FAILED,
  ASSIGN_GRADE_TO_COURSES_RESET,
} from "./constants";

export function getCourseGrade(requestBody) {
  return { type: GET_COURSES_GRADE, payload: { requestBody } };
}

export function getCourseGradeSuccess(result) {
  return { type: GET_COURSES_GRADE_SUCCESS, payload: result };
}

export function getCourseGradeFailed(error) {
  return { type: GET_COURSES_GRADE_FAILED, payload: error };
}

export function getCourseGradeReset() {
  return { type: GET_COURSES_GRADE_RESET, payload: {} };
}

export function createCourseGrade(requestBody) {
  return { type: CREATE_COURSES_GRADE, payload: { requestBody } };
}

export function createCourseGradeSuccess(result) {
  return { type: CREATE_COURSES_GRADE_SUCCESS, payload: result };
}

export function createCourseGradeFailed(error) {
  return { type: CREATE_COURSES_GRADE_FAILED, payload: error };
}

export function createCourseGradeReset() {
  return { type: CREATE_COURSES_GRADE_RESET, payload: {} };
}

export function updateCourseGrade(requestBody) {
  return { type: UPDATE_COURSES_GRADE, payload: { requestBody } };
}

export function updateCourseGradeSuccess(result) {
  console.log("action updateCourseGradeSuccess", result);
  return { type: UPDATE_COURSES_GRADE_SUCCESS, payload: result };
}

export function updateCourseGradeFailed(error) {
  return { type: UPDATE_COURSES_GRADE_FAILED, payload: error };
}

export function updateCourseGradeReset() {
  return { type: UPDATE_COURSES_GRADE_RESET, payload: {} };
}

export function updateCourseGradeStatus(requestBody) {
  return { type: UPDATE_COURSES_GRADE_STATUS, payload: { requestBody } };
}

export function updateCourseGradeStatusSuccess(result) {
  return { type: UPDATE_COURSES_GRADE_STATUS_SUCCESS, payload: result };
}

export function updateCourseGradeStatusFailed(error) {
  return { type: UPDATE_COURSES_GRADE_STATUS_FAILED, payload: error };
}

export function deleteCourseGrade(requestBody) {
  return { type: DELETE_COURSES_GRADE, payload: { requestBody } };
}

export function deleteCourseGradeSuccess(result) {
  return { type: DELETE_COURSES_GRADE_SUCCESS, payload: result };
}

export function deleteCourseGradeFailed(error) {
  return { type: DELETE_COURSES_GRADE_FAILED, payload: error };
}

export function deleteCourseGradeReset(error) {
  return { type: DELETE_COURSES_GRADE_RESET, payload: error };
}

export function getAllCourseAndGrade(requestBody) {
  return { type: GET_COURSES_AND_GRADE, payload: { requestBody } };
}

export function getAllCourseAndGradeSuccess(result) {
  return { type: GET_COURSES_AND_GRADE_SUCCESS, payload: result };
}

export function getAllCourseAndGradeFailed(error) {
  return { type: GET_COURSES_AND_GRADE_FAILED, payload: error };
}

export function getAllCourseAndGradeReset() {
  return { type: GET_COURSES_AND_GRADE_RESET, payload: {} };
}

export function assignGradesToCourse(requestBody) {
  return { type: ASSIGN_GRADE_TO_COURSES, payload: { requestBody } };
}

export function assignGradesToCourseSuccess(result) {
  return { type: ASSIGN_GRADE_TO_COURSES_SUCCESS, payload: result };
}

export function assignGradesToCourseFailed(error) {
  return { type: ASSIGN_GRADE_TO_COURSES_FAILED, payload: error };
}

export function assignGradesToCourseReset() {
  return { type: ASSIGN_GRADE_TO_COURSES_RESET, payload: {} };
}

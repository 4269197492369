import {
  GET_TIME_TABLE,
  GET_TIME_TABLE_SUCCESS,
  GET_TIME_TABLE_FAILED,
  GET_TIME_TABLE_RESET,
  CREATE_TIME_TABLE_SUCCESS,
  CREATE_TIME_TABLE,
  CREATE_TIME_TABLE_FAILED,
  UPDATE_TIME_TABLE,
  UPDATE_TIME_TABLE_SUCCESS,
  UPDATE_TIME_TABLE_FAILED,
  UPDATE_TIME_TABLE_RESET,
  CREATE_TIME_TABLE_RESET,
  CHECK_AVAILABEL,
  CHECK_AVAILABEL_SUCCESS,
  CHECK_AVAILABEL_FAILED,
  CHECK_AVAILABEL_RESET,
  GET_TIME_TABLE_CC,
  GET_TIME_TABLE_CC_SUCCESS,
  GET_TIME_TABLE_CC_FAILED,
  GET_TIME_TABLE_CC_RESET,
} from './constants'

export function getTimetable(requestBody) {
  return { type: GET_TIME_TABLE, payload: { requestBody } }
}

export function getTimetableSuccess(result) {
  return { type: GET_TIME_TABLE_SUCCESS, payload: result }
}

export function getTimetableFailed(error) {
  return { type: GET_TIME_TABLE_FAILED, payload: error }
}

export function getTimetableReset() {
  return { type: GET_TIME_TABLE_RESET, payload: {} }
}

export function createTimetable(requestBody) {
  return { type: CREATE_TIME_TABLE, payload: { requestBody } }
}

export function createTimetableSuccess(result) {
  return { type: CREATE_TIME_TABLE_SUCCESS, payload: result }
}

export function createTimetableFailed(error) {
  return { type: CREATE_TIME_TABLE_FAILED, payload: error }
}

export function createTimetableReset() {
  return { type: CREATE_TIME_TABLE_RESET, payload: {} }
}

export function updateTimetable(requestBody) {
  return { type: UPDATE_TIME_TABLE, payload: { requestBody } }
}

export function updateTimetableSuccess(result) {
  return { type: UPDATE_TIME_TABLE_SUCCESS, payload: result }
}

export function updateTimetableFailed(error) {
  return { type: UPDATE_TIME_TABLE_FAILED, payload: error }
}

export function updateTimetableReset() {
  return { type: UPDATE_TIME_TABLE_RESET, payload: {} }
}

export function checkAvailabelTime(requestBody) {
  return { type: CHECK_AVAILABEL, payload: { requestBody } }
}

export function checkAvailabelTimeSuccess(result) {
  return { type: CHECK_AVAILABEL_SUCCESS, payload: result }
}

export function checkAvailabelTimeFailed(error) {
  return { type: CHECK_AVAILABEL_FAILED, payload: error }
}

export function checkAvailabelTimeReset() {
  return { type: CHECK_AVAILABEL_RESET, payload: {} }
}

export function getTimeTableByClassTypeAndCourse(requestBody) {
  return { type: GET_TIME_TABLE_CC, payload: { requestBody } }
}

export function getTimeTableByClassTypeAndCourseSuccess(result) {
  return { type: GET_TIME_TABLE_CC_SUCCESS, payload: result }
}

export function getTimeTableByClassTypeAndCourseFailed(error) {
  return { type: GET_TIME_TABLE_CC_FAILED, payload: error }
}

export function getTimeTableByClassTypeAndCourseReset() {
  return { type: GET_TIME_TABLE_CC_RESET, payload: {} }
}

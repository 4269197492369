import axios from "axios";
import { checkTokenExpiration } from "../../utils/authUtils";
import { Navigate } from "react-router-dom";

export function getAllSocialMediaApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/socialMedia/getAllSocialMedia`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getSocialMediaApi() {
  if (checkTokenExpiration()) {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/socialMedia/getSocialMedia`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function createSocialMediaApi(requestBody) {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/socialMedia/createSocialMedia`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateSocialMediaApi(requestBody) {
  console.log(requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/socialMedia/updateSocialMedia/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteSocialMediaApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/SocialMedia/deleteSocialMedia/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateSocialMediaStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/SocialMedia/updateSocialMediaStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

import {
  GET_BATCH,
  GET_BATCH_SUCCESS,
  GET_BATCH_FAILED,
  GET_BATCH_RESET,
  CREATE_BATCH_SUCCESS,
  CREATE_BATCH,
  CREATE_BATCH_FAILED,
  UPDATE_BATCH,
  UPDATE_BATCH_SUCCESS,
  UPDATE_BATCH_FAILED,
  DELETE_BATCH,
  DELETE_BATCH_SUCCESS,
  DELETE_BATCH_FAILED,
  UPDATE_BATCH_STATUS_SUCCESS,
  UPDATE_BATCH_STATUS,
  UPDATE_BATCH_STATUS_FAILED,
  UPDATE_BATCH_RESET,
  CREATE_BATCH_RESET,
  DELETE_BATCH_RESET,
  UPDATE_BATCH_STATUS_RESET,
  GET_ALL_BATCH_RESET,
  GET_ALL_BATCH,
  GET_ALL_BATCH_SUCCESS,
  GET_ALL_BATCH_FAILED,
} from "./constants";

export function getAllBatch(requestBody) {
  return { type: GET_ALL_BATCH, payload: { requestBody } };
}

export function getAllBatchSuccess(result) {
  return { type: GET_ALL_BATCH_SUCCESS, payload: result };
}

export function getAllBatchFailed(error) {
  return { type: GET_ALL_BATCH_FAILED, payload: error };
}

export function getAllBatchReset() {
  return { type: GET_ALL_BATCH_RESET, payload: {} };
}

export function getBatch(requestBody) {
  return { type: GET_BATCH, payload: { requestBody } };
}

export function getBatchSuccess(result) {
  return { type: GET_BATCH_SUCCESS, payload: result };
}

export function getBatchFailed(error) {
  return { type: GET_BATCH_FAILED, payload: error };
}

export function getBatchReset() {
  return { type: GET_BATCH_RESET, payload: {} };
}

export function createBatch(requestBody) {
  return { type: CREATE_BATCH, payload: { requestBody } };
}

export function createBatchSuccess(result) {
  return { type: CREATE_BATCH_SUCCESS, payload: result };
}

export function createBatchFailed(error) {
  return { type: CREATE_BATCH_FAILED, payload: error };
}

export function createBatchReset() {
  return { type: CREATE_BATCH_RESET, payload: {} };
}

export function updateBatch(requestBody) {
  return { type: UPDATE_BATCH, payload: { requestBody } };
}

export function updateBatchSuccess(result) {
  return { type: UPDATE_BATCH_SUCCESS, payload: result };
}

export function updateBatchFailed(error) {
  return { type: UPDATE_BATCH_FAILED, payload: error };
}

export function updateBatchReset() {
  return { type: UPDATE_BATCH_RESET, payload: {} };
}

export function updateBatchStatus(requestBody) {
  return { type: UPDATE_BATCH_STATUS, payload: { requestBody } };
}

export function updateBatchStatusSuccess(result) {
  return { type: UPDATE_BATCH_STATUS_SUCCESS, payload: result };
}

export function updateBatchStatusFailed(error) {
  return { type: UPDATE_BATCH_STATUS_FAILED, payload: error };
}

export function updateBatchStatusReset(error) {
  return { type: UPDATE_BATCH_STATUS_RESET, payload: error };
}

export function deleteBatch(requestBody) {
  return { type: DELETE_BATCH, payload: { requestBody } };
}

export function deleteBatchSuccess(result) {
  return { type: DELETE_BATCH_SUCCESS, payload: result };
}

export function deleteBatchFailed(error) {
  return { type: DELETE_BATCH_FAILED, payload: error };
}

export function deleteBatchReset() {
  return { type: DELETE_BATCH_RESET, payload: {} };
}

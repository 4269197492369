import axios from "axios";
import { checkTokenExpiration } from "../../utils/authUtils";
import { Navigate } from "react-router-dom";

export function getAllSessionApi() {
  if (checkTokenExpiration()) {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/Session/getAllSession`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function getSessionApi() {
  if (checkTokenExpiration()) {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/Session/getSession`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function createSessionApi(requestBody) {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/Session/createSession`,
      requestBody,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateSessionApi(requestBody) {
  console.log(requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/Session/updateSession/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteSessionApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/Session/deleteSession/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateSessionStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/Session/updateSessionStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

import { GET_TOKEN, GET_TOKEN_SUCCESS, GET_TOKEN_FAILED, GET_TOKEN_RESET } from './constants'

export function getToken(requestBody) {
  return { type: GET_TOKEN, payload: { requestBody } }
}

export function getTokenSuccess(result) {
  return { type: GET_TOKEN_SUCCESS, payload: result }
}

export function getTokenFailed(error) {
  return { type: GET_TOKEN_FAILED, payload: error }
}

export function getTokenReset() {
  return { type: GET_TOKEN_RESET, payload: {} }
}

import {
  GET_VEHICLE,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILED,
  GET_VEHICLE_RESET,
  CREATE_VEHICLE,
  CREATE_VEHICLE_FAILED,
  CREATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_FAILED,
  UPDATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_RESET,
  UPDATE_VEHICLE_RESET,
  DELETE_VEHICLE_FAILED,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE,
  DELETE_VEHICLE_RESET,
  UPDATE_VEHICLE_STATUS,
  UPDATE_VEHICLE_STATUS_SUCCESS,
  UPDATE_VEHICLE_STATUS_FAILED,
  UPDATE_VEHICLE_STATUS_RESET,
} from "./constants";

export const vehicleReducer = (
  state = {
    vehicledata: null,
    vehicleloading: false,
    vehicleerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_VEHICLE:
      return {
        ...state,
        vehicleloading: true,
        vehicledata: null,
        vehicleerror: null,
      };
    case GET_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicledata: action.payload,
        vehicleloading: false,
        vehicleerror: null,
      };
    case GET_VEHICLE_FAILED:
      return {
        ...state,
        vehicleloading: false,
        vehicledata: null,
        vehicleerror: action.payload,
      };
    case GET_VEHICLE_RESET:
      return {
        ...state,
        vehicleloading: false,
        vehicledata: null,
        vehicleerror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createvehicleReducer = (
  state = {
    createvehicleData: null,
    createvehicleLoading: false,
    createvehicleError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_VEHICLE:
      console.log("CREATE_VEHICLE")
      return {
        ...state,
        createvehicleLoading: true,
        createvehicleData: null,
        createvehicleError: null,
      };
    case CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        createvehicleData: action.payload,
        createvehicleLoading: false,
        createvehicleError: null,
      };
    case CREATE_VEHICLE_FAILED:
      return {
        ...state,
        createvehicleLoading: false,
        createvehicleData: null,
        createvehicleError: action.payload,
      };
    case CREATE_VEHICLE_RESET:
      return {
        ...state,
        createvehicleLoading: false,
        createvehicleData: null,
        createvehicleError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updatevehicleReducer = (
  state = {
    updatevehicleData: null,
    updatevehicleLoading: false,
    updatevehicleError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_VEHICLE:
      return {
        ...state,
        updatevehicleLoading: true,
        updatevehicleData: null,
        updatevehicleError: null,
      };
    case UPDATE_VEHICLE_SUCCESS:
      console.log(action);
      return {
        ...state,
        updatevehicleData: action.payload,
        updatevehicleLoading: false,
        updatevehicleError: null,
      };
    case UPDATE_VEHICLE_FAILED:
      return {
        ...state,
        updatevehicleLoading: false,
        updatevehicleData: null,
        updatevehicleError: action.payload,
      };
    case UPDATE_VEHICLE_RESET:
      return {
        ...state,
        updatevehicleLoading: false,
        updatevehicleData: null,
        updatevehicleError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteVehicleReducer = (
  state = {
    deleteVehicleData: null,
    deleteVehicleLoading: false,
    deleteVehicleError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_VEHICLE:
      return {
        ...state,
        deleteVehicleLoading: true,
        deleteVehicleData: null,
        deleteVehicleError: null,
      };
    case DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        deleteVehicleData: action.payload,
        deleteVehicleLoading: false,
        deleteVehicleError: null,
      };
    case DELETE_VEHICLE_FAILED:
      return {
        ...state,
        deleteVehicleLoading: false,
        deleteVehicleData: null,
        deleteVehicleError: action.payload,
      };
    case DELETE_VEHICLE_RESET:
      return {
        ...state,
        deleteVehicleLoading: false,
        deleteVehicleData: null,
        deleteVehicleError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const vehicleStatusReducer = (
  state = {
    vehicleStatusData: null,
    vehicleStatusLoading: false,
    vehicleStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_VEHICLE_STATUS:
      return {
        ...state,
        vehicleStatusLoading: true,
        vehicleStatusData: null,
        vehicleStatusError: null,
      };
    case UPDATE_VEHICLE_STATUS_SUCCESS:
      return {
        ...state,
        vehicleStatusData: action.payload,
        vehicleStatusLoading: false,
        vehicleStatusError: null,
      };
    case UPDATE_VEHICLE_STATUS_FAILED:
      return {
        ...state,
        vehicleStatusLoading: false,
        vehicleStatusData: null,
        vehicleStatusError: action.payload,
      };
    case UPDATE_VEHICLE_STATUS_RESET:
      return {
        ...state,
        vehicleStatusLoading: false,
        vehicleStatusData: null,
        vehicleStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import axios from "axios";

export function getCourseGradeApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/course/getAllGrades`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function createCourseGradeApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/course/createGrade`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function updateCourseGradeApi(requestBody) {
  console.log("requestBody", requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/course/updateGrade/${requestBody.id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteCourseGradeApi(requestBody) {
  console.log("deleteCourseGradeApi");
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/course/deleteGrade/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateCourseGradeStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/course/createCourseGrade/${requestBody.id}`,
      requestBody.body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllCourseAndGradeApi(requestBody) {
  const course = requestBody ? `id=${requestBody}` : "";
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/course/getAllCourseAndGrade?${course}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function assignCourseGradeApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/course/assignUpdateCourseGrades`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

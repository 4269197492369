export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAILED = "GET_LOCATION_FAILED";
export const GET_LOCATION_RESET = "GET_LOCATION_RESET";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILED = "CREATE_LOCATION_FAILED";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILED = "UPDATE_LOCATION_FAILED";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILED = "DELETE_LOCATION_FAILED";
export const UPDATE_LOCATION_STATUS = "UPDATE_LOCATION_STATUS";
export const UPDATE_LOCATION_STATUS_SUCCESS = "UPDATE_LOCATION_STATUS_SUCCESS";
export const UPDATE_LOCATION_STATUS_FAILED = "UPDATE_LOCATION_STATUS_FAILED";
export const UPDATE_LOCATION_RESET = "UPDATE_LOCATION_RESET";
export const CREATE_LOCATION_RESET = "CREATE_LOCATION_RESET";
export const UPDATE_LOCATION_STATUS_RESET = "UPDATE_LOCATION_STATUS_RESET";
export const DELETE_LOCATION_RESET = "DELETE_LOCATION_RESET";

import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_COURSE,
  DELETE_COURSE,
  GET_ALL_COURSE,
  GET_COURSE,
  GET_COURSE_BY_CALSSID,
  GET_GRADE_BY_COURSEID,
  UPDATE_COURSE,
  UPDATE_COURSE_STATUS,
} from "./constants";
import {
  getCourseApi,
  getAllCourseApi,
  deleteCourseApi,
  createCourseApi,
  updateCourseApi,
  updateCourseStatusApi,
  getCourseByClassIdApi,
  getGradeByCourseIdApi,
} from "./api";
import {
  createCourseFailed,
  createCourseSuccess,
  deleteCourseFailed,
  deleteCourseSuccess,
  getAllCourseFailed,
  getAllCourseSuccess,
  getCourseFailed,
  getCourseSuccess,
  updateCourseSuccess,
  updateCourseFailed,
  updateCourseStatusSuccess,
  updateCourseStatusFailed,
  getCourseByClassIdSuccess,
  getCourseByClassIdFailed,
  getGradeByCourseIdSuccess,
  getGradeByCourseIdFailed,
} from "./action";

function* getCourse(action) {
  try {
    const response = yield call(getCourseApi, action.payload.requestBody);

    if (response && response.status === 200) {
      yield put(getCourseSuccess(response));
    } else {
      yield put(getCourseFailed(response));
    }
  } catch (e) {
    yield put(getCourseFailed(e.response));
  }
}

function* getAllCourse(action) {
  try {
    const response = yield call(getAllCourseApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getAllCourseSuccess(response));
    } else {
      yield put(getAllCourseFailed(response));
    }
  } catch (e) {
    yield put(getAllCourseFailed(e.response));
  }
}

function* createCourse(action) {
  try {
    const response = yield call(createCourseApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createCourseSuccess(response));
    } else {
      yield put(createCourseFailed(response));
    }
  } catch (e) {
    yield put(createCourseFailed(e.response));
  }
}

function* updateCourse(action) {
  try {
    console.log(action.payload);
    const response = yield call(updateCourseApi, action.payload);
    if (response && response.status === 200) {
      yield put(updateCourseSuccess(response));
    } else {
      yield put(updateCourseFailed(response));
    }
  } catch (e) {
    yield put(updateCourseFailed(e.response));
  }
}

function* deleteCourse(action) {
  try {
    const response = yield call(deleteCourseApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteCourseSuccess(response));
    } else {
      yield put(deleteCourseFailed(response));
    }
  } catch (e) {
    yield put(deleteCourseFailed(e.response));
  }
}

function* updateCourseStatus(action) {
  console.log("updateCourseStatus", action);
  try {
    const response = yield call(
      updateCourseStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateCourseStatusSuccess(response));
    } else {
      yield put(updateCourseStatusFailed(response));
    }
  } catch (e) {
    yield put(updateCourseStatusFailed(e.response));
  }
}

function* getGradeByCourseId(action) {
  try {
    const response = yield call(
      getGradeByCourseIdApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(getGradeByCourseIdSuccess(response));
    } else {
      yield put(getGradeByCourseIdFailed(response));
    }
  } catch (e) {
    yield put(getGradeByCourseIdFailed(e.response));
  }
}

function* getCourseByClassId(action) {
  try {
    const response = yield call(
      getCourseByClassIdApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(getCourseByClassIdSuccess(response));
    } else {
      yield put(getCourseByClassIdFailed(response));
    }
  } catch (e) {
    yield put(getCourseByClassIdFailed(e.response));
  }
}

export default function* getCourseSaga() {
  yield takeEvery(GET_COURSE, getCourse);
  yield takeEvery(GET_ALL_COURSE, getAllCourse);
  yield takeEvery(CREATE_COURSE, createCourse);
  yield takeEvery(UPDATE_COURSE, updateCourse);
  yield takeEvery(UPDATE_COURSE_STATUS, updateCourseStatus);
  yield takeEvery(DELETE_COURSE, deleteCourse);
  yield takeEvery(GET_COURSE_BY_CALSSID, getCourseByClassId);
  yield takeEvery(GET_GRADE_BY_COURSEID, getGradeByCourseId);
}

import axios from "axios";

export function getVehicleApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/vehicle/getAllVehicle`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function createVehicleApi(requestBody) {
  console.log(requestBody)
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/vehicle/createVehicles`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export function updateVehicleApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/vehicle/updateVehicle/${requestBody?.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteVehicleApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/vehicle/deleteVehicle/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateVehicleStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/vehicle/updateVehicleStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

import axios from 'axios'

export function getStudentApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/student/getAllStudent`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function createStudentApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/student/createStudent`, requestBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function updateStudentApi(requestBody) {
  console.log(requestBody)
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/student/updateStudent/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}
export function updateStudentStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/student/updateStudentStatus/${requestBody.id}`,
      requestBody.body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

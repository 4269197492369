import {
  GET_FOLLOWUP,
  GET_FOLLOWUP_SUCCESS,
  GET_FOLLOWUP_FAILED,
  GET_FOLLOWUP_RESET,
  CREATE_FOLLOWUP_SUCCESS,
  CREATE_FOLLOWUP,
  CREATE_FOLLOWUP_FAILED,
  UPDATE_FOLLOWUP,
  UPDATE_FOLLOWUP_SUCCESS,
  UPDATE_FOLLOWUP_FAILED,
  DELETE_FOLLOWUP,
  DELETE_FOLLOWUP_SUCCESS,
  DELETE_FOLLOWUP_FAILED,
  UPDATE_FOLLOWUP_STATUS_SUCCESS,
  UPDATE_FOLLOWUP_STATUS,
  UPDATE_FOLLOWUP_STATUS_FAILED,
  UPDATE_FOLLOWUP_RESET,
  CREATE_FOLLOWUP_RESET,
  DELETE_FOLLOWUP_RESET,
  UPDATE_FOLLOWUP_STATUS_RESET,
  GET_ALL_FOLLOWUP,
  GET_ALL_FOLLOWUP_SUCCESS,
  GET_ALL_FOLLOWUP_FAILED,
  GET_ALL_FOLLOWUP_RESET,
} from "./constants";

export function getFollowup(requestBody) {
  return { type: GET_FOLLOWUP, payload: { requestBody } };
}

export function getFollowupSuccess(result) {
  return { type: GET_FOLLOWUP_SUCCESS, payload: result };
}

export function getFollowupFailed(error) {
  return { type: GET_FOLLOWUP_FAILED, payload: error };
}

export function getFollowupReset() {
  return { type: GET_FOLLOWUP_RESET, payload: {} };
}

export function getAllFollowup(requestBody) {
  return { type: GET_ALL_FOLLOWUP, payload: { requestBody } };
}

export function getAllFollowupSuccess(result) {
  return { type: GET_ALL_FOLLOWUP_SUCCESS, payload: result };
}

export function getAllFollowupFailed(error) {
  return { type: GET_ALL_FOLLOWUP_FAILED, payload: error };
}

export function getAllFollowupReset() {
  return { type: GET_ALL_FOLLOWUP_RESET, payload: {} };
}

export function createFollowup(requestBody) {
  return { type: CREATE_FOLLOWUP, payload: { requestBody } };
}

export function createFollowupSuccess(result) {
  return { type: CREATE_FOLLOWUP_SUCCESS, payload: result };
}

export function createFollowupFailed(error) {
  return { type: CREATE_FOLLOWUP_FAILED, payload: error };
}

export function createFollowupReset() {
  return { type: CREATE_FOLLOWUP_RESET, payload: {} };
}

export function updateFollowup(requestBody) {
  return { type: UPDATE_FOLLOWUP, payload: { requestBody } };
}

export function updateFollowupSuccess(result) {
  return { type: UPDATE_FOLLOWUP_SUCCESS, payload: result };
}

export function updateFollowupFailed(error) {
  return { type: UPDATE_FOLLOWUP_FAILED, payload: error };
}

export function updateFollowupReset() {
  return { type: UPDATE_FOLLOWUP_RESET, payload: {} };
}

export function updateFollowupStatus(requestBody) {
  return { type: UPDATE_FOLLOWUP_STATUS, payload: { requestBody } };
}

export function updateFollowupStatusSuccess(result) {
  return { type: UPDATE_FOLLOWUP_STATUS_SUCCESS, payload: result };
}

export function updateFollowupStatusFailed(error) {
  return { type: UPDATE_FOLLOWUP_STATUS_FAILED, payload: error };
}

export function updateFollowupStatusReset(error) {
  return { type: UPDATE_FOLLOWUP_STATUS_RESET, payload: error };
}

export function deleteFollowup(requestBody) {
  return { type: DELETE_FOLLOWUP, payload: { requestBody } };
}

export function deleteFollowupSuccess(result) {
  return { type: DELETE_FOLLOWUP_SUCCESS, payload: result };
}

export function deleteFollowupFailed(error) {
  return { type: DELETE_FOLLOWUP_FAILED, payload: error };
}

export function deleteFollowupReset() {
  return { type: DELETE_FOLLOWUP_RESET, payload: {} };
}

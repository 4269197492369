import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_CLASSROOM,
  DELETE_CLASSROOM,
  GET_CLASSROOM,
  UPDATE_CLASSROOM,
  UPDATE_CLASSROOM_STATUS,
} from "./constants";
import {
  createClassRoomApi,
  deleteClassRoomApi,
  getClassRoomApi,
  updateClassRoomApi,
  updateClassRoomStatusApi,
} from "./api";
import {
  createClassRoomFailed,
  createClassRoomSuccess,
  deleteClassRoomFailed,
  deleteClassRoomSuccess,
  getClassRoomFailed,
  getClassRoomSuccess,
  updateClassRoomFailed,
  updateClassRoomStatusFailed,
  updateClassRoomStatusSuccess,
  updateClassRoomSuccess,
} from "./action";

function* getClassRoom(action) {
  try {
    const response = yield call(getClassRoomApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getClassRoomSuccess(response));
    } else {
      yield put(getClassRoomFailed(response));
    }
  } catch (e) {
    yield put(getClassRoomFailed(e.response));
  }
}

function* createClassRoom(action) {
  try {
    const response = yield call(createClassRoomApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createClassRoomSuccess(response));
    } else {
      yield put(createClassRoomFailed(response));
    }
  } catch (e) {
    yield put(createClassRoomFailed(e.response));
  }
}

function* updateClassRoom(action) {
  try {
    const response = yield call(updateClassRoomApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateClassRoomSuccess(response));
    } else {
      yield put(updateClassRoomFailed(response));
    }
  } catch (e) {
    yield put(updateClassRoomFailed(e.response));
  }
}

function* updateClassRoomStatus(action) {
  try {
    const response = yield call(
      updateClassRoomStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateClassRoomStatusSuccess(response));
    } else {
      yield put(updateClassRoomStatusFailed(response));
    }
  } catch (e) {
    yield put(updateClassRoomStatusFailed(e.response));
  }
}

function* deleteClassRoom(action) {
  try {
    const response = yield call(deleteClassRoomApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteClassRoomSuccess(response));
    } else {
      yield put(deleteClassRoomFailed(response));
    }
  } catch (e) {
    yield put(updateClassRoomFailed(e.response));
  }
}

export default function* getClassRoomSaga() {
  yield takeEvery(GET_CLASSROOM, getClassRoom);
  yield takeEvery(CREATE_CLASSROOM, createClassRoom);
  yield takeEvery(UPDATE_CLASSROOM, updateClassRoom);
  yield takeEvery(DELETE_CLASSROOM, deleteClassRoom);
  yield takeEvery(UPDATE_CLASSROOM_STATUS, updateClassRoomStatus);
}

import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_FOLLOWUP,
  DELETE_FOLLOWUP,
  GET_ALL_FOLLOWUP,
  GET_FOLLOWUP,
  UPDATE_FOLLOWUP,
  UPDATE_FOLLOWUP_STATUS,
} from "./constants";
import {
  createFollowupApi,
  deleteFollowupApi,
  getAllFollowupApi,
  getFollowupApi,
  updateFollowupApi,
  updateFollowupStatusApi,
} from "./api";
import {
  createFollowupFailed,
  createFollowupSuccess,
  deleteFollowupFailed,
  deleteFollowupSuccess,
  getAllFollowupFailed,
  getAllFollowupSuccess,
  getFollowupFailed,
  getFollowupSuccess,
  updateFollowupFailed,
  updateFollowupStatusFailed,
  updateFollowupStatusSuccess,
  updateFollowupSuccess,
} from "./action";

function* getAllFollowup(action) {
  try {
    const response = yield call(getAllFollowupApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getAllFollowupSuccess(response));
    } else {
      yield put(getAllFollowupFailed(response));
    }
  } catch (e) {
    yield put(getAllFollowupFailed(e.response));
  }
}

function* getFollowup(action) {
  try {
    const response = yield call(getFollowupApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getFollowupSuccess(response));
    } else {
      yield put(getFollowupFailed(response));
    }
  } catch (e) {
    yield put(getFollowupFailed(e.response));
  }
}

function* createFollowup(action) {
  try {
    const response = yield call(createFollowupApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createFollowupSuccess(response));
    } else {
      yield put(createFollowupFailed(response));
    }
  } catch (e) {
    yield put(createFollowupFailed(e.response));
  }
}

function* updateFollowup(action) {
  try {
    const response = yield call(updateFollowupApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateFollowupSuccess(response));
    } else {
      yield put(updateFollowupFailed(response));
    }
  } catch (e) {
    yield put(updateFollowupFailed(e.response));
  }
}

function* updateFollowupStatus(action) {
  try {
    const response = yield call(
      updateFollowupStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateFollowupStatusSuccess(response));
    } else {
      yield put(updateFollowupStatusFailed(response));
    }
  } catch (e) {
    yield put(updateFollowupStatusFailed(e.response));
  }
}

function* deleteFollowup(action) {
  try {
    const response = yield call(deleteFollowupApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteFollowupSuccess(response));
    } else {
      yield put(deleteFollowupFailed(response));
    }
  } catch (e) {
    yield put(updateFollowupFailed(e.response));
  }
}

export default function* getFollowupSaga() {
  yield takeEvery(GET_ALL_FOLLOWUP, getAllFollowup);
  yield takeEvery(GET_FOLLOWUP, getFollowup);
  yield takeEvery(CREATE_FOLLOWUP, createFollowup);
  yield takeEvery(UPDATE_FOLLOWUP, updateFollowup);
  yield takeEvery(DELETE_FOLLOWUP, deleteFollowup);
  yield takeEvery(UPDATE_FOLLOWUP_STATUS, updateFollowupStatus);
}

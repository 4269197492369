export const GET_TIME_TABLE = 'GET_TIME_TABLE'
export const GET_TIME_TABLE_SUCCESS = 'GET_TIME_TABLE_SUCCESS'
export const GET_TIME_TABLE_FAILED = 'GET_TIME_TABLE_FAILED'
export const GET_TIME_TABLE_RESET = 'GET_TIME_TABLE_RESET'
export const CREATE_TIME_TABLE = 'CREATE_TIME_TABLE'
export const CREATE_TIME_TABLE_SUCCESS = 'CREATE_TIME_TABLE_SUCCESS'
export const CREATE_TIME_TABLE_FAILED = 'CREATE_TIME_TABLE_FAILED'
export const CREATE_TIME_TABLE_RESET = 'CREATE_TIME_TABLE_RESET'
export const UPDATE_TIME_TABLE = 'UPDATE_TIME_TABLE'
export const UPDATE_TIME_TABLE_SUCCESS = 'UPDATE_TIME_TABLE_SUCCESS'
export const UPDATE_TIME_TABLE_FAILED = 'UPDATE_TIME_TABLE_FAILED'
export const UPDATE_TIME_TABLE_RESET = 'UPDATE_TIME_TABLE_RESET'
export const CHECK_AVAILABEL = 'CHECK_AVAILABEL'
export const CHECK_AVAILABEL_SUCCESS = 'CHECK_AVAILABEL_SUCCESS'
export const CHECK_AVAILABEL_FAILED = 'CHECK_AVAILABEL_FAILED'
export const CHECK_AVAILABEL_RESET = 'CHECK_AVAILABEL_RESET'
export const GET_TIME_TABLE_CC = 'GET_TIME_TABLE_CC'
export const GET_TIME_TABLE_CC_SUCCESS = 'GET_TIME_TABLE_CC_SUCCESS'
export const GET_TIME_TABLE_CC_FAILED = 'GET_TIME_TABLE_CC_FAILED'
export const GET_TIME_TABLE_CC_RESET = 'GET_TIME_TABLE_CC_RESET'

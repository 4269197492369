import { all } from "redux-saga/effects";
import getTokenSaga from "./login/sagas";
import getBranchSaga from "./branch/sagas";
import getEmployeeSaga from "./employee/sagas";
import getVehicleSaga from "./vehicle/sagas";
import getCourseSaga from "./courses/sagas";
import getStudentSaga from "./student/sagas";
import getSocialMediaSaga from "./socialMedia/sagas";
import getAllParentSaga from "./parent/sagas";
import getLocationSaga from "./location/sagas";
import getBatchSaga from "./batch/sagas";
import getTimetableSaga from "./timetable/sagas";
import getCourseGradeSaga from "./coursesGurad/sagas";
import getClassSaga from "./class/sagas";
import getSessionSaga from "./session/sagas";
import getLeadSaga from "./lead/sagas";
import getFollowupSaga from "./followup/sagas";
import getCampSaga from "./camp/sagas";
import getClassRoomSaga from "./classRoom/sagas";
export default function* rootSaga() {
  yield all([
    getTokenSaga(),
    getBranchSaga(),
    getEmployeeSaga(),
    getStudentSaga(),
    getCourseSaga(),
    getSocialMediaSaga(),
    getAllParentSaga(),
    getLocationSaga(),
    getBatchSaga(),
    getTimetableSaga(),
    getCourseGradeSaga(),
    getClassSaga(),
    getSessionSaga(),
    getVehicleSaga(),
    getLeadSaga(),
    getFollowupSaga(),
    getCampSaga(),
    getClassRoomSaga(),
  ]);
}

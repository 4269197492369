import { takeLatest, put, call } from 'redux-saga/effects'
import { GET_TOKEN } from './constants'
import { getTokenApi } from './api'
import { getTokenFailed, getTokenSuccess } from './action'

function* getToken(action) {
  try {
    const response = yield call(getTokenApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getTokenSuccess(response))
    } else {
      console.error('error', response)
      yield put(getTokenFailed(response))
    }
  } catch (e) {
    yield put(getTokenFailed(e.response))
  }
}

export default function* getTokenSaga() {
  yield takeLatest(GET_TOKEN, getToken)
}

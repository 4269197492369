import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_BATCH,
  DELETE_BATCH,
  GET_ALL_BATCH,
  GET_BATCH,
  UPDATE_BATCH,
  UPDATE_BATCH_STATUS,
} from "./constants";
import {
  createBatchApi,
  deleteBatchApi,
  getAllBatchApi,
  getBatchApi,
  updateBatchApi,
  updateBatchStatusApi,
} from "./api";
import {
  createBatchFailed,
  createBatchSuccess,
  deleteBatchFailed,
  deleteBatchSuccess,
  getAllBatchFailed,
  getAllBatchSuccess,
  getBatchFailed,
  getBatchSuccess,
  updateBatchFailed,
  updateBatchStatusFailed,
  updateBatchStatusSuccess,
  updateBatchSuccess,
} from "./action";

function* getAllBatch(action) {
  try {
    const response = yield call(getAllBatchApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getAllBatchSuccess(response));
    } else {
      yield put(getAllBatchFailed(response));
    }
  } catch (e) {
    yield put(getAllBatchFailed(e.response));
  }
}

function* getBatch(action) {
  try {
    const response = yield call(getBatchApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getBatchSuccess(response));
    } else {
      yield put(getBatchFailed(response));
    }
  } catch (e) {
    yield put(getBatchFailed(e.response));
  }
}

function* createBatch(action) {
  try {
    const response = yield call(createBatchApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createBatchSuccess(response));
    } else {
      yield put(createBatchFailed(response));
    }
  } catch (e) {
    yield put(createBatchFailed(e.response));
  }
}

function* updateBatch(action) {
  try {
    const response = yield call(updateBatchApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateBatchSuccess(response));
    } else {
      yield put(updateBatchFailed(response));
    }
  } catch (e) {
    yield put(updateBatchFailed(e.response));
  }
}

function* updateBatchStatus(action) {
  try {
    const response = yield call(
      updateBatchStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateBatchStatusSuccess(response));
    } else {
      yield put(updateBatchStatusFailed(response));
    }
  } catch (e) {
    yield put(updateBatchStatusFailed(e.response));
  }
}

function* deleteBatch(action) {
  try {
    const response = yield call(deleteBatchApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteBatchSuccess(response));
    } else {
      yield put(deleteBatchFailed(response));
    }
  } catch (e) {
    yield put(updateBatchFailed(e.response));
  }
}

export default function* getBatchSaga() {
  yield takeEvery(GET_ALL_BATCH, getAllBatch);
  yield takeEvery(GET_BATCH, getBatch);
  yield takeEvery(CREATE_BATCH, createBatch);
  yield takeEvery(UPDATE_BATCH, updateBatch);
  yield takeEvery(DELETE_BATCH, deleteBatch);
  yield takeEvery(UPDATE_BATCH_STATUS, updateBatchStatus);
}

export const GET_COURSES_GRADE = "GET_COURSES_GRADE";
export const GET_COURSES_GRADE_SUCCESS = "GET_COURSES_GRADE_SUCCESS";
export const GET_COURSES_GRADE_FAILED = "GET_COURSES_GRADE_FAILED";
export const GET_COURSES_GRADE_RESET = "GET_COURSES_GRADE_RESET";
export const CREATE_COURSES_GRADE = "CREATE_COURSES_GRADE";
export const CREATE_COURSES_GRADE_SUCCESS = "CREATE_COURSES_GRADE_SUCCESS";
export const CREATE_COURSES_GRADE_FAILED = "CREATE_COURSES_GRADE_FAILED";
export const UPDATE_COURSES_GRADE = "UPDATE_COURSES_GRADE";
export const UPDATE_COURSES_GRADE_SUCCESS = "UPDATE_COURSES_GRADE_SUCCESS";
export const UPDATE_COURSES_GRADE_FAILED = "UPDATE_COURSES_GRADE_FAILED";
export const DELETE_COURSES_GRADE = "DELETE_COURSES_GRADE";
export const DELETE_COURSES_GRADE_SUCCESS = "DELETE_COURSES_GRADE_SUCCESS";
export const DELETE_COURSES_GRADE_FAILED = "DELETE_COURSES_GRADE_FAILED";
export const DELETE_COURSES_GRADE_RESET = "DELETE_COURSES_GRADE_RESET";
export const UPDATE_COURSES_GRADE_STATUS = "UPDATE_COURSES_GRADE_STATUS";
export const UPDATE_COURSES_GRADE_STATUS_SUCCESS =
  "UPDATE_COURSES_GRADE_STATUS_SUCCESS";
export const UPDATE_COURSES_GRADE_STATUS_FAILED =
  "UPDATE_COURSES_GRADE_STATUS_FAILED";
export const UPDATE_COURSES_GRADE_RESET = "UPDATE_COURSES_GRADE_RESET";
export const CREATE_COURSES_GRADE_RESET = "CREATE_COURSES_GRADE_RESET";
export const GET_COURSES_AND_GRADE = "GET_COURSES_AND_GRADE";
export const GET_COURSES_AND_GRADE_SUCCESS = "GET_COURSES_AND_GRADE_SUCCESS";
export const GET_COURSES_AND_GRADE_FAILED = "GET_COURSES_AND_GRADE_FAILED";
export const GET_COURSES_AND_GRADE_RESET = "GET_COURSES_AND_GRADE_RESET";

export const ASSIGN_GRADE_TO_COURSES = "ASSIGN_GRADE_TO_COURSES";
export const ASSIGN_GRADE_TO_COURSES_SUCCESS =
  "ASSIGN_GRADE_TO_COURSES_SUCCESS";
export const ASSIGN_GRADE_TO_COURSES_FAILED = "ASSIGN_GRADE_TO_COURSES_FAILED";
export const ASSIGN_GRADE_TO_COURSES_RESET = "ASSIGN_GRADE_TO_COURSES_RESET";

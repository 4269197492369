import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_SOCIAL_MEDIA,
  DELETE_SOCIAL_MEDIA,
  GET_ALL_SOCIAL_MEDIA,
  GET_SOCIAL_MEDIA,
  UPDATE_SOCIAL_MEDIA,
  UPDATE_SOCIAL_MEDIA_STATUS,
} from "./constants";
import {
  createSocialMediaApi,
  deleteSocialMediaApi,
  getAllSocialMediaApi,
  getSocialMediaApi,
  updateSocialMediaApi,
  updateSocialMediaStatusApi,
} from "./api";
import {
  createSocialMediaFailed,
  createSocialMediaSuccess,
  deleteSocialMediaFailed,
  deleteSocialMediaSuccess,
  getAllSocialMediaFailed,
  getAllSocialMediaSuccess,
  getSocialMediaFailed,
  getSocialMediaSuccess,
  updateSocialMediaFailed,
  updateSocialMediaStatusFailed,
  updateSocialMediaStatusSuccess,
  updateSocialMediaSuccess,
} from "./action";

function* getAllSocialMedia(action) {
  try {
    const response = yield call(
      getAllSocialMediaApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(getAllSocialMediaSuccess(response));
    } else {
      yield put(getAllSocialMediaFailed(response));
    }
  } catch (e) {
    yield put(getAllSocialMediaFailed(e.response));
  }
}

function* getSocialMedia(action) {
  try {
    const response = yield call(getSocialMediaApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getSocialMediaSuccess(response));
    } else {
      yield put(getSocialMediaFailed(response));
    }
  } catch (e) {
    yield put(getSocialMediaFailed(e.response));
  }
}

function* createSocialMedia(action) {
  try {
    const response = yield call(
      createSocialMediaApi,
      action.payload.requestBody
    );
    if (response && response.status === 201) {
      yield put(createSocialMediaSuccess(response));
    } else {
      yield put(createSocialMediaFailed(response));
    }
  } catch (e) {
    yield put(createSocialMediaFailed(e.response));
  }
}

function* updateSocialMedia(action) {
  try {
    const response = yield call(
      updateSocialMediaApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateSocialMediaSuccess(response));
    } else {
      yield put(updateSocialMediaFailed(response));
    }
  } catch (e) {
    yield put(updateSocialMediaFailed(e.response));
  }
}

function* updateSocialMediaStatus(action) {
  try {
    const response = yield call(
      updateSocialMediaStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateSocialMediaStatusSuccess(response));
    } else {
      yield put(updateSocialMediaStatusFailed(response));
    }
  } catch (e) {
    yield put(updateSocialMediaStatusFailed(e.response));
  }
}

function* deleteSocialMedia(action) {
  try {
    const response = yield call(
      deleteSocialMediaApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(deleteSocialMediaSuccess(response));
    } else {
      yield put(deleteSocialMediaFailed(response));
    }
  } catch (e) {
    yield put(deleteSocialMediaFailed(e.response));
  }
}

export default function* getSocialMediaSaga() {
  yield takeEvery(GET_ALL_SOCIAL_MEDIA, getAllSocialMedia);
  yield takeEvery(GET_SOCIAL_MEDIA, getSocialMedia);
  yield takeEvery(CREATE_SOCIAL_MEDIA, createSocialMedia);
  yield takeEvery(UPDATE_SOCIAL_MEDIA, updateSocialMedia);
  yield takeEvery(DELETE_SOCIAL_MEDIA, deleteSocialMedia);
  yield takeEvery(UPDATE_SOCIAL_MEDIA_STATUS, updateSocialMediaStatus);
}

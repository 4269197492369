import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  CREATE_BRANCH,
  DELETE_BRANCH,
  GET_BRANCH,
  UPDATE_BRANCH,
  UPDATE_BRANCH_STATUS,
} from './constants'
import {
  createBranchApi,
  deleteBranchApi,
  getBranchApi,
  updateBranchApi,
  updateBranchStatusApi,
} from './api'
import {
  createBranchFailed,
  createBranchSuccess,
  deleteBranchFailed,
  deleteBranchSuccess,
  getBranchFailed,
  getBranchSuccess,
  updateBranchFailed,
  updateBranchStatusFailed,
  updateBranchStatusSuccess,
  updateBranchSuccess,
} from './action'

function* getBranch(action) {
  try {
    const response = yield call(getBranchApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getBranchSuccess(response))
    } else {
      yield put(getBranchFailed(response))
    }
  } catch (e) {
    yield put(getBranchFailed(e.response))
  }
}

function* createBranch(action) {
  try {
    const response = yield call(createBranchApi, action.payload.requestBody)
    if (response && response.status === 201) {
      yield put(createBranchSuccess(response))
    } else {
      yield put(createBranchFailed(response))
    }
  } catch (e) {
    yield put(createBranchFailed(e.response))
  }
}

function* updateBranch(action) {
  try {
    const response = yield call(updateBranchApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(updateBranchSuccess(response))
    } else {
      yield put(updateBranchFailed(response))
    }
  } catch (e) {
    yield put(updateBranchFailed(e.response))
  }
}

function* updateBranchStatus(action) {
  try {
    const response = yield call(updateBranchStatusApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(updateBranchStatusSuccess(response))
    } else {
      yield put(updateBranchStatusFailed(response))
    }
  } catch (e) {
    yield put(updateBranchStatusFailed(e.response))
  }
}

function* deleteBranch(action) {
  try {
    const response = yield call(deleteBranchApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(deleteBranchSuccess(response))
    } else {
      yield put(deleteBranchFailed(response))
    }
  } catch (e) {
    yield put(updateBranchFailed(e.response))
  }
}

export default function* getBranchSaga() {
  yield takeEvery(GET_BRANCH, getBranch)
  yield takeEvery(CREATE_BRANCH, createBranch)
  yield takeEvery(UPDATE_BRANCH, updateBranch)
  yield takeEvery(DELETE_BRANCH, deleteBranch)
  yield takeEvery(UPDATE_BRANCH_STATUS, updateBranchStatus)
}

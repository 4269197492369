import axios from "axios";
import { checkTokenExpiration } from "../../utils/authUtils";
import { Navigate } from "react-router-dom";

export function getCampApi() {
  if (checkTokenExpiration()) {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/camp/getAllCamp`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function getCampAndCourseApi() {
  console.log("getCampAndCourseApi");
  if (checkTokenExpiration()) {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/camp/getAllCampAndCourse`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function getCampByIdApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/camp/getCampById/${requestBody}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function createCampApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/camp/createCamp`, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateCampApi(requestBody) {
  console.log(requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/camp/updateCamp/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteCampApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/camp/deleteCamp/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateCampStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/camp/updateCampStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getCampTypeApi() {
  if (checkTokenExpiration()) {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/camp/getAllCampType`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function createCampTypeApi(requestBody) {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/camp/createCampType`,
      requestBody,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateCampTypeApi(requestBody) {
  console.log("requestBody", requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/camp/updateCampType/${requestBody.id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteCampTypeApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/camp/deleteCampType/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateCampTypeStatusApi(requestBody) {
  console.log("requestBody", requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/camp/updateCampTypeStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

import axios from "axios";

export function getLocationApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/location/getAllLocation`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function createLocationApi(requestBody) {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/location/createLocation`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateLocationApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/location/updateLocation/${requestBody.id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteLocationApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/location/deleteLocation/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateLocationStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/location/updateLocationStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

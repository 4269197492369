import {
  GET_CLASSROOM,
  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAILED,
  GET_CLASSROOM_RESET,
  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM,
  CREATE_CLASSROOM_FAILED,
  UPDATE_CLASSROOM,
  UPDATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSROOM_FAILED,
  DELETE_CLASSROOM,
  DELETE_CLASSROOM_SUCCESS,
  DELETE_CLASSROOM_FAILED,
  UPDATE_CLASSROOM_STATUS_SUCCESS,
  UPDATE_CLASSROOM_STATUS,
  UPDATE_CLASSROOM_STATUS_FAILED,
  UPDATE_CLASSROOM_RESET,
  CREATE_CLASSROOM_RESET,
  DELETE_CLASSROOM_RESET,
  UPDATE_CLASSROOM_STATUS_RESET,
} from "./constants";

export function getClassRoom(requestBody) {
  return { type: GET_CLASSROOM, payload: { requestBody } };
}

export function getClassRoomSuccess(result) {
  return { type: GET_CLASSROOM_SUCCESS, payload: result };
}

export function getClassRoomFailed(error) {
  return { type: GET_CLASSROOM_FAILED, payload: error };
}

export function getClassRoomReset() {
  return { type: GET_CLASSROOM_RESET, payload: {} };
}

export function createClassRoom(requestBody) {
  return { type: CREATE_CLASSROOM, payload: { requestBody } };
}

export function createClassRoomSuccess(result) {
  return { type: CREATE_CLASSROOM_SUCCESS, payload: result };
}

export function createClassRoomFailed(error) {
  return { type: CREATE_CLASSROOM_FAILED, payload: error };
}

export function createClassRoomReset() {
  return { type: CREATE_CLASSROOM_RESET, payload: {} };
}

export function updateClassRoom(requestBody) {
  return { type: UPDATE_CLASSROOM, payload: { requestBody } };
}

export function updateClassRoomSuccess(result) {
  return { type: UPDATE_CLASSROOM_SUCCESS, payload: result };
}

export function updateClassRoomFailed(error) {
  return { type: UPDATE_CLASSROOM_FAILED, payload: error };
}

export function updateClassRoomReset() {
  return { type: UPDATE_CLASSROOM_RESET, payload: {} };
}

export function updateClassRoomStatus(requestBody) {
  return { type: UPDATE_CLASSROOM_STATUS, payload: { requestBody } };
}

export function updateClassRoomStatusSuccess(result) {
  return { type: UPDATE_CLASSROOM_STATUS_SUCCESS, payload: result };
}

export function updateClassRoomStatusFailed(error) {
  return { type: UPDATE_CLASSROOM_STATUS_FAILED, payload: error };
}

export function updateClassRoomStatusReset(error) {
  return { type: UPDATE_CLASSROOM_STATUS_RESET, payload: error };
}

export function deleteClassRoom(requestBody) {
  return { type: DELETE_CLASSROOM, payload: { requestBody } };
}

export function deleteClassRoomSuccess(result) {
  return { type: DELETE_CLASSROOM_SUCCESS, payload: result };
}

export function deleteClassRoomFailed(error) {
  return { type: DELETE_CLASSROOM_FAILED, payload: error };
}

export function deleteClassRoomReset() {
  return { type: DELETE_CLASSROOM_RESET, payload: {} };
}

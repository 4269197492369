import axios from "axios";
import { checkTokenExpiration } from "../../utils/authUtils";
import { Navigate } from "react-router-dom";

export function getLeadApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/lead/getAllLead?${
          requestBody.branch_id
            ? "branch_id=" + requestBody.branch_id + "&"
            : ""
        }${
          requestBody.filter_type
            ? "filter_type=" + requestBody.filter_type + "&"
            : ""
        }status=${requestBody.status}&date_range=${
          requestBody.date_range
        }&page=${requestBody.page}&limit=${requestBody.limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function getLeadReportMonthApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/lead/getAllLeadByMonth`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}
export function getLeadReportWeekApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/lead/getAllLeadByWeek`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}
export function getLeadReportYearApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/lead/getAllLeadByYear`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function createLeadApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/lead/createLead`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateLeadApi(requestBody) {
  console.log(requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/lead/updateLead/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteLeadApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/lead/deleteLead/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateLeadStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/lead/updateLeadtatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

import {
  GET_ALL_BATCH,
  GET_ALL_BATCH_SUCCESS,
  GET_ALL_BATCH_FAILED,
  GET_ALL_BATCH_RESET,
  GET_BATCH,
  GET_BATCH_SUCCESS,
  GET_BATCH_FAILED,
  GET_BATCH_RESET,
  CREATE_BATCH,
  CREATE_BATCH_FAILED,
  CREATE_BATCH_SUCCESS,
  UPDATE_BATCH,
  UPDATE_BATCH_FAILED,
  UPDATE_BATCH_SUCCESS,
  UPDATE_BATCH_RESET,
  CREATE_BATCH_RESET,
  DELETE_BATCH,
  DELETE_BATCH_SUCCESS,
  DELETE_BATCH_FAILED,
  DELETE_BATCH_RESET,
  UPDATE_BATCH_STATUS,
  UPDATE_BATCH_STATUS_SUCCESS,
  UPDATE_BATCH_STATUS_RESET,
  UPDATE_BATCH_STATUS_FAILED,
} from "./constants";

export const batchAllReducer = (
  state = {
    batchAllData: null,
    batchAllLoading: false,
    batchAllError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_BATCH:
      return {
        ...state,
        batchAllLoading: true,
        batchAllData: null,
        batchAllError: null,
      };
    case GET_ALL_BATCH_SUCCESS:
      return {
        ...state,
        batchAllData: action.payload,
        batchAllLoading: false,
        batchAllError: null,
      };
    case GET_ALL_BATCH_FAILED:
      return {
        ...state,
        batchAllLoading: false,
        batchAllData: null,
        batchAllError: action.payload,
      };
    case GET_ALL_BATCH_RESET:
      return {
        ...state,
        batchAllLoading: false,
        batchAllData: null,
        batchAllError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const batchReducer = (
  state = {
    batchData: null,
    batchLoading: false,
    batchError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_BATCH:
      return {
        ...state,
        batchLoading: true,
        batchData: null,
        batchError: null,
      };
    case GET_BATCH_SUCCESS:
      return {
        ...state,
        batchData: action.payload,
        batchLoading: false,
        batchError: null,
      };
    case GET_BATCH_FAILED:
      return {
        ...state,
        batchLoading: false,
        batchData: null,
        batchError: action.payload,
      };
    case GET_BATCH_RESET:
      return {
        ...state,
        batchLoading: false,
        batchData: null,
        batchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createBatchReducer = (
  state = {
    createBatchData: null,
    createBatchLoading: false,
    createBatchError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_BATCH:
      return {
        ...state,
        createBatchLoading: true,
        createBatchData: null,
        createBatchError: null,
      };
    case CREATE_BATCH_SUCCESS:
      return {
        ...state,
        createBatchData: action.payload,
        createBatchLoading: false,
        createBatchError: null,
      };
    case CREATE_BATCH_FAILED:
      return {
        ...state,
        createBatchLoading: false,
        createBatchData: null,
        createBatchError: action.payload,
      };
    case CREATE_BATCH_RESET:
      return {
        ...state,
        createBatchLoading: false,
        createBatchData: null,
        createBatchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateBatchReducer = (
  state = {
    updateBatchData: null,
    updateBatchLoading: false,
    updateBatchError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_BATCH:
      return {
        ...state,
        updateBatchLoading: true,
        updateBatchData: null,
        updateBatchError: null,
      };
    case UPDATE_BATCH_SUCCESS:
      return {
        ...state,
        updateBatchData: action.payload,
        updateBatchLoading: false,
        updateBatchError: null,
      };
    case UPDATE_BATCH_FAILED:
      return {
        ...state,
        updateBatchLoading: false,
        updateBatchData: null,
        updateBatchError: action.payload,
      };
    case UPDATE_BATCH_RESET:
      return {
        ...state,
        updateBatchLoading: false,
        updateBatchData: null,
        updateBatchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteBatchReducer = (
  state = {
    deleteBatchData: null,
    deleteBatchLoading: false,
    deleteBatchError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_BATCH:
      return {
        ...state,
        deleteBatchLoading: true,
        deleteBatchData: null,
        deleteBatchError: null,
      };
    case DELETE_BATCH_SUCCESS:
      return {
        ...state,
        deleteBatchData: action.payload,
        deleteBatchLoading: false,
        deleteBatchError: null,
      };
    case DELETE_BATCH_FAILED:
      return {
        ...state,
        deleteBatchLoading: false,
        deleteBatchData: null,
        deleteBatchError: action.payload,
      };
    case DELETE_BATCH_RESET:
      return {
        ...state,
        deleteBatchLoading: false,
        deleteBatchData: null,
        deleteBatchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const batchStatusReducer = (
  state = {
    batchStatusData: null,
    batchStatusLoading: false,
    batchStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_BATCH_STATUS:
      return {
        ...state,
        batchStatusLoading: true,
        batchStatusData: null,
        batchStatusError: null,
      };
    case UPDATE_BATCH_STATUS_SUCCESS:
      return {
        ...state,
        batchStatusData: action.payload,
        batchStatusLoading: false,
        batchStatusError: null,
      };
    case UPDATE_BATCH_STATUS_FAILED:
      return {
        ...state,
        batchStatusLoading: false,
        batchStatusData: null,
        batchStatusError: action.payload,
      };
    case UPDATE_BATCH_STATUS_RESET:
      return {
        ...state,
        batchStatusLoading: false,
        batchStatusData: null,
        batchStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

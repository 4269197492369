import {
  GET_LOCATION,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILED,
  GET_LOCATION_RESET,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION,
  CREATE_LOCATION_FAILED,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILED,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILED,
  UPDATE_LOCATION_STATUS_SUCCESS,
  UPDATE_LOCATION_STATUS,
  UPDATE_LOCATION_STATUS_FAILED,
  UPDATE_LOCATION_RESET,
  CREATE_LOCATION_RESET,
  UPDATE_LOCATION_STATUS_RESET,
  DELETE_LOCATION_RESET,
} from "./constants";

export function getLocation() {
  return { type: GET_LOCATION, payload: {} };
}

export function getLocationSuccess(result) {
  return { type: GET_LOCATION_SUCCESS, payload: result };
}

export function getLocationFailed(error) {
  return { type: GET_LOCATION_FAILED, payload: error };
}

export function getLocationReset() {
  return { type: GET_LOCATION_RESET, payload: {} };
}

export function createLocation(requestBody) {
  console.log("createLocation", requestBody);
  return { type: CREATE_LOCATION, payload: { requestBody } };
}

export function createLocationSuccess(result) {
  console.log("createLocationSuccess", result);
  return { type: CREATE_LOCATION_SUCCESS, payload: result };
}

export function createLocationFailed(error) {
  return { type: CREATE_LOCATION_FAILED, payload: error };
}

export function createLocationReset() {
  return { type: CREATE_LOCATION_RESET, payload: {} };
}

export function updateLocation(requestBody) {
  return { type: UPDATE_LOCATION, payload: { requestBody } };
}

export function updateLocationSuccess(result) {
  return { type: UPDATE_LOCATION_SUCCESS, payload: result };
}

export function updateLocationFailed(error) {
  return { type: UPDATE_LOCATION_FAILED, payload: error };
}

export function updateLocationReset() {
  return { type: UPDATE_LOCATION_RESET, payload: {} };
}

export function updateLocationStatus(requestBody) {
  return { type: UPDATE_LOCATION_STATUS, payload: { requestBody } };
}

export function updateLocationStatusSuccess(result) {
  return { type: UPDATE_LOCATION_STATUS_SUCCESS, payload: result };
}

export function updateLocationStatusFailed(error) {
  return { type: UPDATE_LOCATION_STATUS_FAILED, payload: error };
}

export function updateLocationStatusReset(error) {
  return { type: UPDATE_LOCATION_STATUS_RESET, payload: error };
}

export function deleteLocation(requestBody) {
  return { type: DELETE_LOCATION, payload: { requestBody } };
}

export function deleteLocationSuccess(result) {
  return { type: DELETE_LOCATION_SUCCESS, payload: result };
}

export function deleteLocationFailed(error) {
  return { type: DELETE_LOCATION_FAILED, payload: error };
}

export function deleteLocationReset(error) {
  return { type: DELETE_LOCATION_RESET, payload: error };
}

import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILED,
  GET_EMPLOYEE_RESET,
  CREATE_EMPLOYEE_FAILED,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILED,
  UPDATE_EMPLOYEE_STATUS_FAILED,
  UPDATE_EMPLOYEE_STATUS_SUCCESS,
  UPDATE_EMPLOYEE_STATUS,
  UPDATE_EMPLOYEE_RESET,
  CREATE_EMPLOYEE_RESET,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILED,
  DELETE_EMPLOYEE_RESET,
  GET_ALL_ROLE,
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_FAILED,
  GET_ALL_ROLE_RESET,
} from './constants'

export function getEmployee(requestBody) {
  return { type: GET_EMPLOYEE, payload: { requestBody } }
}

export function getEmployeeSuccess(result) {
  return { type: GET_EMPLOYEE_SUCCESS, payload: result }
}

export function getEmployeeFailed(error) {
  return { type: GET_EMPLOYEE_FAILED, payload: error }
}

export function getEmployeeReset() {
  return { type: GET_EMPLOYEE_RESET, payload: {} }
}

export function createEmployee(requestBody) {
  return { type: CREATE_EMPLOYEE, payload: { requestBody } }
}

export function createEmployeeSuccess(result) {
  return { type: CREATE_EMPLOYEE_SUCCESS, payload: result }
}

export function createEmployeeFailed(error) {
  return { type: CREATE_EMPLOYEE_FAILED, payload: error }
}

export function createEmployeeReset() {
  return { type: CREATE_EMPLOYEE_RESET, payload: {} }
}

export function updateEmployee(requestBody) {
  return { type: UPDATE_EMPLOYEE, payload: { requestBody } }
}

export function updateEmployeeSuccess(result) {
  return { type: UPDATE_EMPLOYEE_SUCCESS, payload: result }
}

export function updateEmployeeFailed(error) {
  return { type: UPDATE_EMPLOYEE_FAILED, payload: error }
}

export function updateEmployeeReset() {
  return { type: UPDATE_EMPLOYEE_RESET, payload: {} }
}

export function updateEmployeeStatus(requestBody) {
  return { type: UPDATE_EMPLOYEE_STATUS, payload: { requestBody } }
}

export function updateEmployeeStatusSuccess(result) {
  return { type: UPDATE_EMPLOYEE_STATUS_SUCCESS, payload: result }
}

export function updateEmployeeStatusFailed(error) {
  return { type: UPDATE_EMPLOYEE_STATUS_FAILED, payload: error }
}

export function deleteEmployee(requestBody) {
  return { type: DELETE_EMPLOYEE, payload: { requestBody } }
}

export function deleteEmployeeSuccess(result) {
  return { type: DELETE_EMPLOYEE_SUCCESS, payload: result }
}

export function deleteEmployeeFailed(error) {
  return { type: DELETE_EMPLOYEE_FAILED, payload: error }
}

export function deleteEmployeeReset() {
  return { type: DELETE_EMPLOYEE_RESET, payload: {} }
}

export function getAllRole() {
  return { type: GET_ALL_ROLE, payload: {} }
}

export function getAllRoleSuccess(result) {
  return { type: GET_ALL_ROLE_SUCCESS, payload: result }
}

export function getAllRoleFailed(error) {
  return { type: GET_ALL_ROLE_FAILED, payload: error }
}

export function getAllRoleReset() {
  return { type: GET_ALL_ROLE_RESET, payload: {} }
}

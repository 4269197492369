import { put, call, takeEvery } from "redux-saga/effects";
import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  GET_LOCATION,
  UPDATE_LOCATION,
  UPDATE_LOCATION_STATUS,
} from "./constants";
import {
  createLocationApi,
  deleteLocationApi,
  getLocationApi,
  updateLocationApi,
  updateLocationStatusApi,
} from "./api";
import {
  createLocationFailed,
  createLocationSuccess,
  deleteLocationFailed,
  deleteLocationSuccess,
  getLocationFailed,
  getLocationSuccess,
  updateLocationFailed,
  updateLocationStatusFailed,
  updateLocationStatusSuccess,
  updateLocationSuccess,
} from "./action";

function* getLocation(action) {
  try {
    const response = yield call(getLocationApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getLocationSuccess(response));
    } else {
      yield put(getLocationFailed(response));
    }
  } catch (e) {
    yield put(getLocationFailed(e.response));
  }
}

function* createLocation(action) {
  try {
    const response = yield call(createLocationApi, action.payload.requestBody);
    console.log("response", response);
    if (response && response.status === 201) {
      yield put(createLocationSuccess(response));
    } else {
      yield put(createLocationFailed(response));
    }
  } catch (e) {
    yield put(createLocationFailed(e.response));
  }
}

function* updateLocation(action) {
  try {
    const response = yield call(updateLocationApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateLocationSuccess(response));
    } else {
      yield put(updateLocationFailed(response));
    }
  } catch (e) {
    yield put(updateLocationFailed(e.response));
  }
}

function* updateLocationStatus(action) {
  try {
    const response = yield call(
      updateLocationStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateLocationStatusSuccess(response));
    } else {
      yield put(updateLocationStatusFailed(response));
    }
  } catch (e) {
    yield put(updateLocationStatusFailed(e.response));
  }
}

function* deleteLocation(action) {
  try {
    const response = yield call(deleteLocationApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteLocationSuccess(response));
    } else {
      yield put(deleteLocationFailed(response));
    }
  } catch (e) {
    yield put(updateLocationFailed(e.response));
  }
}

export default function* getLocationSaga() {
  yield takeEvery(GET_LOCATION, getLocation);
  yield takeEvery(CREATE_LOCATION, createLocation);
  yield takeEvery(UPDATE_LOCATION, updateLocation);
  yield takeEvery(DELETE_LOCATION, deleteLocation);
  yield takeEvery(UPDATE_LOCATION_STATUS, updateLocationStatus);
}

import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_CLASS } from "./constants";
import { getClassApi } from "./api";
import { getClassFailed, getClassSuccess } from "./action";

function* getClass(action) {
  try {
    const response = yield call(getClassApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getClassSuccess(response));
    } else {
      yield put(getClassFailed(response));
    }
  } catch (e) {
    yield put(getClassFailed(e.response));
  }
}

export default function* getClassSaga() {
  yield takeEvery(GET_CLASS, getClass);
}

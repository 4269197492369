import {
  GET_LEAD,
  GET_LEAD_SUCCESS,
  GET_LEAD_FAILED,
  GET_LEAD_RESET,
  CREATE_LEAD,
  CREATE_LEAD_FAILED,
  CREATE_LEAD_SUCCESS,
  UPDATE_LEAD,
  UPDATE_LEAD_FAILED,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_RESET,
  CREATE_LEAD_RESET,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAILED,
  DELETE_LEAD_RESET,
  UPDATE_LEAD_STATUS,
  UPDATE_LEAD_STATUS_SUCCESS,
  UPDATE_LEAD_STATUS_RESET,
  UPDATE_LEAD_STATUS_FAILED,
  GET_LEAD_REPORT_MONTH,
  GET_LEAD_REPORT_MONTH_SUCCESS,
  GET_LEAD_REPORT_MONTH_RESET,
  GET_LEAD_REPORT_YEAR,
  GET_LEAD_REPORT_YEAR_SUCCESS,
  GET_LEAD_REPORT_YEAR_FAILED,
  GET_LEAD_REPORT_YEAR_RESET,
  GET_LEAD_REPORT_WEEK,
  GET_LEAD_REPORT_WEEK_SUCCESS,
  GET_LEAD_REPORT_WEEK_FAILED,
  GET_LEAD_REPORT_WEEK_RESET,
} from "./constants";

export const leadReducer = (
  state = {
    leadData: null,
    leadLoading: false,
    leaderror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_LEAD:
      return {
        ...state,
        leadLoading: true,
        leadData: null,
        leadError: null,
      };
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        leadData: action.payload,
        leadLoading: false,
        leadError: null,
      };
    case GET_LEAD_FAILED:
      return {
        ...state,
        leadLoading: false,
        leadData: null,
        leadError: action.payload,
      };
    case GET_LEAD_RESET:
      return {
        ...state,
        leadLoading: false,
        leadData: null,
        leadError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createLeadReducer = (
  state = {
    createLeadData: null,
    createLeadLoading: false,
    createLeadError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_LEAD:
      return {
        ...state,
        createLeadLoading: true,
        createLeadData: null,
        createLeadError: null,
      };
    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        createLeadData: action.payload,
        createLeadLoading: false,
        createLeadError: null,
      };
    case CREATE_LEAD_FAILED:
      return {
        ...state,
        createLeadLoading: false,
        createLeadData: null,
        createLeadError: action.payload,
      };
    case CREATE_LEAD_RESET:
      return {
        ...state,
        createLeadLoading: false,
        createLeadData: null,
        createLeadError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateLeadReducer = (
  state = {
    updateLeadData: null,
    updateLeadLoading: false,
    updateLeadError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_LEAD:
      return {
        ...state,
        updateLeadLoading: true,
        updateLeadData: null,
        updateLeadError: null,
      };
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        updateLeadData: action.payload,
        updateLeadLoading: false,
        updateLeadError: null,
      };
    case UPDATE_LEAD_FAILED:
      return {
        ...state,
        updateLeadLoading: false,
        updateLeadData: null,
        updateLeadError: action.payload,
      };
    case UPDATE_LEAD_RESET:
      return {
        ...state,
        updateLeadLoading: false,
        updateLeadData: null,
        updateLeadError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteLeadReducer = (
  state = {
    deleteLeadData: null,
    deleteLeadLoading: false,
    deleteleadError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_LEAD:
      return {
        ...state,
        deleteLeadLoading: true,
        deleteLeadData: null,
        deleteleadError: null,
      };
    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        deleteLeadData: action.payload,
        deleteLeadLoading: false,
        deleteleadError: null,
      };
    case DELETE_LEAD_FAILED:
      return {
        ...state,
        deleteLeadLoading: false,
        deleteLeadData: null,
        deleteleadError: action.payload,
      };
    case DELETE_LEAD_RESET:
      return {
        ...state,
        deleteLeadLoading: false,
        deleteLeadData: null,
        deleteleadError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const leadStatusReducer = (
  state = {
    leadStatusData: null,
    leadStatusLoading: false,
    leaderror: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_LEAD_STATUS:
      return {
        ...state,
        leadStatusLoading: true,
        leadStatusData: null,
        leadStatusError: null,
      };
    case UPDATE_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        leadStatusData: action.payload,
        leadStatusLoading: false,
        leadStatusError: null,
      };
    case UPDATE_LEAD_STATUS_FAILED:
      return {
        ...state,
        leadStatusLoading: false,
        leadStatusData: null,
        leadStatusError: action.payload,
      };
    case UPDATE_LEAD_STATUS_RESET:
      return {
        ...state,
        leadStatusLoading: false,
        leadStatusData: null,
        leadStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const leadReportMonthReducer = (
  state = {
    leadReportMonthData: null,
    leadReportMonthLoading: false,
    leadReportMonthError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_LEAD_REPORT_MONTH:
      return {
        ...state,
        leadReportMonthLoading: true,
        leadReportMonthData: null,
        leadReportMonthError: null,
      };
    case GET_LEAD_REPORT_MONTH_SUCCESS:
      return {
        ...state,
        leadReportMonthData: action.payload,
        leadReportMonthLoading: false,
        leadReportMonthError: null,
      };
    case GET_LEAD_FAILED:
      return {
        ...state,
        leadReportMonthLoading: false,
        leadReportMonthData: null,
        leadReportMonthError: action.payload,
      };
    case GET_LEAD_REPORT_MONTH_RESET:
      return {
        ...state,
        leadReportMonthLoading: false,
        leadReportMonthData: null,
        leadReportMonthError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const leadReportWeekReducer = (
  state = {
    leadReportWeekData: null,
    leadReportWeekLoading: false,
    leadReportWeekError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_LEAD_REPORT_WEEK:
      return {
        ...state,
        leadReportWeekLoading: true,
        leadReportWeekData: null,
        leadReportWeekError: null,
      };
    case GET_LEAD_REPORT_WEEK_SUCCESS:
      return {
        ...state,
        leadReportWeekData: action.payload,
        leadReportWeekLoading: false,
        leadReportWeekError: null,
      };
    case GET_LEAD_REPORT_WEEK_FAILED:
      return {
        ...state,
        leadReportWeekLoading: false,
        leadReportWeekData: null,
        leadReportWeekError: action.payload,
      };
    case GET_LEAD_REPORT_WEEK_RESET:
      return {
        ...state,
        leadReportWeekLoading: false,
        leadReportWeekData: null,
        leadReportWeekError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const leadReportYearReducer = (
  state = {
    leadReportYearData: null,
    leadReportYearLoading: false,
    leadReportYearError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_LEAD_REPORT_YEAR:
      return {
        ...state,
        leadReportYearLoading: true,
        leadReportYearData: null,
        leadReportYearError: null,
      };
    case GET_LEAD_REPORT_YEAR_SUCCESS:
      return {
        ...state,
        leadReportYearData: action.payload,
        leadReportYearLoading: false,
        leadReportYearError: null,
      };
    case GET_LEAD_REPORT_YEAR_FAILED:
      return {
        ...state,
        leadReportYearLoading: false,
        leadReportYearData: null,
        leadReportYearError: action.payload,
      };
    case GET_LEAD_REPORT_YEAR_RESET:
      return {
        ...state,
        leadReportYearLoading: false,
        leadReportYearData: null,
        leadReportYearError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import axios from 'axios'

export function getTimetableApi(request) {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/timeTable/getAllTimeTable?m=${request}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function createTimetableApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/timeTable/createTimetable`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}
export function updateTimetableApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/timeTable/updateTimetable/${requestBody?.id}`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function checkAvailableTimeApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/timeTable/checkAvailableTime`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function getTimeTableByClassTypeAndCourseApi(requestBody) {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/timeTable/getTimeTableByClassTypeAndCourse`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

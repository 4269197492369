import axios from "axios";
import { checkTokenExpiration } from "../../utils/authUtils";
import { Navigate } from "react-router-dom";

export function getFollowupApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/Followup/getFollowup/${requestBody}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function getAllFollowupApi(requestBody) {
  if (checkTokenExpiration()) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/lead/getAllFollowup/${requestBody}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function createFollowupApi(requestBody) {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/lead/createFollowup`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export function updateFollowupApi(requestBody) {
  console.log(requestBody.data);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/lead/updateFollowup/${requestBody.id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteFollowupApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/lead/deleteFollowup/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateFollowupStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/lead/updateFollowupStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

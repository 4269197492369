import axios from 'axios'

export function getEmployeeApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/employee/getAllEmployee`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function createEmployeeApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/employee/createEmployee`, requestBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function updateEmployeeApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/employee/updateEmployee/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function updateEmployeeStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/employee/updateEmployeeStatus/${requestBody.id}`,
      requestBody.body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      },
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function deleteEmployeeApi(requestBody) {
  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}/employee/deleteEmployee/${requestBody}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

export function getAllRoleApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/role/getAllRole`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

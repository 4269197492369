export const GET_STUDENT = 'GET_STUDENT'
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS'
export const GET_STUDENT_FAILED = 'GET_STUDENT_FAILED'
export const GET_STUDENT_RESET = 'GET_STUDENT_RESET'
export const CREATE_STUDENT = 'CREATE_STUDENT'
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS'
export const CREATE_STUDENT_FAILED = 'CREATE_STUDENT_FAILED'
export const UPDATE_STUDENT = 'UPDATE_STUDENT'
export const UPDATE_STUDENT_FAILED = 'UPDATE_STUDENT_FAILED'
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS'
export const UPDATE_STUDENT_STATUS = 'UPDATE_STUDENT_STATUS'
export const UPDATE_STUDENT_STATUS_SUCCESS = 'UPDATE_STUDENT_STATUS_SUCCESS'
export const UPDATE_STUDENT_STATUS_FAILED = 'UPDATE_STUDENT_STATUS_FAILED'
export const UPDATE_STUDENT_RESET = 'UPDATE_STUDENT_RESET'
export const CREATE_STUDENT_RESET = 'CREATE_STUDENT_RESET'

export const GET_LEAD = "GET_LEAD";
export const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS";
export const GET_LEAD_FAILED = "GET_LEAD_FAILED";
export const GET_LEAD_RESET = "GET_LEAD_RESET";
export const CREATE_LEAD = "CREATE_LEAD";
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
export const CREATE_LEAD_FAILED = "CREATE_LEAD_FAILED";
export const CREATE_LEAD_RESET = "CREATE_LEAD_RESET";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAILED = "UPDATE_LEAD_FAILED";
export const UPDATE_LEAD_RESET = "UPDATE_LEAD_RESET";
export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAILED = "DELETE_LEAD_FAILED";
export const DELETE_LEAD_RESET = "DELETE_LEAD_RESET";
export const UPDATE_LEAD_STATUS = "UPDATE_LEAD_STATUS";
export const UPDATE_LEAD_STATUS_SUCCESS = "UPDATE_LEAD_STATUS_SUCCESS";
export const UPDATE_LEAD_STATUS_FAILED = "UPDATE_LEAD_STATUS_FAILED";
export const UPDATE_LEAD_STATUS_RESET = "UPDATE_LEAD_STATUS_RESET";
export const GET_LEAD_REPORT_MONTH = "GET_LEAD_REPORT_MONTH";
export const GET_LEAD_REPORT_MONTH_SUCCESS = "GET_LEAD_REPORT_MONTH_SUCCESS";
export const GET_LEAD_REPORT_MONTH_FAILED = "GET_LEAD_REPORT_MONTH_FAILED";
export const GET_LEAD_REPORT_MONTH_RESET = "GET_LEAD_REPORT_MONTH_RESET";
export const GET_LEAD_REPORT_WEEK = "GET_LEAD_REPORT_WEEK";
export const GET_LEAD_REPORT_WEEK_SUCCESS = "GET_LEAD_REPORT_WEEK_SUCCESS";
export const GET_LEAD_REPORT_WEEK_FAILED = "GET_LEAD_REPORT_WEEK_FAILED";
export const GET_LEAD_REPORT_WEEK_RESET = "GET_LEAD_REPORT_WEEK_RESET";
export const GET_LEAD_REPORT_YEAR = "GET_LEAD_REPORT_YEAR";
export const GET_LEAD_REPORT_YEAR_SUCCESS = "GET_LEAD_REPORT_YEAR_SUCCESS";
export const GET_LEAD_REPORT_YEAR_FAILED = "GET_LEAD_REPORT_YEAR_FAILED";
export const GET_LEAD_REPORT_YEAR_RESET = "GET_LEAD_REPORT_YEAR_RESET";

import {
  GET_CLASS,
  GET_CLASS_SUCCESS,
  GET_CLASS_FAILED,
  GET_CLASS_RESET,
} from "./constants";

export const classReducer = (
  state = {
    classdata: null,
    classloading: false,
    classerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CLASS:
      return {
        ...state,
        classloading: true,
        classdata: null,
        classerror: null,
      };
    case GET_CLASS_SUCCESS:
      return {
        ...state,
        classdata: action.payload,
        classloading: false,
        classerror: null,
      };
    case GET_CLASS_FAILED:
      return {
        ...state,
        classloading: false,
        classdata: null,
        classerror: action.payload,
      };
    case GET_CLASS_RESET:
      return {
        ...state,
        classloading: false,
        classdata: null,
        classerror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import {
  GET_STUDENT,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAILED,
  GET_STUDENT_RESET,
  CREATE_STUDENT_FAILED,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT,
  UPDATE_STUDENT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAILED,
  UPDATE_STUDENT_STATUS,
  UPDATE_STUDENT_STATUS_SUCCESS,
  UPDATE_STUDENT_STATUS_FAILED,
  UPDATE_STUDENT_RESET,
  CREATE_STUDENT_RESET,
} from './constants'

export function getStudent() {
  return { type: GET_STUDENT, payload: {} }
}

export function getStudentSuccess(result) {
  return { type: GET_STUDENT_SUCCESS, payload: result }
}

export function getStudentFailed(error) {
  return { type: GET_STUDENT_FAILED, payload: error }
}

export function getStudentReset() {
  return { type: GET_STUDENT_RESET, payload: {} }
}

export function createStudent(requestBody) {
  return { type: CREATE_STUDENT, payload: { requestBody } }
}

export function createStudentSuccess(result) {
  return { type: CREATE_STUDENT_SUCCESS, payload: result }
}

export function createStudentFailed(error) {
  return { type: CREATE_STUDENT_FAILED, payload: error }
}

export function createStudentReset() {
  return { type: CREATE_STUDENT_RESET, payload: {} }
}

export function updateStudent(requestBody) {
  return { type: UPDATE_STUDENT, payload: { requestBody } }
}

export function updateStudentSuccess(result) {
  return { type: UPDATE_STUDENT_SUCCESS, payload: result }
}

export function updateStudentFailed(error) {
  return { type: UPDATE_STUDENT_FAILED, payload: error }
}

export function updateStudentReset() {
  return { type: UPDATE_STUDENT_RESET, payload: {} }
}

export function updateStudentStatus(requestBody) {
  return { type: UPDATE_STUDENT_STATUS, payload: { requestBody } }
}

export function updateStudentStatusSuccess(result) {
  return { type: UPDATE_STUDENT_STATUS_SUCCESS, payload: result }
}

export function updateStudentStatusFailed(error) {
  return { type: UPDATE_STUDENT_STATUS_FAILED, payload: error }
}

import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_CAMP,
  CREATE_CAMP_TYPE,
  DELETE_CAMP,
  DELETE_CAMP_TYPE,
  GET_CAMP,
  GET_CAMP_AND_COURSE,
  GET_CAMP_BY_ID,
  GET_CAMP_TYPE,
  UPDATE_CAMP,
  UPDATE_CAMP_STATUS,
  UPDATE_CAMP_TYPE,
  UPDATE_CAMP_TYPE_STATUS,
} from "./constants";
import {
  createCampApi,
  deleteCampApi,
  getCampApi,
  updateCampApi,
  updateCampStatusApi,
  createCampTypeApi,
  deleteCampTypeApi,
  getCampTypeApi,
  updateCampTypeApi,
  updateCampTypeStatusApi,
  getCampByIdApi,
  getCampAndCourseApi,
} from "./api";
import {
  createCampFailed,
  createCampSuccess,
  deleteCampFailed,
  deleteCampSuccess,
  getCampFailed,
  getCampSuccess,
  updateCampFailed,
  updateCampStatusFailed,
  updateCampStatusSuccess,
  updateCampSuccess,
  createCampTypeFailed,
  createCampTypeSuccess,
  deleteCampTypeFailed,
  deleteCampTypeSuccess,
  getCampTypeFailed,
  getCampTypeSuccess,
  updateCampTypeFailed,
  updateCampTypeStatusFailed,
  updateCampTypeStatusSuccess,
  updateCampTypeSuccess,
  getCampByIdSuccess,
  getCampByIdFailed,
  getCampAndCourseSuccess,
  getCampAndCourseFailed,
} from "./action";

function* getCamp(action) {
  try {
    const response = yield call(getCampApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getCampSuccess(response));
    } else {
      yield put(getCampFailed(response));
    }
  } catch (e) {
    yield put(getCampFailed(e.response));
  }
}

function* getCampAndCourse(action) {
  console.log("getCampAndCourse saga");
  try {
    const response = yield call(
      getCampAndCourseApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(getCampAndCourseSuccess(response));
    } else {
      yield put(getCampAndCourseFailed(response));
    }
  } catch (e) {
    yield put(getCampAndCourseFailed(e.response));
  }
}

function* getCampById(action) {
  try {
    const response = yield call(getCampByIdApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getCampByIdSuccess(response));
    } else {
      yield put(getCampByIdFailed(response));
    }
  } catch (e) {
    yield put(getCampByIdFailed(e.response));
  }
}

function* createCamp(action) {
  try {
    const response = yield call(createCampApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createCampSuccess(response));
    } else {
      yield put(createCampFailed(response));
    }
  } catch (e) {
    yield put(createCampFailed(e.response));
  }
}

function* updateCamp(action) {
  try {
    const response = yield call(updateCampApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateCampSuccess(response));
    } else {
      yield put(updateCampFailed(response));
    }
  } catch (e) {
    yield put(updateCampFailed(e.response));
  }
}

function* updateCampStatus(action) {
  try {
    const response = yield call(
      updateCampStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateCampStatusSuccess(response));
    } else {
      yield put(updateCampStatusFailed(response));
    }
  } catch (e) {
    yield put(updateCampStatusFailed(e.response));
  }
}

function* deleteCamp(action) {
  try {
    const response = yield call(deleteCampApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteCampSuccess(response));
    } else {
      yield put(deleteCampFailed(response));
    }
  } catch (e) {
    yield put(updateCampFailed(e.response));
  }
}

function* getCampType(action) {
  try {
    const response = yield call(getCampTypeApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getCampTypeSuccess(response));
    } else {
      yield put(getCampTypeFailed(response));
    }
  } catch (e) {
    yield put(getCampTypeFailed(e.response));
  }
}

function* createCampType(action) {
  try {
    const response = yield call(createCampTypeApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createCampTypeSuccess(response));
    } else {
      yield put(createCampTypeFailed(response));
    }
  } catch (e) {
    yield put(createCampTypeFailed(e.response));
  }
}

function* updateCampType(action) {
  try {
    const response = yield call(updateCampTypeApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateCampTypeSuccess(response));
    } else {
      yield put(updateCampTypeFailed(response));
    }
  } catch (e) {
    yield put(updateCampTypeFailed(e.response));
  }
}

function* updateCampTypeStatus(action) {
  try {
    const response = yield call(
      updateCampTypeStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateCampTypeStatusSuccess(response));
    } else {
      yield put(updateCampTypeStatusFailed(response));
    }
  } catch (e) {
    yield put(updateCampTypeStatusFailed(e.response));
  }
}

function* deleteCampType(action) {
  try {
    const response = yield call(deleteCampTypeApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteCampTypeSuccess(response));
    } else {
      yield put(deleteCampTypeFailed(response));
    }
  } catch (e) {
    yield put(updateCampTypeFailed(e.response));
  }
}

export default function* getCampSaga() {
  yield takeEvery(GET_CAMP, getCamp);
  yield takeEvery(CREATE_CAMP, createCamp);
  yield takeEvery(UPDATE_CAMP, updateCamp);
  yield takeEvery(DELETE_CAMP, deleteCamp);
  yield takeEvery(UPDATE_CAMP_STATUS, updateCampStatus);
  yield takeEvery(GET_CAMP_TYPE, getCampType);
  yield takeEvery(CREATE_CAMP_TYPE, createCampType);
  yield takeEvery(UPDATE_CAMP_TYPE, updateCampType);
  yield takeEvery(DELETE_CAMP_TYPE, deleteCampType);
  yield takeEvery(UPDATE_CAMP_TYPE_STATUS, updateCampTypeStatus);
  yield takeEvery(GET_CAMP_BY_ID, getCampById);
  yield takeEvery(GET_CAMP_AND_COURSE, getCampAndCourse);
}

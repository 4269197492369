export const GET_VEHICLE = "GET_VEHICLE";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_FAILED = "GET_VEHICLE_FAILED";
export const GET_VEHICLE_RESET = "GET_VEHICLE_RESET";
export const CREATE_VEHICLE = "CREATE_VEHICLE";
export const CREATE_VEHICLE_SUCCESS = "CREATE_VEHICLE_SUCCESS";
export const CREATE_VEHICLE_FAILED = "CREATE_VEHICLE_FAILED";
export const CREATE_VEHICLE_RESET = "CREATE_VEHICLE_RESET";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAILED = "UPDATE_VEHICLE_FAILED";
export const UPDATE_VEHICLE_RESET = "UPDATE_VEHICLE_RESET";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_FAILED = "DELETE_VEHICLE_FAILED";
export const DELETE_VEHICLE_RESET = "UPDATE_VEHICLE_RESET";
export const UPDATE_VEHICLE_STATUS = "UPDATE_VEHICLE_STATUS";
export const UPDATE_VEHICLE_STATUS_SUCCESS = "UPDATE_VEHICLE_STATUS_SUCCESS";
export const UPDATE_VEHICLE_STATUS_FAILED = "UPDATE_VEHICLE_STATUS_FAILED";
export const UPDATE_VEHICLE_STATUS_RESET = "UPDATE_VEHICLE_RESET";

UPDATE_VEHICLE_STATUS;

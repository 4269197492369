import {
  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILED,
  GET_SESSION_RESET,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION,
  CREATE_SESSION_FAILED,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILED,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAILED,
  UPDATE_SESSION_STATUS_SUCCESS,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_STATUS_FAILED,
  UPDATE_SESSION_RESET,
  CREATE_SESSION_RESET,
  DELETE_SESSION_RESET,
  UPDATE_SESSION_STATUS_RESET,
  GET_ALL_SESSION_RESET,
  GET_ALL_SESSION,
  GET_ALL_SESSION_SUCCESS,
  GET_ALL_SESSION_FAILED,
} from "./constants";

export function getAllSession(requestBody) {
  return { type: GET_ALL_SESSION, payload: { requestBody } };
}

export function getAllSessionSuccess(result) {
  return { type: GET_ALL_SESSION_SUCCESS, payload: result };
}

export function getAllSessionFailed(error) {
  return { type: GET_ALL_SESSION_FAILED, payload: error };
}

export function getAllSessionReset() {
  return { type: GET_ALL_SESSION_RESET, payload: {} };
}

export function getSession(requestBody) {
  return { type: GET_SESSION, payload: { requestBody } };
}

export function getSessionSuccess(result) {
  return { type: GET_SESSION_SUCCESS, payload: result };
}

export function getSessionFailed(error) {
  return { type: GET_SESSION_FAILED, payload: error };
}

export function getSessionReset() {
  return { type: GET_SESSION_RESET, payload: {} };
}

export function createSession(requestBody) {
  return { type: CREATE_SESSION, payload: { requestBody } };
}

export function createSessionSuccess(result) {
  return { type: CREATE_SESSION_SUCCESS, payload: result };
}

export function createSessionFailed(error) {
  return { type: CREATE_SESSION_FAILED, payload: error };
}

export function createSessionReset() {
  return { type: CREATE_SESSION_RESET, payload: {} };
}

export function updateSession(requestBody) {
  return { type: UPDATE_SESSION, payload: { requestBody } };
}

export function updateSessionSuccess(result) {
  return { type: UPDATE_SESSION_SUCCESS, payload: result };
}

export function updateSessionFailed(error) {
  return { type: UPDATE_SESSION_FAILED, payload: error };
}

export function updateSessionReset() {
  return { type: UPDATE_SESSION_RESET, payload: {} };
}

export function updateSessionStatus(requestBody) {
  return { type: UPDATE_SESSION_STATUS, payload: { requestBody } };
}

export function updateSessionStatusSuccess(result) {
  return { type: UPDATE_SESSION_STATUS_SUCCESS, payload: result };
}

export function updateSessionStatusFailed(error) {
  return { type: UPDATE_SESSION_STATUS_FAILED, payload: error };
}

export function updateSessionStatusReset(error) {
  return { type: UPDATE_SESSION_STATUS_RESET, payload: error };
}

export function deleteSession(requestBody) {
  return { type: DELETE_SESSION, payload: { requestBody } };
}

export function deleteSessionSuccess(result) {
  return { type: DELETE_SESSION_SUCCESS, payload: result };
}

export function deleteSessionFailed(error) {
  return { type: DELETE_SESSION_FAILED, payload: error };
}

export function deleteSessionReset() {
  return { type: DELETE_SESSION_RESET, payload: {} };
}

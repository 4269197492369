import axios from "axios";
import { checkTokenExpiration } from "../../utils/authUtils";
import { Navigate } from "react-router-dom";

export function getClassApi(req) {
  if (checkTokenExpiration()) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/course/getAllClass?exclude_all=${req}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    <Navigate to="/login" state={{ from: location }} replace />;
  }
}

import { put, call, takeEvery } from 'redux-saga/effects'
import { CREATE_STUDENT, GET_STUDENT, UPDATE_STUDENT, UPDATE_STUDENT_STATUS } from './constants'
import { createStudentApi, getStudentApi, updateStudentApi, updateStudentStatusApi } from './api'
import {
  createStudentFailed,
  createStudentSuccess,
  getStudentFailed,
  getStudentSuccess,
  updateStudentFailed,
  updateStudentStatusFailed,
  updateStudentStatusSuccess,
  updateStudentSuccess,
} from './action'

function* getStudent(action) {
  try {
    const response = yield call(getStudentApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getStudentSuccess(response))
    } else {
      yield put(getStudentFailed(response))
    }
  } catch (e) {
    yield put(getStudentFailed(e.response))
  }
}

function* createStudent(action) {
  try {
    const response = yield call(createStudentApi, action.payload.requestBody)
    if (response && response.status === 201) {
      yield put(createStudentSuccess(response))
    } else {
      yield put(createStudentFailed(response))
    }
  } catch (e) {
    yield put(createStudentFailed(e.response))
  }
}

function* updateStudent(action) {
  try {
    const response = yield call(updateStudentApi, action.payload.requestBody)
    if (response && response.status === 201) {
      yield put(updateStudentSuccess(response))
    } else {
      yield put(updateStudentFailed(response))
    }
  } catch (e) {
    yield put(updateStudentFailed(e.response))
  }
}
function* updateStudentStatus(action) {
  try {
    const response = yield call(updateStudentStatusApi, action.payload.requestBody)
    if (response && response.status === 201) {
      yield put(updateStudentStatusSuccess(response))
    } else {
      yield put(updateStudentStatusFailed(response))
    }
  } catch (e) {
    yield put(updateStudentStatusFailed(e.response))
  }
}
export default function* getStudentSaga() {
  yield takeEvery(GET_STUDENT, getStudent)
  yield takeEvery(CREATE_STUDENT, createStudent)
  yield takeEvery(UPDATE_STUDENT, updateStudent)
  yield takeEvery(UPDATE_STUDENT_STATUS, updateStudentStatus)
}

import {
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAILED,
  GET_BRANCH_RESET,
  CREATE_BRANCH_SUCCESS,
  CREATE_BRANCH,
  CREATE_BRANCH_FAILED,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILED,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  UPDATE_BRANCH_STATUS_SUCCESS,
  UPDATE_BRANCH_STATUS,
  UPDATE_BRANCH_STATUS_FAILED,
  UPDATE_BRANCH_RESET,
  CREATE_BRANCH_RESET,
  DELETE_BRANCH_RESET,
  UPDATE_BRANCH_STATUS_RESET,
} from "./constants";

export function getBranch(requestBody) {
  return { type: GET_BRANCH, payload: { requestBody } };
}

export function getBranchSuccess(result) {
  return { type: GET_BRANCH_SUCCESS, payload: result };
}

export function getBranchFailed(error) {
  return { type: GET_BRANCH_FAILED, payload: error };
}

export function getBranchReset() {
  return { type: GET_BRANCH_RESET, payload: {} };
}

export function createBranch(requestBody) {
  return { type: CREATE_BRANCH, payload: { requestBody } };
}

export function createBranchSuccess(result) {
  return { type: CREATE_BRANCH_SUCCESS, payload: result };
}

export function createBranchFailed(error) {
  return { type: CREATE_BRANCH_FAILED, payload: error };
}

export function createBranchReset() {
  return { type: CREATE_BRANCH_RESET, payload: {} };
}

export function updateBranch(requestBody) {
  return { type: UPDATE_BRANCH, payload: { requestBody } };
}

export function updateBranchSuccess(result) {
  return { type: UPDATE_BRANCH_SUCCESS, payload: result };
}

export function updateBranchFailed(error) {
  return { type: UPDATE_BRANCH_FAILED, payload: error };
}

export function updateBranchReset() {
  return { type: UPDATE_BRANCH_RESET, payload: {} };
}

export function updateBranchStatus(requestBody) {
  return { type: UPDATE_BRANCH_STATUS, payload: { requestBody } };
}

export function updateBranchStatusSuccess(result) {
  return { type: UPDATE_BRANCH_STATUS_SUCCESS, payload: result };
}

export function updateBranchStatusFailed(error) {
  return { type: UPDATE_BRANCH_STATUS_FAILED, payload: error };
}

export function updateBranchStatusReset(error) {
  return { type: UPDATE_BRANCH_STATUS_RESET, payload: error };
}

export function deleteBranch(requestBody) {
  return { type: DELETE_BRANCH, payload: { requestBody } };
}

export function deleteBranchSuccess(result) {
  return { type: DELETE_BRANCH_SUCCESS, payload: result };
}

export function deleteBranchFailed(error) {
  return { type: DELETE_BRANCH_FAILED, payload: error };
}

export function deleteBranchReset() {
  return { type: DELETE_BRANCH_RESET, payload: {} };
}

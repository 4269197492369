import {
  GET_CLASS,
  GET_CLASS_SUCCESS,
  GET_CLASS_FAILED,
  GET_CLASS_RESET,
} from "./constants";

export function getClass(requestBody) {
  return { type: GET_CLASS, payload: { requestBody } };
}

export function getClassSuccess(result) {
  return { type: GET_CLASS_SUCCESS, payload: result };
}

export function getClassFailed(error) {
  return { type: GET_CLASS_FAILED, payload: error };
}

export function getClassReset() {
  return { type: GET_CLASS_RESET, payload: {} };
}

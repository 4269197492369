export const GET_ALL_BATCH = "GET_ALL_BATCH";
export const GET_ALL_BATCH_SUCCESS = "GET_ALL_BATCH_SUCCESS";
export const GET_ALL_BATCH_FAILED = "GET_ALL_BATCH_FAILED";
export const GET_ALL_BATCH_RESET = "GET_ALL_BATCH_RESET";
export const GET_BATCH = "GET_BATCH";
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS";
export const GET_BATCH_FAILED = "GET_BATCH_FAILED";
export const GET_BATCH_RESET = "GET_BATCH_RESET";
export const CREATE_BATCH = "CREATE_BATCH";
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAILED = "CREATE_BATCH_FAILED";
export const UPDATE_BATCH = "UPDATE_BATCH";
export const UPDATE_BATCH_SUCCESS = "UPDATE_BATCH_SUCCESS";
export const UPDATE_BATCH_FAILED = "UPDATE_BATCH_FAILED";
export const DELETE_BATCH = "DELETE_BATCH";
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS";
export const DELETE_BATCH_FAILED = "DELETE_BATCH_FAILED";
export const UPDATE_BATCH_STATUS = "UPDATE_BATCH_STATUS";
export const UPDATE_BATCH_STATUS_SUCCESS = "UPDATE_BATCH_STATUS_SUCCESS";
export const UPDATE_BATCH_STATUS_FAILED = "UPDATE_BATCH_STATUS_FAILED";
export const UPDATE_BATCH_RESET = "UPDATE_BATCH_RESET";
export const CREATE_BATCH_RESET = "CREATE_BATCH_RESET";
export const DELETE_BATCH_RESET = "DELETE_BATCH_RESET";
export const UPDATE_BATCH_STATUS_RESET = "UPDATE_BATCH_STATUS_RESET";

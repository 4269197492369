import {
  GET_CLASSROOM,
  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAILED,
  GET_CLASSROOM_RESET,
  CREATE_CLASSROOM,
  CREATE_CLASSROOM_FAILED,
  CREATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSROOM,
  UPDATE_CLASSROOM_FAILED,
  UPDATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSROOM_RESET,
  CREATE_CLASSROOM_RESET,
  DELETE_CLASSROOM,
  DELETE_CLASSROOM_SUCCESS,
  DELETE_CLASSROOM_FAILED,
  DELETE_CLASSROOM_RESET,
  UPDATE_CLASSROOM_STATUS,
  UPDATE_CLASSROOM_STATUS_SUCCESS,
  UPDATE_CLASSROOM_STATUS_RESET,
  UPDATE_CLASSROOM_STATUS_FAILED,
} from "./constants";

export const classRoomReducer = (
  state = {
    classRoomData: null,
    classRoomLoading: false,
    classRoomError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CLASSROOM:
      return {
        ...state,
        classRoomLoading: true,
        classRoomData: null,
        classRoomError: null,
      };
    case GET_CLASSROOM_SUCCESS:
      return {
        ...state,
        classRoomData: action.payload,
        classRoomLoading: false,
        classRoomError: null,
      };
    case GET_CLASSROOM_FAILED:
      return {
        ...state,
        classRoomLoading: false,
        classRoomData: null,
        classRoomError: action.payload,
      };
    case GET_CLASSROOM_RESET:
      return {
        ...state,
        classRoomLoading: false,
        classRoomData: null,
        classRoomError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createClassRoomReducer = (
  state = {
    createClassRoomData: null,
    createClassRoomLoading: false,
    createClassRoomError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_CLASSROOM:
      return {
        ...state,
        createClassRoomLoading: true,
        createClassRoomData: null,
        createClassRoomError: null,
      };
    case CREATE_CLASSROOM_SUCCESS:
      return {
        ...state,
        createClassRoomData: action.payload,
        createClassRoomLoading: false,
        createClassRoomError: null,
      };
    case CREATE_CLASSROOM_FAILED:
      return {
        ...state,
        createClassRoomLoading: false,
        createClassRoomData: null,
        createClassRoomError: action.payload,
      };
    case CREATE_CLASSROOM_RESET:
      return {
        ...state,
        createClassRoomLoading: false,
        createClassRoomData: null,
        createClassRoomError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateClassRoomReducer = (
  state = {
    updateClassRoomData: null,
    updateClassRoomLoading: false,
    updateClassRoomError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CLASSROOM:
      return {
        ...state,
        updateClassRoomLoading: true,
        updateClassRoomData: null,
        updateClassRoomError: null,
      };
    case UPDATE_CLASSROOM_SUCCESS:
      return {
        ...state,
        updateClassRoomData: action.payload,
        updateClassRoomLoading: false,
        updateClassRoomError: null,
      };
    case UPDATE_CLASSROOM_FAILED:
      return {
        ...state,
        updateClassRoomLoading: false,
        updateClassRoomData: null,
        updateClassRoomError: action.payload,
      };
    case UPDATE_CLASSROOM_RESET:
      return {
        ...state,
        updateClassRoomLoading: false,
        updateClassRoomData: null,
        updateClassRoomError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteClassRoomReducer = (
  state = {
    deleteClassRoomData: null,
    deleteClassRoomLoading: false,
    deleteClassRoomError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_CLASSROOM:
      return {
        ...state,
        deleteClassRoomLoading: true,
        deleteClassRoomData: null,
        deleteClassRoomError: null,
      };
    case DELETE_CLASSROOM_SUCCESS:
      return {
        ...state,
        deleteClassRoomData: action.payload,
        deleteClassRoomLoading: false,
        deleteClassRoomError: null,
      };
    case DELETE_CLASSROOM_FAILED:
      return {
        ...state,
        deleteClassRoomLoading: false,
        deleteClassRoomData: null,
        deleteClassRoomError: action.payload,
      };
    case DELETE_CLASSROOM_RESET:
      return {
        ...state,
        deleteClassRoomLoading: false,
        deleteClassRoomData: null,
        deleteClassRoomError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const classRoomStatusReducer = (
  state = {
    classRoomStatusData: null,
    classRoomloading: false,
    classRoomerror: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CLASSROOM_STATUS:
      return {
        ...state,
        classRoomStatusLoading: true,
        classRoomStatusData: null,
        classRoomStatusError: null,
      };
    case UPDATE_CLASSROOM_STATUS_SUCCESS:
      return {
        ...state,
        classRoomStatusData: action.payload,
        classRoomStatusLoading: false,
        classRoomStatusError: null,
      };
    case UPDATE_CLASSROOM_STATUS_FAILED:
      return {
        ...state,
        classRoomStatusLoading: false,
        classRoomStatusData: null,
        classRoomStatusError: action.payload,
      };
    case UPDATE_CLASSROOM_STATUS_RESET:
      return {
        ...state,
        classRoomStatusLoading: false,
        classRoomStatusData: null,
        classRoomStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import axios from "axios";

export function getCourseApi(requestBody) {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/course/getCourseById/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getAllCourseApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/course/getAllCourse`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function createCourseApi(requestBody) {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/course/createCourse`,
      requestBody,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateCourseApi(requestBody) {
  console.log("Update Course", requestBody);
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/course/updateCourse/${requestBody.requestBody.id}`,
      requestBody.requestBody.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteCourseApi(requestBody) {
  return axios
    .delete(
      `${process.env.REACT_APP_BASE_URL}/course/deleteCourse/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateCourseStatusApi(requestBody) {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/Course/updateCourseStatus/${requestBody.id}`,
      requestBody.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getCourseByClassIdApi(requestBody) {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/course/getCourseByClassId/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getGradeByCourseIdApi(requestBody) {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/course/getGradeByCourseId/${requestBody}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

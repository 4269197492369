import { GET_TOKEN, GET_TOKEN_SUCCESS, GET_TOKEN_FAILED, GET_TOKEN_RESET } from './constants'

const authTokenReducer = (
  state = {
    authdata: null,
    authloading: false,
    autherror: null,
  },
  action,
) => {
  switch (action.type) {
    case GET_TOKEN:
      return {
        ...state,
        authloading: true,
        authdata: null,
        autherror: null,
      }
    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        authdata: action.payload,
        authloading: false,
        autherror: null,
      }
    case GET_TOKEN_FAILED:
      return {
        ...state,
        authloading: false,
        authdata: null,
        autherror: action.payload.response,
      }
    case GET_TOKEN_RESET:
      return {
        ...state,
        authloading: false,
        authdata: null,
        autherror: null,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default authTokenReducer

import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ASSIGN_GRADE_TO_COURSES,
  CREATE_COURSES_GRADE,
  DELETE_COURSES_GRADE,
  GET_COURSES_AND_GRADE,
  GET_COURSES_GRADE,
  UPDATE_COURSES_GRADE,
  UPDATE_COURSES_GRADE_STATUS,
} from "./constants";
import {
  assignCourseGradeApi,
  createCourseGradeApi,
  deleteCourseGradeApi,
  getAllCourseAndGradeApi,
  getCourseGradeApi,
  updateCourseGradeApi,
  updateCourseGradeStatusApi,
} from "./api";
import {
  assignGradesToCourseFailed,
  assignGradesToCourseSuccess,
  createCourseGradeFailed,
  createCourseGradeSuccess,
  deleteCourseGradeFailed,
  deleteCourseGradeSuccess,
  getAllCourseAndGradeFailed,
  getAllCourseAndGradeSuccess,
  getCourseGradeFailed,
  getCourseGradeSuccess,
  updateCourseGradeFailed,
  updateCourseGradeStatusFailed,
  updateCourseGradeStatusSuccess,
  updateCourseGradeSuccess,
} from "./action";

function* getCourseGrade(action) {
  try {
    const response = yield call(getCourseGradeApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getCourseGradeSuccess(response));
    } else {
      yield put(getCourseGradeFailed(response));
    }
  } catch (e) {
    yield put(getCourseGradeFailed(e.response));
  }
}

function* createCourseGrade(action) {
  try {
    const response = yield call(
      createCourseGradeApi,
      action.payload.requestBody
    );
    if (response && response.status === 201) {
      yield put(createCourseGradeSuccess(response));
    } else {
      yield put(createCourseGradeFailed(response));
    }
  } catch (e) {
    yield put(createCourseGradeFailed(e.response));
  }
}

function* updateCourseGrade(action) {
  try {
    const response = yield call(
      updateCourseGradeApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateCourseGradeSuccess(response));
    } else {
      yield put(updateCourseGradeFailed(response));
    }
  } catch (e) {
    yield put(updateCourseGradeFailed(e.response));
  }
}

function* updateCourseGradeStatus(action) {
  try {
    const response = yield call(
      updateCourseGradeStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateCourseGradeStatusSuccess(response));
    } else {
      yield put(updateCourseGradeStatusFailed(response));
    }
  } catch (e) {
    yield put(updateCourseGradeStatusFailed(e.response));
  }
}

function* deleteCourseGrade(action) {
  console.log("deleteCourseGrade", action);
  try {
    const response = yield call(
      deleteCourseGradeApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(deleteCourseGradeSuccess(response));
    } else {
      yield put(deleteCourseGradeFailed(response));
    }
  } catch (e) {
    yield put(deleteCourseGradeFailed(e.response));
  }
}

function* assignCourseGrade(action) {
  try {
    const response = yield call(
      assignCourseGradeApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(assignGradesToCourseSuccess(response));
    } else {
      yield put(assignGradesToCourseFailed(response));
    }
  } catch (e) {
    yield put(assignGradesToCourseFailed(e.response));
  }
}

export default function* getCourseGradeSaga() {
  yield takeEvery(GET_COURSES_GRADE, getCourseGrade);
  yield takeEvery(CREATE_COURSES_GRADE, createCourseGrade);
  yield takeEvery(UPDATE_COURSES_GRADE, updateCourseGrade);
  yield takeEvery(DELETE_COURSES_GRADE, deleteCourseGrade);
  yield takeEvery(UPDATE_COURSES_GRADE_STATUS, updateCourseGradeStatus);
  yield takeEvery(ASSIGN_GRADE_TO_COURSES, assignCourseGrade);
}

import {
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAILED,
  GET_BRANCH_RESET,
  CREATE_BRANCH,
  CREATE_BRANCH_FAILED,
  CREATE_BRANCH_SUCCESS,
  UPDATE_BRANCH,
  UPDATE_BRANCH_FAILED,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_RESET,
  CREATE_BRANCH_RESET,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  DELETE_BRANCH_RESET,
  UPDATE_BRANCH_STATUS,
  UPDATE_BRANCH_STATUS_SUCCESS,
  UPDATE_BRANCH_STATUS_RESET,
  UPDATE_BRANCH_STATUS_FAILED,
} from "./constants";

export const branchReducer = (
  state = {
    branchdata: null,
    branchloading: false,
    brancherror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_BRANCH:
      return {
        ...state,
        branchloading: true,
        branchdata: null,
        brancherror: null,
      };
    case GET_BRANCH_SUCCESS:
      return {
        ...state,
        branchdata: action.payload,
        branchloading: false,
        brancherror: null,
      };
    case GET_BRANCH_FAILED:
      return {
        ...state,
        branchloading: false,
        branchdata: null,
        brancherror: action.payload,
      };
    case GET_BRANCH_RESET:
      return {
        ...state,
        branchloading: false,
        branchdata: null,
        brancherror: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createBranchReducer = (
  state = {
    createBranchData: null,
    createBranchLoading: false,
    createBranchError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_BRANCH:
      return {
        ...state,
        createBranchLoading: true,
        createBranchData: null,
        createBranchError: null,
      };
    case CREATE_BRANCH_SUCCESS:
      return {
        ...state,
        createBranchData: action.payload,
        createBranchLoading: false,
        createBranchError: null,
      };
    case CREATE_BRANCH_FAILED:
      return {
        ...state,
        createBranchLoading: false,
        createBranchData: null,
        createBranchError: action.payload,
      };
    case CREATE_BRANCH_RESET:
      return {
        ...state,
        createBranchLoading: false,
        createBranchData: null,
        createBranchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateBranchReducer = (
  state = {
    updateBranchData: null,
    updateBranchLoading: false,
    updateBranchError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_BRANCH:
      return {
        ...state,
        updateBranchLoading: true,
        updateBranchData: null,
        updateBranchError: null,
      };
    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        updateBranchData: action.payload,
        updateBranchLoading: false,
        updateBranchError: null,
      };
    case UPDATE_BRANCH_FAILED:
      return {
        ...state,
        updateBranchLoading: false,
        updateBranchData: null,
        updateBranchError: action.payload,
      };
    case UPDATE_BRANCH_RESET:
      return {
        ...state,
        updateBranchLoading: false,
        updateBranchData: null,
        updateBranchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteBranchReducer = (
  state = {
    deleteBranchData: null,
    deleteBranchLoading: false,
    deleteBranchError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_BRANCH:
      return {
        ...state,
        deleteBranchLoading: true,
        deleteBranchData: null,
        deleteBranchError: null,
      };
    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        deleteBranchData: action.payload,
        deleteBranchLoading: false,
        deleteBranchError: null,
      };
    case DELETE_BRANCH_FAILED:
      return {
        ...state,
        deleteBranchLoading: false,
        deleteBranchData: null,
        deleteBranchError: action.payload,
      };
    case DELETE_BRANCH_RESET:
      return {
        ...state,
        deleteBranchLoading: false,
        deleteBranchData: null,
        deleteBranchError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const branchStatusReducer = (
  state = {
    branchStatusData: null,
    branchloading: false,
    brancherror: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_BRANCH_STATUS:
      return {
        ...state,
        branchStatusLoading: true,
        branchStatusData: null,
        branchStatusError: null,
      };
    case UPDATE_BRANCH_STATUS_SUCCESS:
      return {
        ...state,
        branchStatusData: action.payload,
        branchStatusLoading: false,
        branchStatusError: null,
      };
    case UPDATE_BRANCH_STATUS_FAILED:
      return {
        ...state,
        branchStatusLoading: false,
        branchStatusData: null,
        branchStatusError: action.payload,
      };
    case UPDATE_BRANCH_STATUS_RESET:
      return {
        ...state,
        branchStatusLoading: false,
        branchStatusData: null,
        branchStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

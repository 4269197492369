import axios from 'axios'

export function getAllParentApi() {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/parent/getAllParent`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}

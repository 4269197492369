import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_SESSION,
  DELETE_SESSION,
  GET_ALL_SESSION,
  GET_SESSION,
  UPDATE_SESSION,
  UPDATE_SESSION_STATUS,
} from "./constants";
import {
  createSessionApi,
  deleteSessionApi,
  getAllSessionApi,
  getSessionApi,
  updateSessionApi,
  updateSessionStatusApi,
} from "./api";
import {
  createSessionFailed,
  createSessionSuccess,
  deleteSessionFailed,
  deleteSessionSuccess,
  getAllSessionFailed,
  getAllSessionSuccess,
  getSessionFailed,
  getSessionSuccess,
  updateSessionFailed,
  updateSessionStatusFailed,
  updateSessionStatusSuccess,
  updateSessionSuccess,
} from "./action";

function* getAllSession(action) {
  try {
    const response = yield call(getAllSessionApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getAllSessionSuccess(response));
    } else {
      yield put(getAllSessionFailed(response));
    }
  } catch (e) {
    yield put(getAllSessionFailed(e.response));
  }
}

function* getSession(action) {
  try {
    const response = yield call(getSessionApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(getSessionSuccess(response));
    } else {
      yield put(getSessionFailed(response));
    }
  } catch (e) {
    yield put(getSessionFailed(e.response));
  }
}

function* createSession(action) {
  try {
    const response = yield call(createSessionApi, action.payload.requestBody);
    if (response && response.status === 201) {
      yield put(createSessionSuccess(response));
    } else {
      yield put(createSessionFailed(response));
    }
  } catch (e) {
    yield put(createSessionFailed(e.response));
  }
}

function* updateSession(action) {
  try {
    const response = yield call(updateSessionApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(updateSessionSuccess(response));
    } else {
      yield put(updateSessionFailed(response));
    }
  } catch (e) {
    yield put(updateSessionFailed(e.response));
  }
}

function* updateSessionStatus(action) {
  try {
    const response = yield call(
      updateSessionStatusApi,
      action.payload.requestBody
    );
    if (response && response.status === 200) {
      yield put(updateSessionStatusSuccess(response));
    } else {
      yield put(updateSessionStatusFailed(response));
    }
  } catch (e) {
    yield put(updateSessionStatusFailed(e.response));
  }
}

function* deleteSession(action) {
  try {
    const response = yield call(deleteSessionApi, action.payload.requestBody);
    if (response && response.status === 200) {
      yield put(deleteSessionSuccess(response));
    } else {
      yield put(deleteSessionFailed(response));
    }
  } catch (e) {
    yield put(deleteSessionFailed(e.response));
  }
}

export default function* getSessionSaga() {
  yield takeEvery(GET_ALL_SESSION, getAllSession);
  yield takeEvery(GET_SESSION, getSession);
  yield takeEvery(CREATE_SESSION, createSession);
  yield takeEvery(UPDATE_SESSION, updateSession);
  yield takeEvery(DELETE_SESSION, deleteSession);
  yield takeEvery(UPDATE_SESSION_STATUS, updateSessionStatus);
}

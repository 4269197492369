import {
  GET_CAMP,
  GET_CAMP_SUCCESS,
  GET_CAMP_FAILED,
  GET_CAMP_RESET,
  CREATE_CAMP,
  CREATE_CAMP_FAILED,
  CREATE_CAMP_SUCCESS,
  UPDATE_CAMP,
  UPDATE_CAMP_FAILED,
  UPDATE_CAMP_SUCCESS,
  UPDATE_CAMP_RESET,
  CREATE_CAMP_RESET,
  DELETE_CAMP,
  DELETE_CAMP_SUCCESS,
  DELETE_CAMP_FAILED,
  DELETE_CAMP_RESET,
  UPDATE_CAMP_STATUS,
  UPDATE_CAMP_STATUS_SUCCESS,
  UPDATE_CAMP_STATUS_RESET,
  UPDATE_CAMP_STATUS_FAILED,
  GET_CAMP_TYPE,
  GET_CAMP_TYPE_SUCCESS,
  GET_CAMP_TYPE_FAILED,
  GET_CAMP_TYPE_RESET,
  CREATE_CAMP_TYPE,
  CREATE_CAMP_TYPE_FAILED,
  CREATE_CAMP_TYPE_SUCCESS,
  UPDATE_CAMP_TYPE,
  UPDATE_CAMP_TYPE_FAILED,
  UPDATE_CAMP_TYPE_SUCCESS,
  UPDATE_CAMP_TYPE_RESET,
  CREATE_CAMP_TYPE_RESET,
  DELETE_CAMP_TYPE,
  DELETE_CAMP_TYPE_SUCCESS,
  DELETE_CAMP_TYPE_FAILED,
  DELETE_CAMP_TYPE_RESET,
  UPDATE_CAMP_TYPE_STATUS,
  UPDATE_CAMP_TYPE_STATUS_SUCCESS,
  UPDATE_CAMP_TYPE_STATUS_RESET,
  UPDATE_CAMP_TYPE_STATUS_FAILED,
  GET_CAMP_BY_ID_RESET,
  GET_CAMP_BY_ID_FAILED,
  GET_CAMP_BY_ID_SUCCESS,
  GET_CAMP_BY_ID,
  GET_CAMP_AND_COURSE,
  GET_CAMP_AND_COURSE_SUCCESS,
  GET_CAMP_AND_COURSE_RESET,
  GET_CAMP_AND_COURSE_FAILED,
} from "./constants";

export const campReducer = (
  state = {
    campData: null,
    campLoading: false,
    campError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CAMP:
      return {
        ...state,
        campLoading: true,
        campData: null,
        campError: null,
      };
    case GET_CAMP_SUCCESS:
      return {
        ...state,
        campData: action.payload,
        campLoading: false,
        campError: null,
      };
    case GET_CAMP_FAILED:
      return {
        ...state,
        campLoading: false,
        campData: null,
        campError: action.payload,
      };
    case GET_CAMP_RESET:
      return {
        ...state,
        campLoading: false,
        campData: null,
        campError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const getCampAndCourseReducer = (
  state = {
    campAndCourseAndCourseData: null,
    campAndCourseLoading: false,
    campAndCourseError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CAMP_AND_COURSE:
      return {
        ...state,
        campAndCourseLoading: true,
        campAndCourseAndCourseData: null,
        campAndCourseError: null,
      };
    case GET_CAMP_AND_COURSE_SUCCESS:
      return {
        ...state,
        campAndCourseAndCourseData: action.payload,
        campAndCourseLoading: false,
        campAndCourseError: null,
      };
    case GET_CAMP_AND_COURSE_FAILED:
      return {
        ...state,
        campAndCourseLoading: false,
        campAndCourseAndCourseData: null,
        campAndCourseError: action.payload,
      };
    case GET_CAMP_AND_COURSE_RESET:
      return {
        ...state,
        campAndCourseLoading: false,
        campAndCourseAndCourseData: null,
        campAndCourseError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const campByIdReducer = (
  state = {
    campByIdData: null,
    campByIdLoading: false,
    campByIdError: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CAMP_BY_ID:
      return {
        ...state,
        campByIdLoading: true,
        campByIdData: null,
        campByIdError: null,
      };
    case GET_CAMP_BY_ID_SUCCESS:
      return {
        ...state,
        campByIdData: action.payload,
        campByIdLoading: false,
        campByIdError: null,
      };
    case GET_CAMP_BY_ID_FAILED:
      return {
        ...state,
        campByIdLoading: false,
        campByIdData: null,
        campByIdError: action.payload,
      };
    case GET_CAMP_BY_ID_RESET:
      return {
        ...state,
        campByIdLoading: false,
        campByIdData: null,
        campByIdError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createCampReducer = (
  state = {
    createCampData: null,
    createCampLoading: false,
    createCampError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_CAMP:
      return {
        ...state,
        createCampLoading: true,
        createCampData: null,
        createCampError: null,
      };
    case CREATE_CAMP_SUCCESS:
      return {
        ...state,
        createCampData: action.payload,
        createCampLoading: false,
        createCampError: null,
      };
    case CREATE_CAMP_FAILED:
      return {
        ...state,
        createCampLoading: false,
        createCampData: null,
        createCampError: action.payload,
      };
    case CREATE_CAMP_RESET:
      return {
        ...state,
        createCampLoading: false,
        createCampData: null,
        createCampError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateCampReducer = (
  state = {
    updateCampData: null,
    updateCampLoading: false,
    updateCampError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CAMP:
      return {
        ...state,
        updateCampLoading: true,
        updateCampData: null,
        updateCampError: null,
      };
    case UPDATE_CAMP_SUCCESS:
      return {
        ...state,
        updateCampData: action.payload,
        updateCampLoading: false,
        updateCampError: null,
      };
    case UPDATE_CAMP_FAILED:
      return {
        ...state,
        updateCampLoading: false,
        updateCampData: null,
        updateCampError: action.payload,
      };
    case UPDATE_CAMP_RESET:
      return {
        ...state,
        updateCampLoading: false,
        updateCampData: null,
        updateCampError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteCampReducer = (
  state = {
    deleteCampData: null,
    deleteCampLoading: false,
    deleteCampError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_CAMP:
      return {
        ...state,
        deleteCampLoading: true,
        deleteCampData: null,
        deleteCampError: null,
      };
    case DELETE_CAMP_SUCCESS:
      return {
        ...state,
        deleteCampData: action.payload,
        deleteCampLoading: false,
        deleteCampError: null,
      };
    case DELETE_CAMP_FAILED:
      return {
        ...state,
        deleteCampLoading: false,
        deleteCampData: null,
        deleteCampError: action.payload,
      };
    case DELETE_CAMP_RESET:
      return {
        ...state,
        deleteCampLoading: false,
        deleteCampData: null,
        deleteCampError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const campStatusReducer = (
  state = {
    campStatusData: null,
    campStatusLoading: false,
    campStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CAMP_STATUS:
      return {
        ...state,
        campStatusLoading: true,
        campStatusData: null,
        campStatusError: null,
      };
    case UPDATE_CAMP_STATUS_SUCCESS:
      return {
        ...state,
        campStatusData: action.payload,
        campStatusLoading: false,
        campStatusError: null,
      };
    case UPDATE_CAMP_STATUS_FAILED:
      return {
        ...state,
        campStatusLoading: false,
        campStatusData: null,
        campStatusError: action.payload,
      };
    case UPDATE_CAMP_STATUS_RESET:
      return {
        ...state,
        campStatusLoading: false,
        campStatusData: null,
        campStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const campTypeReducer = (
  state = {
    campTypeData: null,
    campTypeLoading: false,
    campTypeerror: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CAMP_TYPE:
      return {
        ...state,
        campTypeLoading: true,
        campTypeData: null,
        campTypeError: null,
      };
    case GET_CAMP_TYPE_SUCCESS:
      return {
        ...state,
        campTypeData: action.payload,
        campTypeLoading: false,
        campTypeError: null,
      };
    case GET_CAMP_TYPE_FAILED:
      return {
        ...state,
        campTypeLoading: false,
        campTypeData: null,
        campTypeError: action.payload,
      };
    case GET_CAMP_TYPE_RESET:
      return {
        ...state,
        campTypeLoading: false,
        campTypeData: null,
        campTypeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const createCampTypeReducer = (
  state = {
    createCampTypeData: null,
    createCampTypeLoading: false,
    createCampTypeError: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_CAMP_TYPE:
      return {
        ...state,
        createCampTypeLoading: true,
        createCampTypeData: null,
        createCampTypeError: null,
      };
    case CREATE_CAMP_TYPE_SUCCESS:
      return {
        ...state,
        createCampTypeData: action.payload,
        createCampTypeLoading: false,
        createCampTypeError: null,
      };
    case CREATE_CAMP_TYPE_FAILED:
      return {
        ...state,
        createCampTypeLoading: false,
        createCampTypeData: null,
        createCampTypeError: action.payload,
      };
    case CREATE_CAMP_TYPE_RESET:
      return {
        ...state,
        createCampTypeLoading: false,
        createCampTypeData: null,
        createCampTypeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const updateCampTypeReducer = (
  state = {
    updateCampTypeData: null,
    updateCampTypeLoading: false,
    updateCampTypeError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CAMP_TYPE:
      return {
        ...state,
        updateCampTypeLoading: true,
        updateCampTypeData: null,
        updateCampTypeError: null,
      };
    case UPDATE_CAMP_TYPE_SUCCESS:
      return {
        ...state,
        updateCampTypeData: action.payload,
        updateCampTypeLoading: false,
        updateCampTypeError: null,
      };
    case UPDATE_CAMP_TYPE_FAILED:
      return {
        ...state,
        updateCampTypeLoading: false,
        updateCampTypeData: null,
        updateCampTypeError: action.payload,
      };
    case UPDATE_CAMP_TYPE_RESET:
      return {
        ...state,
        updateCampTypeLoading: false,
        updateCampTypeData: null,
        updateCampTypeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const deleteCampTypeReducer = (
  state = {
    deleteCampTypeData: null,
    deleteCampTypeLoading: false,
    deleteCampTypeError: null,
  },
  action
) => {
  switch (action.type) {
    case DELETE_CAMP_TYPE:
      return {
        ...state,
        deleteCampTypeLoading: true,
        deleteCampTypeData: null,
        deleteCampTypeError: null,
      };
    case DELETE_CAMP_TYPE_SUCCESS:
      return {
        ...state,
        deleteCampTypeData: action.payload,
        deleteCampTypeLoading: false,
        deleteCampTypeError: null,
      };
    case DELETE_CAMP_TYPE_FAILED:
      return {
        ...state,
        deleteCampTypeLoading: false,
        deleteCampTypeData: null,
        deleteCampTypeError: action.payload,
      };
    case DELETE_CAMP_TYPE_RESET:
      return {
        ...state,
        deleteCampTypeLoading: false,
        deleteCampTypeData: null,
        deleteCampTypeError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const campTypeStatusReducer = (
  state = {
    campTypeStatusData: null,
    campTypeStatusLoading: false,
    campTypeStatusError: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CAMP_TYPE_STATUS:
      return {
        ...state,
        campTypeStatusLoading: true,
        campTypeStatusData: null,
        campTypeStatusError: null,
      };
    case UPDATE_CAMP_TYPE_STATUS_SUCCESS:
      return {
        ...state,
        campTypeStatusData: action.payload,
        campTypeStatusLoading: false,
        campTypeStatusError: null,
      };
    case UPDATE_CAMP_TYPE_STATUS_FAILED:
      return {
        ...state,
        campTypeStatusLoading: false,
        campTypeStatusData: null,
        campTypeStatusError: action.payload,
      };
    case UPDATE_CAMP_TYPE_STATUS_RESET:
      return {
        ...state,
        campTypeStatusLoading: false,
        campTypeStatusData: null,
        campTypeStatusError: null,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

import { put, call, takeEvery } from 'redux-saga/effects'
import { GET_PARENT } from './constants'
import { getAllParentApi } from './api'
import { getAllParentFailed, getAllParentSuccess } from './action'

function* getAllParent(action) {
  try {
    const response = yield call(getAllParentApi, action.payload.requestBody)
    if (response && response.status === 200) {
      yield put(getAllParentSuccess(response))
    } else {
      yield put(getAllParentFailed(response))
    }
  } catch (e) {
    yield put(getAllParentFailed(e.response))
  }
}

export default function* getAllParentSaga() {
  yield takeEvery(GET_PARENT, getAllParent)
}

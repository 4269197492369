import axios from 'axios'

export function getTokenApi(requestBody) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/login`, requestBody, { crossdomain: true })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}
